import React from "react"
import MenuLayouts from "../layouts/MenuLayouts"
import { Route, Switch } from "react-router-dom"
import ManagerSalesScreen from "../screens/Manager/ManagerSalesScreen"
import NotFountScreen from "../screens/Error/NotFountScreen"
import UserScreen from "../screens/User/UserScreen"

export default ({ match }) => {
    return (
            <Switch>
                <MenuLayouts exact path={match.path} component={UserScreen} title={"매니저정보"} />
                <MenuLayouts path={`${match.path}/sales`} component={ManagerSalesScreen} title={"매니저실적"} />
                <Route component={NotFountScreen} />
            </Switch>
    )
}