import Api from "./Api"
const url = "arranger"
const Arranger = {
    get: async (params = {}) => {
        return await Api.get(`${url}`, params)
    },
    show: async (_arranger) => {
        return await Api.get(`${url}/${_arranger}`)
    },
    my: async () => {
        return await Api.get(`${url}/my`)
    },
    user: async (params) => {
        return await Api.get(`${url}/user`, params)
    },
    myUser: async (params) => {
        return await Api.get(`${url}/my-user`, params)
    },
    save: async (params = {}) => {
        return await Api.post(`${url}`, params)
    }
}

export default Arranger