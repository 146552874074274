import React from 'react'
import { Route } from 'react-router-dom'
import LoginLayout from "../layouts/LoginLayout"


const LoginLayouts = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            <LoginLayout>
                <Component {...props} />
            </LoginLayout>
        )} />
    )
}

export default LoginLayouts