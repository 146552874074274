import _Component from "../../common/_Component"
import React from "react"
import { action, observable } from "mobx"
import GetValue from "../../utils/GetValue"
import { getMoment, isId } from "../../utils/Utils"
import User from "../../api/User"
import Match from "../../api/Match"
import UserShowLayout from "../../layouts/UserShowLayout"
import IconButton from "@material-ui/core/IconButton"
import { Delete, Visibility } from "@material-ui/icons"
import Pagination from "../../components/Pagination"
import { getProfileUrl } from "../../utils/ImageUtil"
import MatchModal from "../../components/MatchModal"
import Area from "../../api/Area"
import { inject } from "mobx-react"
import AvailableSuccess from "../../utils/AvailableSuccess"
import ShowType from "../../utils/ShowType"
import ShownStatus from "../../utils/ShownStatus"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class UserResponseScreen extends _Component {
    @observable user = null
    @observable user2 = null
    @observable status = ""
    @observable successible = ""
    @observable matches = []
    @observable modal = false
    @observable modalMode = "response"
    @observable availableSuccess = null
    @observable matchingStep = 0
    @observable meetAt = ""
    @observable pagination = {}
    @observable areas = []
    @observable _matchId = null
    @observable myPermission = ""

    async componentDidMount() {
        this.myPermission = this.props.loginStore.permission
        try {
            await this._getAreas()
            await this._getUser()
            await this._getData()
            super.componentDidMount()
        } catch (e) {
            console.log(e)
        }
    }

    @action
    _getAreas = async () => {
        try {
            let response = await Area.get()
            if (response && response.success) {
                this.areas = response.areas
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _getUser = async () => {
        const _id = GetValue(this, "this.props.match.params.id")
        if (!_id || !isId(_id)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
        }
        try {
            let response = await User.show(_id)

            if (response && response.success) {
                this.user = response.user
                this.user.trophy = response.trophy
                this.user.interest = response.interest
                this.responseMatchCount = response.responseMatchCount
                this.isNewMatch = response.isNewMatch
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _getData = async (page = 1) => {
        try {

            const response = await Match.get({
                _user: this.user._id,
                status: this.status,
                successible: this.successible,
                page: page,
                limit: this.limit,
                type: "response"
            })
            if (response && response.success) {
                this.matches = response.matches
                this.pagination = response.pagination
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _showModal = async (e, userId, matchId) => {
        if (!userId) {
            alert("유저ID 를 찾을 수 없는 회원입니다");
            return;
        }
        
        e.preventDefault()
        try {
            let response = await User.show(userId)
            if (response && response.success) {
                this.user2 = response.user
                this.user2.trophy = response.trophy
                this.user2.interest = response.interest
                this._matchId = matchId
            } else {
                alert(response.message)
            }
            response = await Match.show(matchId)
            if (response && response.success) {
                const match = response.matches[0]
                this.modalMode = match.status
                this.availableSuccess = AvailableSuccess.calculate(this.user._id, match).result
                this.matchingStep = match.matchingStep
                this.meetAt = match.meetAt ? match.meetAt : ""
                this.handleModal()
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    handleModal = (show = true) => {
        this.modal = show
        if (show === false) {
            this.user2 = null
            this._matchId = null
        }
    }

    _changeStatus = async (e) => {
        e.preventDefault()
        this.status = e.target.value
        await this._getData()
    }

    _changeSuccessible = async (e) => {
        e.preventDefault()
        this.successible = e.target.value
        await this._getData()
    }

    _render = () => (
        <UserShowLayout user={this.user} path={this.path} title={"받은매칭요청"} isNewMatch={this.isNewMatch} responseMatchCount={this.responseMatchCount} permission={this.myPermission}>
            {
                this.modal &&
                <MatchModal
                    areas={this.areas}
                    modal={this.modal}
                    user1={this.user}
                    user2={this.user2}
                    handle={this.handleModal.bind(this)}
                    type={this.modalMode}
                    availableSuccess={this.availableSuccess}
                    matchingStep={this.matchingStep}
                    _meetAt={this.meetAt}
                    _matchId={this._matchId}
                    _getData={this._getData.bind(this)}
                />
            }
            <div className="d-md-flex">
                <div className="form-group mr-2">
                    <label htmlFor="search">요청</label>
                    <select className="form-control" value={this.status} onChange={this._changeStatus}>
                        <option value={""}>전체</option>
                        <option value={"response"}>이어줘받음</option>
                        <option value={"wait"}>성사대기</option>
                        <option value={"success"}>매칭성사</option>
                        <option value={"fail"}>매칭실패</option>
                    </select>
                </div>
                <div className="form-group mr-2">
                    <label htmlFor="search">성사진행건</label>
                    <select className="form-control" id="request_filter" defaultValue={this.successible} onChange={this._changeSuccessible}>
                        <option value={""}>기본값</option>
                        <option value={"all"}>모두포함</option>
                        <option value={"available"}>성사가능</option>
                        <option value={"final"}>최종성사</option>
                    </select>
                </div>
                {
                    this.myPermission === 'super' &&
                    <div style={{ marginTop: 32 }}>
                        <button className="btn btn-outline-secondary"
                                onClick={e => this._delete(e, "match", this._getData.bind(this), this.pagination.currentPage, this.matches)}>선택삭제
                        </button>
                    </div>
                }
                
            </div>
            <div className="table-responsive">
                <table className="table text-nowrap">
                    <thead>
                        <tr>
                            <th scope="col">
                                <input type="checkbox" onChange={e => this._checkAll(e, this.matches)} />
                            </th>
                            <th scope="col">Atc.</th>
                            <th scope="col">요청</th>
                            <th scope="col">성사진행건</th>
                            <th scope="col"></th>
                            <th scope="col">보낸사람</th>
                            <th scope="col">매칭확률</th>
                            <th scope="col">이어줘받음날짜</th>
                            <th scope="col">매니저 최종응답</th>
                            <th scope="col">미팅일정</th>
                            <th scope="col">담당매니저</th>
                            <th scope="col">수정일</th>
                            <th scope="col">ID</th>
                          </tr>
                    </thead>
                    <tbody>
                    {
                        this.matches && this.matches.length > 0 && this.matches.map((value, index) => (
                            <tr key={index}>
                                <td>
                                        <input type="checkbox" checked={!!value.checked} onChange={e => this.matches[index].checked = e.target.checked} />
                                </td>
                                <td>
                                    <IconButton
                                        className="text-info fas"
                                        aria-label="visibility"
                                        style={{ padding: 0, outline: "none" }}
                                        onClick={e => this._showModal(e, value._request._user ? value._request._user._id : null, value._id)}
                                    >
                                      <Visibility />
                                    </IconButton>
                                    {
                                        this.myPermission === "super" &&
                                        <IconButton
                                            color={"secondary"}
                                            aria-label="delete"
                                            style={{ padding: 0, outline: "none" }}
                                            onClick={e => this._delete(e, `match/${value._id}`, this._getData.bind(this), this.pagination)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    }
                                </td>
                                <td>
                                    {
                                        value.status === "show" &&
                                        ShowType.getJSXElementByMatch(value)
                                    }
                                    {
                                        value.status === "request" &&
                                        ShownStatus.getJSXElementByMatch(value)
                                    }
                                    {
                                        value.status === "response" && value._request._user && value._request._user._id === this.user._id && 
                                        <span className="badge badge-pill badge-success">이어줘</span>
                                    }
                                    {
                                        value.status === "response" && value._response._user && value._response._user._id === this.user._id && value._response.answer === null &&
                                        <span className="badge badge-pill badge-success">이어줘받음</span>
                                    }
                                    {
                                        value.status === "wait" &&
                                        <span className="badge badge-pill badge-info">성사대기</span>
                                    }
                                    {
                                        value.status === "success" &&
                                        <span className="badge badge-pill badge-primary">매칭성사</span>
                                    }
                                    {
                                        value.status === "fail" &&
                                        <span className="badge badge-pill badge-secondary">매칭실패</span>
                                    }

                                </td>
                                <td>
                                    {AvailableSuccess.getJSXElementByMatch(GetValue(value, "value._response._user._id"), value)}
                                </td>
                                <td>
                                    <img className="align-self-center mr-3" style={{
                                        width: 32,
                                        height: 32,
                                        borderRadius: 16
                                    }} src={getProfileUrl(GetValue(value, "value._request._user._photos._main"))} alt="profile" />
                                </td>
                                <td>{GetValue(value, "value._request._user.name")}</td>
                                <td>{GetValue(value, "value.rate", 0)}%</td>
                                <td>{getMoment(GetValue(value, "value.requestAt"))}</td>
                                <td>
                                {
                                    GetValue(value, "value._response.answer") ?
                                        getMoment(GetValue(value, "value._response.answer"))
                                        : "응답대기"
                                }
                                </td>
                                <td>
                                {
                                    GetValue(value, "value.meetAt") ?
                                        getMoment(GetValue(value, "value.meetAt"))
                                        : "미입력"
                                }
                                </td>
                                <td>{GetValue(value, "value._request._manager.name", "매니저없음")}</td>
                                <td>{getMoment(GetValue(value, "value.updatedAt"))}</td>
                                <td>{GetValue(value, "value._id")}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                  </table>
            </div>
                <div className="d-flex">
                <div className="d-flex mr-auto">
                    <div>
                        <select className="form-control form-control-sm" value={this.limit} onChange={e => this._changeLimit(e, this._getData.bind(this))}>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                            <option>50</option>
                          </select>
                    </div>
                </div>
                <div>
                    <nav aria-label="Page navigation example">
                        <Pagination handle={this._getData.bind(this)} pagination={this.pagination} />
                    </nav>
                </div>
            </div>
        </UserShowLayout>
    )
}

export default UserResponseScreen