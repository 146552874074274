import _Component from "../../common/_Component"
import React from "react"
import UserShowLayout from "../../layouts/UserShowLayout"
import GetValue from "../../utils/GetValue"
import { isId } from "../../utils/Utils"
import User from "../../api/User"
import { action, observable } from "mobx"

class UserPasswordScreen extends _Component {
    @observable user = null
    @observable password = ""
    @observable passwordConfirm = ""

    async componentDidMount() {
        const _id = GetValue(this, "this.props.match.params.id")
        if (!_id || !isId(_id)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
        }
        try {
            let response = await User.show(_id)
            if (response && response.success) {
                this.user = response.user
                this.responseMatchCount = response.responseMatchCount
                this.isNewMatch = response.isNewMatch
            } else {
                alert(response.message)
                return true
            }
            super.componentDidMount()
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _save = async (e) => {
        e.preventDefault()
        try {
            const response = await User.update(this.user._id , {password : this.password, passwordConfirm : this.passwordConfirm})
            if (response && response.success) {
                alert("비밀번호 변경이 완료되었습니다")
                //this.props.history.push("/user")
            } else {
                alert(response.message)
            }
        }catch (e) {
            alert(e.toString())
        }
    }

    _render = () => (
        <UserShowLayout user={this.user} title={"비밀번호수정"} path={this.path} isNewMatch={this.isNewMatch} responseMatchCount={this.responseMatchCount} permission={this.myPermission}>
            <form>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">비밀번호</label>
                    <div className="col-sm-10">
                        <input type="password" className="form-control" placeholder="Password" value={this.password} onChange={e => this.password = e.target.value} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">비밀번호 확인</label>
                    <div className="col-sm-10">
                        <input type="password" className="form-control" placeholder="Password" value={this.passwordConfirm} onChange={e => this.passwordConfirm = e.target.value}  />
                    </div>
                </div>
                <div>
                    <button type="button" className="btn btn-secondary" onClick={this._save}>수정하기</button>
                </div>
            </form>
        </UserShowLayout>
    )
}
export default UserPasswordScreen