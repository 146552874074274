import React from "react"
import DashboardScreen from "../screens/Dashboard/DashboardScreen"
import MenuLayouts from "../layouts/MenuLayouts"
import NotFountScreen from "../screens/Error/NotFountScreen"
import { Route, Switch } from "react-router-dom"

export default ({ match }) => {
    return (
        <Switch>
            <MenuLayouts exact path={match.path} component={DashboardScreen} title={"대시보드"}/>
            <Route component={NotFountScreen} />
        </Switch>
    )
}