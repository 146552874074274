import _Component from "../../common/_Component"
import React from "react"
import UserShowLayout from "../../layouts/UserShowLayout"
import { action, observable } from "mobx"
import GetValue from "../../utils/GetValue"
import { getMatchRanges, getMatchValueToExpression, isId } from "../../utils/Utils"
import User from "../../api/User"
import { setInterest } from "../../utils/InterestUtil"
import Interest from "../../api/Interest"
import { inject } from "mobx-react"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class UserInterestScreen extends _Component {
    @observable user = null
    @observable interest = null
    @observable isInterest = false
    @observable matchRanges = []
    @observable myPermission = ""

    async componentDidMount() {
        this.myPermission = this.props.loginStore.permission

        const _id = GetValue(this, "this.props.match.params.id")
        if (!_id || !isId(_id)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
        }
        try {
            let response = await User.show(_id)
            if (response && response.success) {
                this.user = response.user
                this.responseMatchCount = response.responseMatchCount
                this.isNewMatch = response.isNewMatch
                this.interest = setInterest(response.interest)
                this.interest.matchRange = GetValue(this, "this.user.matchRange" , "all")
                this.matchRanges = getMatchRanges(GetValue(this, "this.user.grade", "standard"))
                if (response.interest) {
                    this.isInterest = true
                }
            } else {
                alert(response.message)
                return true
            }
            super.componentDidMount()
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _save = async (e) => {
        e.preventDefault()
        this.interest._user = this.user._id
        try {
            const response = await Interest.save(this.interest)
            if (response && response.success) {
                alert(`이상형이 ${this.isInterest ? "수정" : "저장"}되었습니다`)
                this.isInterest = true
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _render = () => (
        <UserShowLayout user={this.user} title={"이상형"} path={this.path} isNewMatch={this.isNewMatch} responseMatchCount={this.responseMatchCount} permission={this.myPermission}>
        {
            !this.isInterest &&
            <div className="alert alert-danger">
                <strong>알림!</strong> 아직 이상형이 등록되지 않은 회원입니다. 데이터 저장시 이상형이 생성됩니다.
            </div>
        }
            <form>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">외모</label>
                <div className="col-sm-8">
                    <select className="form-control" value={this.interest.face} onChange={e => this.interest.face = e.target.value}>
                        <option value="이쁨/잘생김">이쁨/잘생김</option>
                        <option value="귀여운/준수함">귀여운/준수함</option>
                        <option value="평범이상">평범이상</option>
                    </select>
                </div>
                <div className="col-sm-2">
                    <select className="form-control" value={this.interest.faceRank} onChange={e => this.interest.faceRank = e.target.value}>
                        <option value={1}>1순위</option>
                        <option value={2}>2순위</option>
                        <option value={3}>3순위</option>
                        <option value={4}>4순위</option>
                        <option value={5}>5순위</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">경제력</label>
                <div className="col-sm-8">
                    <select className="form-control" value={this.interest.income} onChange={e => this.interest.income = e.target.value}>
                        <option value="상관없음">상관없음</option>
                        <option value="비슷하면됨">비슷하면됨</option>
                        <option value="중요함">중요함</option>
                    </select>
                </div>
                <div className="col-sm-2">
                    <select className="form-control" value={this.interest.incomeRank} onChange={e => this.interest.incomeRank = e.target.value}>
                        <option value={1}>1순위</option>
                        <option value={2}>2순위</option>
                        <option value={3}>3순위</option>
                        <option value={4}>4순위</option>
                        <option value={5}>5순위</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">성격</label>
                <div className="col-sm-8">
                    <select className="form-control" value={this.interest.personality} onChange={e => this.interest.personality = e.target.value}>
                        <option value="내향적인">내향적인</option>
                        <option value="외향적인">외향적인</option>
                    </select>
                </div>
                <div className="col-sm-2">
                    <select className="form-control" value={this.interest.personalityRank} onChange={e => this.interest.personalityRank = e.target.value}>
                        <option value={1}>1순위</option>
                        <option value={2}>2순위</option>
                        <option value={3}>3순위</option>
                        <option value={4}>4순위</option>
                        <option value={5}>5순위</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">가치관</label>
                <div className="col-sm-8">
                    <select className="form-control" value={this.interest.values} onChange={e => this.interest.values = e.target.value}>
                        <option value="현실적">현실적</option>
                        <option value="사회도덕적">사회도덕적</option>
                        <option value="미래지향적">미래지향적</option>
                    </select>
                </div>
                <div className="col-sm-2">
                    <select className="form-control" value={this.interest.valuesRank} onChange={e => this.interest.valuesRank = e.target.value}>
                        <option value={1}>1순위</option>
                        <option value={2}>2순위</option>
                        <option value={3}>3순위</option>
                        <option value={4}>4순위</option>
                        <option value={5}>5순위</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">가정환경</label>
                <div className="col-sm-8">
                    <select className="form-control" value={this.interest.familyBackground} onChange={e => this.interest.familyBackground = e.target.value}>
                        <option value="중요함">중요함</option>
                        <option value="둘만좋으면됨">둘만좋으면됨</option>
                    </select>
                </div>
                <div className="col-sm-2">
                    <select className="form-control" value={this.interest.familyBackgroundRank} onChange={e => this.interest.familyBackgroundRank = e.target.value}>
                        <option value={1}>1순위</option>
                        <option value={2}>2순위</option>
                        <option value={3}>3순위</option>
                        <option value={4}>4순위</option>
                        <option value={5}>5순위</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">키</label>
                <div className="col-sm-10">
                    <select className="form-control" value={this.interest.height} onChange={e => this.interest.height = e.target.value}>
                        <option value="상관없음">상관없음</option>
                        <option value="나와10cm이내">나와10cm이내</option>
                        <option value="나와20cm이내">나와20cm이내</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">직업군</label>
                <div className="col-sm-10">
                    <select className="form-control" value={this.interest.job} onChange={e => this.interest.job = e.target.value}>
                        <option value="상관없음">상관없음</option>
                        <option value="도전적">도전적</option>
                        <option value="안정적">안정적</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">나이대</label>
                <div className="col-sm-10">
                    <select className="form-control" value={this.interest.age} onChange={e => this.interest.age = e.target.value}>
                        <option value="상관없음">상관없음</option>
                        <option value="동연하">동연하</option>
                        <option value="동연상">동연상</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">관심사</label>
                <div className="col-sm-10">
                    <select className="form-control" value={this.interest.interest} onChange={e => this.interest.interest = e.target.value}>
                        <option value="예술운동">예술운동</option>
                        <option value="일상여행">일상여행</option>
                        <option value="교육,자기계발">교육,자기계발</option>
                        <option value="음악,친목모임">음악,친목모임</option>
                        <option value="기타">기타</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label small">매치범위</label>
                <div className="col-sm-10">
                    <select className="form-control" value={this.interest.matchRange} onChange={e => this.interest.matchRange = e.target.value}>
                        {
                            this.matchRanges && this.matchRanges.length > 0 && this.matchRanges.map((value, index) => (
                                <option key={index} value={value}>{getMatchValueToExpression(value)}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            {
                this.myPermission === 'super' &&
                <div>
                    <button className="btn btn-primary" onClick={this._save} style={{ marginRight: 5 }}>완료</button>
                    <button className="btn btn-secondary" onClick={this._refresh}>취소</button>
                </div>
            }
            
        </form>
        </UserShowLayout>
    )
}

export default UserInterestScreen