import { inject } from 'mobx-react'
import _Component from "../../common/_Component"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class LogoutScreen extends _Component {
    componentDidMount() {
        this.props.loginStore.logout()
        this.props.history.push("/login")
        this.loaded = true
    }
}

export default LogoutScreen