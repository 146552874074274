import _Component from "../../common/_Component"
import React from "react"
import UserShowLayout from "../../layouts/UserShowLayout"
import { action, observable } from "mobx"
import GetValue from "../../utils/GetValue"
import { getMoment, isId } from "../../utils/Utils"
import User from "../../api/User"
import Admin from "../../api/Admin"
import { inject } from "mobx-react"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class UserShowScreen extends _Component {
    @observable user = null
    @observable arrangerPoint = 0
    @observable managers = []
    @observable managerId = ""
    @observable managerName = ""
    @observable myPermission = ""
    @observable admin = null

    async componentDidMount() {
        this.myPermission = this.props.loginStore.permission
        const _id = GetValue(this, "this.props.match.params.id")
        if (!_id || !isId(_id)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
        }
        try {
            let adminRes = await Admin.my()
            if (adminRes.success) {
                this.admin = adminRes.admin
            }

            let response = await User.show(_id)
            if (response && response.success) {
                this.user = response.user
                this.managerId = GetValue(response, "response.user._manager._id", "nothing")
                this.managerName = GetValue(response, "response.user._manager.name", "nothing")
                this.responseMatchCount = response.responseMatchCount
                this.isNewMatch = response.isNewMatch
                this.arrangerPoint = response.arrangerPoint
            } else {
                alert(response.message)
                return true
            }


            let params = { type: "manager" }
            if (adminRes.success) {
                if (adminRes.admin.permission !== 'super') {
                    params._admin = adminRes.admin._id
                }
            }
            response = await Admin.get(params)
            if (response && response.success) {
                this.managers = response.admins.sort(function (a, b) {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })
            } else {
                alert(response.message)
                return true
            }
            super.componentDidMount()
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _update = async (e) => {
        e.preventDefault()
        let params = {
            email: this.user.email,
            status: this.user.status,
            _manager: this.managerId,
            arrangerPoint: this.arrangerPoint,
            phone: this.user.phone,
            memo: this.user.memo
        }
        try {
            const response = await User.update(this.user._id, params)
            if (response && response.success) {
                alert("정보가 업데이트 되었습니다.")
                return true
                //this.props.history.push("/user")
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _delete = async (e) => {
        e.preventDefault()
        const confirm = window.confirm("삭제하시겠습니까?")
        if (confirm) {
            try {
                const response = await User.delete(this.user._id)
                if (response && response.success) {
                    alert("회원 삭제가 완료되었습니다.")
                    this.props.history.push("/user")
                } else {
                    alert(response.message)
                }
            } catch (e) {
                alert(e.toString())
            }
        }
    }

    setUserStatus = (status) => {
        let statusName = ''

        if (status === 'approval') statusName = '가입완료'
        if (status === 'wait') statusName = '가입심사'
        if (status === 'rejoin') statusName = '재가입'
        if (status === 'reject') statusName = '가입거절'
        if (status === 'rest') statusName = '휴면'
        if (status === 'withdraw') statusName = '회원탈퇴'
        return statusName
    }

    _render = () => (
        <UserShowLayout user={this.user} title={"계정정보"} path={this.path} isNewMatch={this.isNewMatch} responseMatchCount={this.responseMatchCount} permission={this.myPermission}>
            <form>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">ID</label>
                    <div className="col-sm-10">
                        <input type="text" readOnly className="form-control-plaintext" value={this.user._id} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">Email</label>
                    <div className="col-sm-10">
                        {
                            this.myPermission === "super" ?
                                <input type="text" className="form-control" value={this.user.email} onChange={e => this.user.email = e.target.value} /> :
                                <input type="text" readOnly className="form-control-plaintext" value={this.user.email} />
                        }
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">본인인증</label>
                    <div className="col-sm-10">
                        <input type="text" readOnly className="form-control-plaintext" value={GetValue(this, "this.user.auth.date") && "인증완료"} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">휴대전화</label>
                    <div className="col-sm-10">
                        {
                            this.myPermission === 'super' ?
                                <input type="text" className="form-control" value={this.user.phone} onChange={e => this.user.phone = e.target.value} /> :
                                <input type="text" readOnly className="form-control-plaintext" value={this.user.phone} onChange={e => this.user.phone = e.target.value} />
                        }
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">회원가입상태</label>
                    <div className="col-sm-10">
                        {
                            this.myPermission === 'super' ?
                                <select name="status" className="form-control" value={GetValue(this, "this.user.status")} onChange={e => this.user.status = e.target.value}>
                                    <option value="wait">가입심사</option>
                                    <option value="approval">가입완료</option>
                                    <option value="rejoin">재가입</option>
                                    <option value="reject">가입거절</option>
                                    <option value="rest">휴면</option>
                                    <option value="withdraw">회원탈퇴</option>
                                </select> :
                                <input type="text" readOnly className="form-control-plaintext" value={this.setUserStatus(this.user.status)} />
                        }

                    </div>
                </div>
                <div className="form-group row" style={{ display: (this.myPermission === 'super' ? 'visible' : 'none') }}>
                    <label className="col-sm-2 col-form-label small">최초담당케어</label>
                    <div className="col-sm-10">
                        <input type="text" readOnly className="form-control-plaintext" value={this.user.initTeam} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">담당매니저</label>
                    <div className="col-sm-10">
                        {
                            this.myPermission === 'super' || this.myPermission === 'admin' ?
                                <select className="form-control" value={this.managerId} onChange={e => this.managerId = e.target.value}>
                                    <option value="nothing">없음</option>
                                    {
                                        this.managers.map((value, index) =>
                                            <option key={index} value={value._id}>{value.name}</option>)
                                    }
                                </select> :
                                <input type="text" readOnly className="form-control-plaintext" value={this.managerName} />
                        }

                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">코맨트</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" cols="30" rows="10" value={this.user.memo} onChange={e => this.user.memo = e.target.value} />
                    </div>
                </div>
                {
                    GetValue(this, "this.user._arranger") &&
                    <>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">주선자</label>
                            <div className="col-sm-10">
                                <input type="text" readOnly className="form-control-plaintext" value={`${GetValue(this, "this.user._arranger.name", "없음")}`} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">주선자 포인트</label>
                            <div className="col-sm-10">
                                <select className="form-control" value={this.arrangerPoint} onChange={e => this.arrangerPoint = e.target.value}>
                                    <option value="0">0</option>
                                    <option value="5000">5000</option>
                                    <option value="10000">10000</option>
                                </select>
                            </div>
                        </div>
                    </>
                }
                {
                    GetValue(this, "this.user.status") === "withdraw" && GetValue(this, "this.user.withdrawAt") &&
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">탈퇴일</label>
                        <div className="col-sm-10">
                            <input readOnly className="form-control-plaintext" value={getMoment(this.user.withdrawAt)} />
                        </div>
                    </div>
                }
                <button type="button" className="btn btn-secondary" onClick={this._update} style={{ marginRight: 5 }}>수정하기</button>
                {
                    this.myPermission === 'super' &&
                    <button type="button" className="btn btn-light" onClick={this._delete}>삭제</button>

                }
            </form>
        </UserShowLayout>
    )
}

export default UserShowScreen