import _Component from "../../common/_Component"
import React from "react"
import { observable } from "mobx"
import AdminShowLayout from "../../layouts/AdminShowLayout"
import GetValue from "../../utils/GetValue"
import { addComma, diffDays, isId } from "../../utils/Utils"
import Arranger from "../../api/Arranger"

class AdminShowArrangeSalesScreen extends _Component {
    @observable sales = null
    @observable admin = null
    @observable permission = ""

    async componentDidMount() {
        try {
            this.admin = await this._getAdmin()
            if (this.admin && this.admin.permission) this.permission = this.admin.permission
            if (GetValue(this, "this.admin.permission") !== "arranger") {
                alert("잘못된 접근입니다.")
                this.props.history.goBack()
                return true
            }
            const _id = GetValue(this, "this.props.match.params.id")
            if (!_id || !isId(_id)) {
                alert("잘못된 접근입니다.")
                this.props.history.goBack()
                return true
            }
            const response = await Arranger.show(this.admin._id)
            if (response && response.success) {
                this.sales = response.sales
                super.componentDidMount()
            }
        } catch (e) {
            console.log(e)
        }
    }

    _render = () => (
        <AdminShowLayout title={"마이포인트"} admin={this.admin} _id={this.admin._id} path={this.path} permission={this.permission}>
            <div className="table-responsive">
                <table className="table text-nowrap">
                    <tbody>
                        <tr>
                            <th>가입회원수</th>
                            <td>{addComma(GetValue(this, "this.sales.userCount", 0))}</td>
                        </tr>
                        <tr>
                            <th>당월포인트</th>
                            <td>{addComma(GetValue(this, "this.sales.pointForThisMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>전월포인트</th>
                            <td>{addComma(GetValue(this, "this.sales.pointForLastMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>추가 안한기간</th>
                            {
                                GetValue(this, "this.sales.createdAt") == null
                                    ?
                                    <td>추가데이터 없음</td>
                                    :
                                    <td>{diffDays(GetValue(this, "this.sales.createdAt"))}</td>
                            }

                        </tr>
                        <tr>
                            <th>전월 일반 가입</th>
                            <td>{addComma(GetValue(this, "this.sales.normalRegisterForLastMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>전월 프리미엄 가입</th>
                            <td>{addComma(GetValue(this, "this.sales.premiumRegisterForLastMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>전월실패수</th>
                            <td>{addComma(GetValue(this, "this.sales.failForLastMonth", 0))}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </AdminShowLayout>
    )
}

export default AdminShowArrangeSalesScreen