import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import GetValue from './GetValue'

const AvailableSuccess = {
    getJSXElementByUser: user => {
        let jsx = null
        try {
            if (!user)
                return
            
            const matches = user._matchAvailable ? user._matchAvailable : user._match
            if (!matches || 0 >= matches.length)
                return 
            
            let result = -999
            let remainSeconds = Number.MAX_VALUE

            matches.some(item => {
                const calcData = AvailableSuccess.calculate(user._id, item)
                if (result < calcData.result) {
                    result = calcData.result
                    switch (result) {
                        case AvailableSuccess.Result.Wait:
                            if (remainSeconds > calcData.remainSeconds) {
                                jsx = AvailableSuccess.toJSXElement(calcData)
                                remainSeconds = calcData.remainSeconds
                            }
                            break

                        case AvailableSuccess.Result.Available:
                        case AvailableSuccess.Result.OldVersion:
                            jsx = AvailableSuccess.toJSXElement(calcData)
                            break

                        case AvailableSuccess.Result.Final:
                            jsx = AvailableSuccess.toJSXElement(calcData)
                            return true
                    }
                }

                return false
            })
        } finally {
            if (!jsx)
                jsx = (<></>)

            return jsx
        }
    },

    getJSXElementByMatch: (userId, match) => {
        const calcData = AvailableSuccess.calculate(userId, match)
        return AvailableSuccess.toJSXElement(calcData)
    },

    toJSXElement: calcData => {
        const {
            result,
            remainSeconds,
        } = calcData

        switch (result) {
            case AvailableSuccess.Result.Wait:
                {
                    if (59 > remainSeconds) {
                        const remainSecondsDP = remainSeconds + 1
                        return (<span className='badge badge-pill badge-danger'>{`${remainSecondsDP}초`}</span>)
                    }

                    const remainMinuteDP = Math.ceil(remainSeconds / 60)
                    return (<span className='badge badge-pill badge-danger'>{`${remainMinuteDP}분`}</span>)
                }

            case AvailableSuccess.Result.Available:
                return (<span className='badge badge-pill badge-success'>성사가능</span>)

            case AvailableSuccess.Result.Final:
                return (<span className='badge badge-pill badge-info'>최종성사</span>)

            case AvailableSuccess.Result.OldVersion:
                return (<span className='badge badge-pill badge-secondary'>이전버전</span>)

            default:
            case AvailableSuccess.Result.None:
                return (<></>)
        }
    },

    calculate: (userId, match) => {
        const user = GetValue(match, 'match._response._user')
        const responseId = !user ? null :
                           'string' !== typeof (user) ? GetValue(user, 'user._id') :
                           user

        if (responseId == userId) {
            const matchingStep = GetValue(match, 'match.matchingStep')
            if (2 > matchingStep) {
                const status = GetValue(match, 'match.status')
                if ('response' === status) {
                    const availableSuccessAt = GetValue(match, 'match.availableSuccessAt')
                    if (availableSuccessAt) {
                        // NOTE: jason_wang: 쌍방 이어줘
                        const remainSeconds = moment(availableSuccessAt).diff(moment(), 'seconds')
                        return { result: 0 > remainSeconds ? AvailableSuccess.Result.Available : AvailableSuccess.Result.Wait, remainSeconds: remainSeconds }
                    } else if (1 == matchingStep) {
                        // NOTE: jason_wang: 쌍방 이어줘가 아니다
                        return { result: AvailableSuccess.Result.None, remainSeconds: -1 }
                    }

                    // NOTE: jason_wang: 이전 데이터
                    return { result: AvailableSuccess.Result.OldVersion, remainSeconds: -1 }
                }

                if ('wait' === status) {
                    if (1 == matchingStep) {
                        // NOTE: jason_wang: 쌍방 이어줘 이후 한쪽이 성사시킴
                        return { result: AvailableSuccess.Result.Final, remainSeconds: -1 }
                    }
                }
            }
        }

        // NOTE: jason_wang: 비활성 (성사를 했거나, 취소를 했거나, 아니면 이어줘 이전 단계)
        return { result: AvailableSuccess.Result.None, remainSeconds: 0 }
    },

    Result: {
        None: 0,
        Wait: 1,
        Available: 2,
        Final: 3,

        OldVersion: -1,
    }
}

export default AvailableSuccess