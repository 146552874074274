import Mime from './mime.helper'
import axios from 'axios'

const Upload = {
    execute: async (files, getUrls) => {
        const uploads = []
        try {
            if (!files || !files.length)
                return Upload.fail(uploads, '업로드할 파일이 없습니다', 400)

            const fileInfos = {}
            const inputs = []
            for (let n = 0, count = files.length; n < count; ++n) {
                const file = files[n]
                const mime = await Mime.getMime(file)
                if (!mime)
                    return Upload.fail(uploads, 'PNG 또는 JPG 형식 이미지를 지원합니다', 400)
                const input = {
                    basename: file.name,
                    type: mime
                }
                inputs.push(input)
                fileInfos[file.name] = {
                    file,
                    input,
                }
            }

            const responseUrls = await getUrls({ inputs })
            if (!responseUrls.success)
                return Upload.fail(uploads, responseUrls.message, responseUrls.status)

            const targets = responseUrls.targets
            if (!targets || !targets.length)
                return Upload.fail(uploads, 'API 서버 에러가 발생하였습니다.\n고객센터에 문의 바랍니다', 500)

            for (let n = 0, count = targets.length; n < count; ++n) {
                const target = targets[n]
                const fileInfo = fileInfos[target.basename]
                const file = fileInfo.file
                const input = fileInfo.input
                const type = input.type

                const filename = target.filename
                const signedUrl = target.url
                const fields = target.fields

                let formData = new FormData()
                formData.append('Content-Type', type)
                Object.keys(fields).forEach(key => formData.append(key, fields[key]))
                formData.append('file', file)

                try {
                    await axios.post(signedUrl, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                } catch (e) {
                    if (e.response) {
                        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                        return Upload.fail(uploads, `${file.name}(${filename}): Status = ${e.response.status}`, e.response.status)
                    }
                    else if (e.request) {
                        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                        // `e.request`는 브라우저의 XMLHttpRequest 인스턴스 또는 Node.js의 http.ClientRequest 인스턴스입니다.
                        return Upload.fail(uploads, `${file.name}(${filename}): No response: ${e.message}`, 408)
                    }
                    else {
                        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                        return Upload.fail(uploads, `${file.name}(${filename}): Internal = ${e.message}`, 500)
                    }
                }

                uploads.push({
                    file,
                    filename,
                })
            }

            return Upload.success(uploads)
        } catch (e) {
            return Upload.fail(uploads, e.message, 500)
        }
    },

    success: uploads => ({ uploads, error: null, status: 200 }),

    fail: (uploads, error, status) => ({ uploads, error, status }),
}

export default Upload