import _Component from "../../common/_Component"
import React from "react"
import { action, observable } from "mobx"
import GetValue from "../../utils/GetValue"
import { getMoment, isId } from "../../utils/Utils"
import User from "../../api/User"
import UserShowLayout from "../../layouts/UserShowLayout"
import IconButton from "@material-ui/core/IconButton"
import { Delete, Visibility } from "@material-ui/icons"
import Pagination from "../../components/Pagination"
import Match from "../../api/Match"
import { getProfileUrl } from "../../utils/ImageUtil"
import { inject } from "mobx-react"
import AvailableSuccess from "../../utils/AvailableSuccess"
import ShowType from "../../utils/ShowType"
import ShownStatus from "../../utils/ShownStatus"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class UserRequestScreen extends _Component {
    @observable user = null
    @observable status = ""
    @observable matches = []
    @observable pagination = {}
    @observable myPermission = ""

    async componentDidMount() {
        this.myPermission = this.props.loginStore.permission

        try {
            await this._getUser()
            await this._getData()
            super.componentDidMount()
        } catch (e) {
            console.log(e)
        }
    }

    @action
    _getUser = async () => {
        const _id = GetValue(this, "this.props.match.params.id")
        if (!_id || !isId(_id)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
        }
        try {
            let response = await User.show(_id)
            if (response && response.success) {
                this.user = response.user
                this.responseMatchCount = response.responseMatchCount
                this.isNewMatch = response.isNewMatch
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _getData = async (page = 1) => {
        try {
            const response = await Match.get({
                _user: this.user._id,
                status: this.status,
                page: page,
                limit: this.limit,
                type: "request"
            })
            if (response && response.success) {
                this.matches = response.matches
                this.pagination = response.pagination
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _changeStatus = async (e) => {
        e.preventDefault()
        this.status = e.target.value
        await this._getData()
    }

    _render = () => (
        <UserShowLayout user={this.user} path={this.path} title={"소개/이어줘 요청"} isNewMatch={this.isNewMatch} responseMatchCount={this.responseMatchCount} permission={this.myPermission}>
            <div className="d-md-flex">
                <div className="form-group mr-2">
                    <label htmlFor="search">요청 리스트</label>
                    <select className="form-control" id="filter_status" value={this.status} onChange={this._changeStatus}>
                        <option value={""}>전체</option>
                        <option value={"show"}>소개해줘</option>
                        <option value={"request"}>소개함</option>
                        <option value={"response"}>이어줘</option>
                        <option value={"wait"}>성사대기</option>
                        <option value={"success"}>매칭성사</option>
                        <option value={"fail"}>매칭실패</option>
                    </select>
                </div>
                {
                    this.myPermission === 'super' &&
                    <div style={{ marginTop: 32 }}>
                        <button className="btn btn-outline-secondary"
                            onClick={e => this._delete(e, "match", this._getData.bind(this), this.pagination.currentPage, this.matches)}>선택삭제
                        </button>
                    </div>
                }
            </div>
            <div className="table-responsive">
                <table className="table text-nowrap">
                    <thead>
                        <tr>
                            <th scope="col">
                                <input type="checkbox" onChange={e => this._checkAll(e, this.matches)} />
                            </th>
                            <th scope="col">Atc.</th>
                            <th scope="col">요청</th>
                            <th scope="col"></th>
                            <th scope="col">받는사람</th>
                            <th scope="col">소개요청일</th>
                            <th scope="col">소개추천일</th>
                            <th scope="col">미팅일정</th>
                            <th scope="col">수정일</th>
                            <th scope="col">ID</th>
                          </tr>
                    </thead>
                    <tbody>
                    {
                        this.matches && this.matches.length > 0 && this.matches.map((value, index) => (
                            <tr key={index}>
                                <td>
                                    <input type="checkbox" checked={!!value.checked} onChange={e => this.matches[index].checked = e.target.checked} />
                                </td>
                                <td>
                                    <IconButton
                                    className="text-info fas"
                                    aria-label="visibility"
                                    style={{ padding: 0, outline: "none" }}
                                    href={`/user/${this.user._id}/request/${value._id}`}
                                    >
                                        <Visibility />
                                    </IconButton>
                                    {
                                        this.myPermission === 'super' && 
                                        <IconButton
                                            color={"secondary"}
                                            aria-label="delete"
                                            style={{ padding: 0, outline: "none" }}
                                            onClick={e => this._delete(e, `match/${value._id}`, this._getData.bind(this), this.pagination)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    }
                                    
                                </td>
                                <td>
                                    {
                                        GetValue(value, "value.status") === "show" &&
                                        ShowType.getJSXElementByMatch(value)
                                    }
                                    {
                                        GetValue(value, "value.status") === "request" &&
                                        ShownStatus.getJSXElementByMatch(value)
                                    }
                                    {
                                        GetValue(value, "value.status") === "response" && value._request._user && value._request._user._id === this.user._id &&
                                        <span className="badge badge-pill badge-success">이어줘</span>
                                    }
                                    {
                                        GetValue(value, "value.status") === "response" && value._response._user && value._response._user._id === this.user._id  && value._response.answer === null &&
                                        <span className="badge badge-pill badge-success">이어줘받음</span>
                                    }
                                    {
                                        GetValue(value, "value.status") === "wait" &&
                                        <span className="badge badge-pill badge-info">성사대기</span>
                                    }
                                    {
                                        GetValue(value, "value.status") === "success" &&
                                        <span className="badge badge-pill badge-primary">매칭성사</span>
                                    }
                                    {
                                        GetValue(value, "value.status") === "fail" &&
                                        <span className="badge badge-pill badge-secondary">매칭실패</span>
                                    }
                                </td>
                                <td>
                                    {
                                        value._response._user &&
                                        <img className="align-self-center mr-3" style={{
                                            width: 32,
                                            height: 32,
                                            borderRadius: 16
                                        }} src={getProfileUrl(GetValue(value, "value._response._user._photos._main"))} alt="profile" />
                                    }
                                </td>
                                <td>
                                    {
                                        value._response._user &&
                                        <span>{value._response._user.name}</span>
                                    }
                                </td>
                                <td>{getMoment(value.createdAt)}</td>
                                <td>{getMoment(value._request.answer)}</td>
                                <td>
                                {
                                    GetValue(value, "value.meetAt") ?
                                        getMoment(GetValue(value, "value.meetAt"))
                                        : "미입력"
                                }
                                </td>
                                <td>{getMoment(value.updatedAt)}</td>
                                <td>{value._id}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                  </table>
            </div>
                <div className="d-flex">
                <div className="d-flex mr-auto">
                    <div>
                        <select className="form-control form-control-sm" value={this.limit} onChange={e => this._changeLimit(e, this._getData.bind(this))}>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                            <option>50</option>
                          </select>
                    </div>
                </div>
                <div>
                    <nav aria-label="Page navigation example">
                        <Pagination handle={this._getData.bind(this)} pagination={this.pagination} />
                    </nav>
                </div>
            </div>
        </UserShowLayout>
    )
}

export default UserRequestScreen