import _Component from "../../common/_Component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import { IconButton } from '@material-ui/core'
import { Delete, Visibility } from "@material-ui/icons"
import React from "react"
import { observable } from "mobx"
import Admin from "../../api/Admin"
import { getMoment, getPermission, isLineNumber } from "../../utils/Utils"
import Pagination from "../../components/Pagination"

class AdminScreen extends _Component {
    @observable search = ''
    @observable admins = []
    @observable pagination = {}

    async componentDidMount() {
        await this._getData(this.page)
    }

    _search = async (e) => {
        e.preventDefault()
        await this._getData(1)
    }

    _getData = async (page = 1) => {
        try {
            const lineNumber = isLineNumber(this.search)
            const response = await Admin.get({
                type: "admin",
                page: page,
                name: lineNumber ? null : this.search,
                lineNumber: lineNumber ? this.search : null,
                limit: this.limit,
            })
            if (response && response.success) {
                this.admins = response.admins
                this.pagination = response.pagination
                this.loaded = true
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _render = () => (
        <div className="container-fluid">
            <div className="card mb-4">
                <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />관리자 목록</div>
                <div className="card-body">
                    <div className="d-md-flex">
                        <div className="form-group mr-2">
                            <label htmlFor="search">관리자검색</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="관리자이름 또는 뒷번호" value={this.search} onChange={e => this.search = e.target.value} />
                                <div className="input-group-append">
                                  <button className="btn btn-outline-secondary" type="button" onClick={this._search}>검색</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-md-flex mb-3">
                        <div style={{ marginTop: 32 }}>
                            <a href={"/admin/create"} className="btn btn-outline-success" style={{ marginRight: 5 }}>데이터 추가</a>
                            <button
                                className="btn btn-outline-secondary"
                                onClick={e => this._delete(e, "admin", this._getData.bind(this), this.pagination.currentPage, this.admins)}>선택삭제
                            </button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table text-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input type="checkbox" onChange={e => this._checkAll(e, this.admins)} />
                                    </th>
                                    <th scope="col">Atc.</th>
                                    <th scope="col">이메일</th>
                                    <th scope="col">이름</th>
                                    <th scope="col">권한</th>
                                    <th scope="col">마지막 접속일</th>
                                    <th scope="col">등록일</th>
                                    <th scope="col">수정일</th>
                                  </tr>
                            </thead>
                            <tbody>
                            {
                                this.admins && this.admins.length > 0 && this.admins.map((value, index) => (
                                    <tr key={value._id}>
                                    <td>
                                        <input type="checkbox" checked={!!value.checked} onChange={(e) => this.admins = this._check(e, this.admins, index)} />
                                    </td>
                                    <td>
                                        <IconButton
                                            className="text-info fas"
                                            aria-label="visibility"
                                            style={{ padding: 0, outline: "none" }}
                                            href={`/admin/${value._id}`}
                                        >
                                          <Visibility />
                                        </IconButton>
                                        <IconButton
                                            color={"secondary"}
                                            aria-label="delete"
                                            style={{ padding: 0, outline: "none" }}
                                            onClick={e => this._delete(e, `admin/${value._id}`, this._getData.bind(this), this.pagination.currentPage)}
                                        >
                                          <Delete />
                                        </IconButton>
                                    </td>
                                    <td>{value.email}</td>
                                    <td>{value.name}</td>
                                    <td>{getPermission(value.permission)}</td>
                                    <td>{value.loginAt ? getMoment(value.loginAt) : ""}</td>
                                    <td>{getMoment(value.createdAt)}</td>
                                    <td>{getMoment(value.updatedAt)}</td>
                                </tr>
                                ))
                            }
                            </tbody>
                          </table>
                    </div>
                    <div className="d-flex">
                        <div className="d-flex mr-auto">
                            <div>
                                <select className="form-control form-control-sm" value={this.limit} onChange={e => this._changeLimit(e, this._getData.bind(this))}>
                                    <option value={"10"}>10</option>
                                    <option value={"20"}>20</option>
                                    <option value={"30"}>30</option>
                                    <option value={"40"}>40</option>
                                    <option value={"50"}>50</option>
                                  </select>
                            </div>
                        </div>
                        <div>
                            <nav aria-label="Page navigation example">
                                <Pagination handle={this._getData.bind(this)} pagination={this.pagination} />
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminScreen