import _Component from "../../common/_Component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import { IconButton } from '@material-ui/core'
import { Delete, Visibility } from "@material-ui/icons"
import React from "react"
import { action, observable } from "mobx"
import Pagination from "../../components/Pagination"
import Config from "../../config/config"
import Product from "../../api/Product"
import { addComma, getMoment } from "../../utils/Utils"
import Goods from '../../helper/goods.helper'

class ProductScreen extends _Component {
    @observable admins = []
    @observable search = ''
    @observable charges = []
    @observable chargePagination = null
    @observable chargeSearch = ""
    @observable chargeLimit = Config.defaultLimit
    @observable tickets = []
    @observable ticketPagination = null
    @observable ticketSearch = ""
    @observable ticketLimit = Config.defaultLimit
    @observable premiums = []
    @observable premiumPagination = null
    @observable premiumSearch = ""
    @observable premiumLimit = Config.defaultLimit
    @observable pagination = {}

    async componentDidMount() {
        await this._getData("charge", 1)
        await this._getData("ticket", 1)
        await this._getData("premium", 1)
        this.loaded = true
    }

    _search = async (e, option) => {
        e.preventDefault()
        await this._getData(option, 1)
    }

    @action
    _getData = async (type = "charge", page = 1) => {
        const name = type === "charge" ? this.chargeSearch : (type === "ticket" ? this.ticketSearch : this.premiumSearch)
        const limit = type === "charge" ? this.chargeLimit : (type === "ticket" ? this.ticketLimit : this.premiumLimit)
        try {
            const response = await Product.get({ type: type, page: page, name: name, limit: limit })
            if (response && response.success) {
                if (type === "charge") {
                    this.charges = response.products
                    this.chargePagination = response.pagination
                } else if (type === "ticket") {
                    this.tickets = response.products
                    this.ticketPagination = response.pagination
                } else {
                    this.premiums = response.products
                    this.premiumPagination = response.pagination
                }
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    getGender = (gender) => {
        return 'male' === gender ? '남성만' :
               'female' === gender ? '여성만' : '모두'
    }

    getTotalPrice = product => {
        const price = product.showItem_price +
            product.connectItem_price +
            product.standardTicket_price +
            product.goldTicket_price +
            product.platinumTicket_price +
            product.diamondTicket_price
        return price.toString()
    }

    _renderList = (mode = "charge") => {
        const data = (mode === "charge" ? this.charges : (mode === "ticket" ? this.tickets : this.premiums))
        const pagination = mode === "charge" ? this.chargePagination : (mode === "ticket" ? this.ticketPagination : this.premiumPagination)
        const limit = mode === "charge" ? this.chargeLimit : (mode === "ticket" ? this.ticketLimit : this.premiumLimit)
        const search = mode === "charge" ? this.chargeSearch : (mode === "ticket" ? this.ticketSearch : this.premiumSearch)
        const title = mode === "charge" ? "아이템충전" : (mode === "ticket" ? "성사이용권" : "프리미엄이용권")
        const check = (e, index) => {
            if (mode === "charge") {
                this.charges = this._check(e, this.charges, index)
            }
            if (mode === "ticket") {
                this.tickets = this._check(e, this.tickets, index)
            }
            if (mode === "premium") {
                this.premiums = this._check(e, this.premiums, index)
            }
        }
        const setSearch = (e) => {
            if (mode === "charge") this.chargeSearch = e.target.value
            if (mode === "ticket") this.ticketSearch = e.target.value
            if (mode === "premium") this.premiumSearch = e.target.value
        }

        const changeLimit = async (e) => {
            e.preventDefault()
            if (mode === "charge") this.chargeLimit = e.target.value
            if (mode === "ticket") this.ticketLimit = e.target.value
            if (mode === "premium") this.premiumLimit = e.target.value
            await this._getData(mode, 1)
        }

        return (
            <div className="card mb-4">
                <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />{title} 목록</div>
                <div className="card-body">
                    <div className="d-md-flex">
                        <div className="form-group mr-2">
                            <label htmlFor="search">검색</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="검색" value={search} onChange={setSearch} />
                                <div className="input-group-append">
                                  <button className="btn btn-outline-secondary" type="button" onClick={e => this._search(e, mode)}>검색</button>
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <a href={"/product/create"} className="btn btn-outline-success" style={{ marginRight: 5 }}>데이터 추가</a>
                                    <button
                                        className="btn btn-outline-secondary"
                                        onClick={e => this._delete(e, "product", this._getData.bind(this), pagination.currentPage, (mode === "charge" ? this.charges : (mode === "ticket" ? this.tickets : this.premiums)), mode)}>선택삭제
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-md-flex mb-3">

                    </div>
                    <div className="table-responsive">
                        <table className="table text-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input type="checkbox" onChange={e => this._checkAll(e, mode === "charge" ? this.charges : (mode === "ticket" ? this.tickets : this.premiums))} />
                                    </th>
                                    <th scope="col">Atc.</th>
                                    <th scope="col">이름</th>
                                    <th scope="col">정가</th>
                                    <th scope="col">구매가능성별</th>
                                    <th scope="col">더보여줘</th>
                                    <th scope="col">이어줘</th>
                                    <th scope="col">스탠다드</th>
                                    <th scope="col">골드</th>
                                    <th scope="col">플레티넘</th>
                                    <th scope="col">다이아</th>
                                    <th scope="col">등록일</th>
                                    <th scope="col">수정일</th>
                                  </tr>
                            </thead>
                            <tbody>
                            {
                                data && data.length > 0 && data.map((value, index) => (
                                    <tr key={value._id}>
                                        <td>
                                            <input type="checkbox" checked={!!value.checked} onChange={(e) => check(e, index)} />
                                        </td>
                                        <td>
                                            <IconButton
                                                className="text-info fas"
                                                aria-label="visibility"
                                                style={{ padding: 0, outline: "none" }}
                                                href={`/product/${value._id}`}
                                            >
                                            <Visibility />
                                            </IconButton>
                                            <IconButton
                                                color={"secondary"}
                                                aria-label="delete"
                                                style={{ padding: 0, outline: "none" }}
                                                onClick={e => this._delete(e, `product/${value._id}`, this._getData.bind(this), pagination.currentPage, null, mode)}
                                            >
                                            <Delete />
                                            </IconButton>
                                        </td>
                                        <td>{value.name}</td>
                                        <td>{addComma(this.getTotalPrice(value))}원</td>
                                        <td>{this.getGender(value.gender)}</td>
                                        <td>{(value.showItem_free + value.showItem_paid)}</td>
                                        <td>{(value.connectItem_free + value.connectItem_paid)}</td>
                                        <td>{(value.standardTicket_free + value.standardTicket_paid)}</td>
                                        <td>{(value.goldTicket_free + value.goldTicket_paid)}</td>
                                        <td>{(value.platinumTicket_free + value.platinumTicket_paid)}</td>
                                        <td>{(value.diamondTicket_free + value.diamondTicket_paid)}</td>
                                        <td>{getMoment(value.createdAt)}</td>
                                        <td>{getMoment(value.updatedAt)}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                          </table>
                    </div>
                    {
                        pagination && <div className="d-flex">
                        <div className="d-flex mr-auto">
                            <div style={{ marginTop: 5, marginRight: 5 }}>
                                1-30 of 50
                            </div>
                            <div>
                                <select className="form-control form-control-sm" defaultValue={limit} onChange={e => changeLimit(e)}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={40}>40</option>
                                    <option value={50}>50</option>
                                  </select>
                            </div>
                        </div>
                        <div>
                            <nav aria-label="Page navigation example">
                                <Pagination type={mode} handle={this._getData.bind(this)} pagination={pagination} />
                            </nav>
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }

    _render = () => (
        <div className="container-fluid">
            {this._renderList()}
            {this._renderList("ticket")}
            {this._renderList("premium")}
        </div>
    )
}

export default ProductScreen