import React, { useEffect, useState } from "react"
import Pagination from "react-js-pagination"
import 'bootstrap/dist/css/bootstrap.min.css'

export default (props) => {
    const { handle, pagination, type } = props
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(0)
    const [total, setTotal] = useState(1)
    const [handleType, setHandleType] = useState("")

    useEffect(() => {
        if (pagination) {
            setPage(pagination.currentPage)
            setLimit(pagination.limit)
            setTotal(pagination.total)
        }
        if (type) setHandleType(type)
    }, [pagination, type])


    const handlePageChange = (pageNumber) => {
        if (handleType) {
            handle(handleType, pageNumber)
        } else {
            handle(pageNumber)
        }
    }

    return (
        <div className="pagination">
            {pagination && <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={page}
                itemsCountPerPage={limit}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
            />}
      </div>
    )
}