import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import './App.css'
import LoginScreen from "./screens/Main/LoginScreen"
import PasswordScreen from "./screens/Main/PasswordScreen"
import LoginLayouts from "./layouts/LoginLayouts"
import NotFountScreen from "./screens/Error/NotFountScreen"
import DashboardRoutes from "./routes/DashboardRoutes"
import AdminRoutes from "./routes/AdminRoutes"
import LogoutScreen from "./screens/Main/LogoutScreen"
import ProductRoutes from "./routes/ProductRoutes"
import UserRoutes from "./routes/UserRoutes"
import ManagerRoutes from "./routes/ManagerRoutes"
import ArrangerRoutes from "./routes/ArrangerRoutes"
// jason_wang: 안되는거 같다
// import TeamRoutes from './routes/TeamRoutes'
import Admin from './api/Admin'
import Config from './config/config'

function App() {
    const [loaded, setLoaded] = useState(false)
    const [permission, setPermission] = useState("")
    const [isLogin, setIsLogin] = useState(false)
    useEffect(() => {
        const setMyPer = async () => {
            try {
                const token = localStorage.getItem("token")
                const per = localStorage.getItem("permission")
                const resAdmin = await Admin.my()
                if (resAdmin.success) {
                    setIsLogin(true)
                    setPermission(per)
                } else {
                    localStorage.clear()
                    setIsLogin(false)
                }
                // if (token !== null && per !== null) {
                //     const resAdmin = await Admin.my()
                //     if( resAdmin.success ) {
                //         setIsLogin(true)
                //         setPermission(per)
                //     } else {
                //         localStorage.clear()
                //         setIsLogin(false)
                //         window.location.href = ${Config.clientUrl}
                //     }
                // } else {
                //     setIsLogin(false)
                // }
                setLoaded(true)
            } catch (error) {
                localStorage.clear()
                setIsLogin(false)
            }

        }
        setMyPer()
    }, [isLogin, loaded])

    return (
        loaded && <Router>
            <Switch>
                <Route exact path={"/"}>
                    {isLogin && permission === "super" && <Redirect to={"/dashboard"} />}
                    {isLogin && permission === "admin" && <Redirect to={"/user"} />}
                    {isLogin && permission === "manager" && <Redirect to={"/manager"} />}
                    {isLogin && permission === "arranger" && <Redirect to={"/arranger"} />}
                    {!isLogin && <Redirect to={"/login"} />}
                </Route>
                <Route path={"/logout"} component={LogoutScreen} />
                <LoginLayouts path={"/login"} component={LoginScreen} />
                <LoginLayouts path={"/password"} component={PasswordScreen} />

                <Route path={"/dashboard"} component={DashboardRoutes} />
                <Route path={"/admin"} component={AdminRoutes} />
                <Route path={"/manager"} component={ManagerRoutes} />
                <Route path={"/arranger"} component={ArrangerRoutes} />
                <Route path={"/product"} component={ProductRoutes} />
                <Route path={"/user"} component={UserRoutes} />


                <Route path={"/not-found"} component={NotFountScreen} />
                <Route component={NotFountScreen} />
            </Switch>
        </Router>
    )
}

export default App