import Api from "./Api"
const url = "admin"
const Admin = {
    get: async (params = {}) => {
        return await Api.get(`${url}`, params)
    },
    show: async (id) => {
        return await Api.get(`${url}/${id}`)
    },
    save: async (params = {}) => {
        return await Api.post(`${url}`, params)
    },
    update: async (id, params = {}) => {
        return await Api.patch(`${url}/${id}`, params)
    },
    delete: async (id) => {
        return await Api.delete(`${url}/${id}`)
    },
    login: async (params = {}) => {
        return await Api.post(`${url}/login`, params)
    },
    password: async (params = {}) => {
        return await Api.post(`${url}/password`, params)
    },
    my: async () => {
        return await Api.get(`${url}/my`)
    },
    getTeamList: async (params) => {
        return await Api.get(`${url}/team?all=all`)
    },
    getTeamNames: async (params) => {
        return await Api.get(`${url}/tname?_sort=dsc`)
    }
}

export default Admin