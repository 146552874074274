import _Component from "../../common/_Component"
import React from "react"
import { action, observable } from "mobx"
import GetValue from "../../utils/GetValue"
import { addComma, getMoment, isId } from "../../utils/Utils"
import User from "../../api/User"
import History from "../../api/History"
import UserShowLayoutMulti from "../../layouts/UserShowLayoutMulti"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import { inject } from "mobx-react"
import Goods from '../../helper/goods.helper'

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class UserServiceScreen extends _Component {
    @observable user = null
    @observable myPermission = ""
    @observable successMatchCount = 0

    @observable price = 0

    @observable showItem = null
    @observable showItem_before = null

    @observable connectItem = null
    @observable connectItem_before = null

    @observable standardTicket = null
    @observable standardTicket_before = null

    @observable goldTicket = null
    @observable goldTicket_before = null

    @observable platinumTicket = null
    @observable platinumTicket_before = null

    @observable diamondTicket = null
    @observable diamondTicket_before = null

    getHistoryDefault = () => {
        return {
            showItem_free: 0,
            showItem_paid: 0,
            showItem_price: 0,

            connectItem_free: 0,
            connectItem_paid: 0,
            connectItem_price: 0,

            standardTicket_free: 0,
            standardTicket_paid: 0,
            standardTicket_price: 0,

            goldTicket_free: 0,
            goldTicket_paid: 0,
            goldTicket_price: 0,

            platinumTicket_free: 0,
            platinumTicket_paid: 0,
            platinumTicket_price: 0,

            diamondTicket_free: 0,
            diamondTicket_paid: 0,
            diamondTicket_price: 0,
        }
    }

    async componentDidMount() {
        this.myPermission = this.props.loginStore.permission

        const _id = GetValue(this, "this.props.match.params.id")
        if (!_id || !isId(_id)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
        }
        try {
            let response = await User.show(_id)
            if (response && response.success) {
                this.user = response.user
                this.responseMatchCount = response.responseMatchCount
                this.isNewMatch = response.isNewMatch
                this.successMatchCount = response.successMatchCount

                this.price = GetValue(response, "response.history.price", 0)

                this.resetGoods(response && response.history ? response.history : this.getHistoryDefault())
            
            } else {
                alert(response.message)
                return true
            }
            super.componentDidMount()
        } catch (e) {
            alert(e.toString())
        }
    }

    resetGoods = history => {
        this.showItem = Goods.create(history.showItem_free, history.showItem_paid, history.showItem_price)
        this.showItem_before = Goods.createFromObj(this.showItem)

        this.connectItem = Goods.create(history.connectItem_free, history.connectItem_paid, history.connectItem_price)
        this.connectItem_before = Goods.createFromObj(this.connectItem)

        this.standardTicket = Goods.create(history.standardTicket_free, history.standardTicket_paid, history.standardTicket_price)
        this.standardTicket_before = Goods.createFromObj(this.standardTicket)

        this.goldTicket = Goods.create(history.goldTicket_free, history.goldTicket_paid, history.goldTicket_price)
        this.goldTicket_before = Goods.createFromObj(this.goldTicket)

        this.platinumTicket = Goods.create(history.platinumTicket_free, history.platinumTicket_paid, history.platinumTicket_price)
        this.platinumTicket_before = Goods.createFromObj(this.platinumTicket)

        this.diamondTicket = Goods.create(history.diamondTicket_free, history.diamondTicket_paid, history.diamondTicket_price)
        this.diamondTicket_before = Goods.createFromObj(this.diamondTicket)
    }

    @action
    _save = async (e) => {
        e.preventDefault()

        const showItem_delta = Goods.subtract(this.showItem, this.showItem_before)
        const connectItem_delta = Goods.subtract(this.connectItem, this.connectItem_before)
        const standardTicket_delta = Goods.subtract(this.standardTicket, this.standardTicket_before)
        const goldTicket_delta = Goods.subtract(this.goldTicket, this.goldTicket_before)
        const platinumTicket_delta = Goods.subtract(this.platinumTicket, this.platinumTicket_before)
        const diamondTicket_delta = Goods.subtract(this.diamondTicket, this.diamondTicket_before)

        const params = {
            _user : this.user._id,
            type : "admin",
            price : 0,

            showItem_free: Goods.free(showItem_delta),
            showItem_paid: Goods.paid(showItem_delta),
            showItem_price: Goods.price(showItem_delta),

            connectItem_free: Goods.free(connectItem_delta),
            connectItem_paid: Goods.paid(connectItem_delta),
            connectItem_price: Goods.price(connectItem_delta),

            standardTicket_free: Goods.free(standardTicket_delta),
            standardTicket_paid: Goods.paid(standardTicket_delta),
            standardTicket_price: Goods.price(standardTicket_delta),

            goldTicket_free: Goods.free(goldTicket_delta),
            goldTicket_paid: Goods.paid(goldTicket_delta),
            goldTicket_price: Goods.price(goldTicket_delta),

            platinumTicket_free: Goods.free(platinumTicket_delta),
            platinumTicket_paid: Goods.paid(platinumTicket_delta),
            platinumTicket_price: Goods.price(platinumTicket_delta),

            diamondTicket_free: Goods.free(diamondTicket_delta),
            diamondTicket_paid: Goods.paid(diamondTicket_delta),
            diamondTicket_price: Goods.price(diamondTicket_delta),
        }
        try {
            const response = await History.save(params)
            if (response && response.success) {
                alert("수정이 완료되었습니다")
                this.resetGoods({
                    showItem_free: Goods.free(this.showItem),
                    showItem_paid: Goods.paid(this.showItem),
                    showItem_price: Goods.price(this.showItem),

                    connectItem_free: Goods.free(this.connectItem),
                    connectItem_paid: Goods.paid(this.connectItem),
                    connectItem_price: Goods.price(this.connectItem),

                    standardTicket_free: Goods.free(this.standardTicket),
                    standardTicket_paid: Goods.paid(this.standardTicket),
                    standardTicket_price: Goods.price(this.standardTicket),

                    goldTicket_free: Goods.free(this.goldTicket),
                    goldTicket_paid: Goods.paid(this.goldTicket),
                    goldTicket_price: Goods.price(this.goldTicket),

                    platinumTicket_free: Goods.free(this.platinumTicket),
                    platinumTicket_paid: Goods.paid(this.platinumTicket),
                    platinumTicket_price: Goods.price(this.platinumTicket),

                    diamondTicket_free: Goods.free(this.diamondTicket),
                    diamondTicket_paid: Goods.paid(this.diamondTicket),
                    diamondTicket_price: Goods.price(this.diamondTicket),
                })
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    renderGoods = (label, goods) => {
        return (<tr>
            <td width="20%">{label}</td>
            <td width="20%">
                <input
                    type="number"
                    className="form-control"
                    value={Goods.free(goods)}
                    onChange={e => Goods.setFree(goods, e.target.value)}
                />
            </td>
            <td width="20%">
                <input
                    type="number"
                    className="form-control"
                    value={Goods.paid(goods)}
                    onChange={e => Goods.setPaid(goods, e.target.value)}
                />
            </td>
            <td width="40%">
                <div className="input-group">
                    <input
                        type="number"
                        className="form-control"
                        value={Goods.price(goods)}
                        onChange={e => Goods.setPrice(goods, e.target.value)}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">원</span>
                    </div>
                </div>
            </td>
        </tr>)
    }

    _render = () => (
        <UserShowLayoutMulti user={this.user} path={this.path} isNewMatch={this.isNewMatch} responseMatchCount={this.responseMatchCount} permission={this.myPermission}>
            <div className="col-md-9">
                <div className="card mb-4">
                    <div className="card-header">이용 서비스</div>
                    <div className="card-body">
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">성사횟수</label>
                            <div className="col-sm-10">
                                <input type="text" disabled className="form-control-plaintext" value={this.successMatchCount} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">누적구매금액</label>
                            <div className="col-sm-10">
                                <input type="text" disabled className="form-control-plaintext" value={`${addComma(this.price)}원`} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />남은서비스</div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table text-nowrap">
                                <thead>
                                    <tr>
                                        <th scope="col">항목</th>
                                        <th scope="col">무료</th>
                                        <th scope="col">유료</th>
                                        <th scope="col">유료구매합</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderGoods("더보여줘", this.showItem)}
                                    {this.renderGoods("이어줘", this.connectItem)}
                                    {this.renderGoods("스탠다드", this.standardTicket)}
                                    {this.renderGoods("골드", this.goldTicket)}
                                    {this.renderGoods("플래티넘", this.platinumTicket)}
                                    {this.renderGoods("다이아", this.diamondTicket)}
                                </tbody>
                            </table>
                            {
                                this.myPermission === 'super' &&
                                <div>
                                    <button type="submit" className="btn btn-secondary" style={{ marginRight: 5 }} onClick={this._save}>수정하기</button>
                                    <button type="button" className="btn btn-light" onClick={this._refresh}>취소</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </UserShowLayoutMulti>
    )
}

export default UserServiceScreen