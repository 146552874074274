import Api from "./Api"
const url = "recommend"
const Recommend = {
    get : async (params = {}) => {
        return await Api.get(`${url}`, params)
    },
    save : async (params = {}) => {
        return await Api.post(`${url}`, params)
    },
    saveMany : async (params = {}) => {
        return await Api.post(`${url}/stores`, params)
    }
}

export default Recommend