import GetValue from "./GetValue"

export const setUser = (user) => {
    return {
        _main: GetValue(user, "user._photos._main", null),
        _privateR: GetValue(user, "user._photos._privateR", []),
        _hobbyLife: GetValue(user, "user._photos._hobbyLife", []),
        _area: GetValue(user, "user._area._id"),
        _manager: GetValue(user, "user._manager._id"),
        name: GetValue(user, "user.name"),
        grade: GetValue(user, "user.grade"),
        status: GetValue(user, "user.status"),
        email: GetValue(user, "user.email"),
        gender: GetValue(user, "user.gender"),
        phone: GetValue(user, "user.phone"),
        birth: GetValue(user, "user.birth"),
        religion: GetValue(user, "user.religion"),
        married: GetValue(user, "user.married"),
        smoking: GetValue(user, "user.smoking"),
        drinking: GetValue(user, "user.drinking"),
        height: GetValue(user, "user.height.value"),
        heightVisible: GetValue(user, "user.height.visible", false),
        body: GetValue(user, "user.body.value"),
        bodyVisible:  GetValue(user, "user.body.visible", false),
        personality: GetValue(user, "user.personality.value"),
        personalityVisible:  GetValue(user, "user.personality.visible", false),
        education: GetValue(user, "user.education.value"),
        educationVisible: GetValue(user, "user.education.visible", false),
        job: GetValue(user, "user.job.value"),
        jobVisible: GetValue(user, "user.job.visible", false),
        insurance: GetValue(user, "user.insurance.value"),
        insuranceVisible: GetValue(user, "user.insurance.visible", false),
        income: GetValue(user, "user.income.value"),
        incomeVisible: GetValue(user, "user.income.visible", false),
        car: GetValue(user, "user.car.value", false),
        carVisible: GetValue(user, "user.car.visible", false),
        estate: GetValue(user, "user.estate.value"),
        estateVisible: GetValue(user, "user.estate.visible", false),
        values:  GetValue(user, "user.values"),
        advantage: GetValue(user, "user.advantage"),
        want: GetValue(user, "user.want"),
        idealType: GetValue(user, "user.idealType"),
        talkTo: GetValue(user, "user.talkTo"),
        familyLevel: GetValue(user, "user.familyLevel"),
        abilityLevel: GetValue(user, "user.abilityLevel"),
        personalityLevel: GetValue(user, "user.personalityLevel"),
        tattoo: GetValue(user, "user.tattoo"),
    }
}