import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faBox, faChartBar, faTable, faUser, faUsers } from "@fortawesome/free-solid-svg-icons"
import Admin from "../api/Admin"
import { Dropdown } from 'react-bootstrap';

const menuToggle = () => {
    var element = document.body
    element.classList.toggle("sb-sidenav-toggled");
}

export default (props) => {
    const { children, title, path } = props
    const [permission , setPermission] = useState("")
    const [loaded , setLoaded] = useState(false)
    const [user , setUser] = useState(null)
    const [route , setRoute] = useState("")
    useEffect(() => {
        if (!permission){
            setPermission(localStorage.getItem("permission"))
        }
        if (path) setRoute(path)

        if (!user) {
            Admin.my().then(response => {
                if (response && response.success) {
                    setUser(response.admin)
                }
            })
        }

        if (user && permission) setLoaded(true)
    }, [permission, path, loaded, user])

    return loaded && (
        <div className="sb-nav-fixed">
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
            <a className="navbar-brand" href="/">연애해 관리자</a>
            <button className="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle"  onClick={menuToggle}>
                <FontAwesomeIcon className="fas" icon={faBars} />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="page-title mr-auto">
                    <h6 className="text-light mb-0">{title}</h6>
                </div>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <FontAwesomeIcon className="fas fa-fw" icon={faUser} />{user.name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="/admin/my">계정정보</Dropdown.Item>
                            <div className="dropdown-divider"></div>
                            <Dropdown.Item href="/logout">로그아웃</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
            </div>
        </nav>
        <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            {(permission === "super" || permission === "admin") &&
                            <>
                                <div className="sb-sidenav-menu-heading">관리자 메뉴</div>
                                {
                                    (permission === "super" || permission === "admin") &&
                                    <a className={`nav-link ${route === "/dashboard" && "active"}`} href={"/dashboard"}>
                                        <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faTable} /></div>대시보드
                                    </a>
                                }
                                <a className={`nav-link ${route === "/user" && "active"}`} href={"/user"}>
                                    <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faUsers} /></div>회원
                                </a>

                                {
                                    permission === "super" &&
                                    <a className={`nav-link ${route === "/admin" && "active"}`} href={"/admin"}>
                                        <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faUser} /></div>관리자
                                    </a>
                                }
                                
                                <a className={`nav-link ${route === "/admin/manager" && "active"}`} href={"/admin/manager"}>
                                    <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faUser} /></div>매니저
                                </a>
                                {
                                    permission === "super" &&
                                    <>
                                        <a className={`nav-link ${route === "/admin/arranger" && "active"}`} href={"/admin/arranger"}>
                                            <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faUser} /></div>주선자(CMO)
                                        </a>
                                        <a className={`nav-link ${route === "/product" && "active"}`} href={"/product"}>
                                            <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faBox} /></div>상품
                                        </a>
                                    </>
                                }
                                </>
                            }

                            {permission === "manager" &&
                            <>
                                <div className="sb-sidenav-menu-heading">매니저 메뉴</div>
                                {/* <a className={`nav-link ${route === "/manager" && "active"}`} href={"/manager"}>
                                    <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faUsers} /></div>회원
                                </a> */}
                                <a className={`nav-link ${route === "/manager" && "active"}`} href={"/manager"}>
                                    <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faUsers} /></div>회원
                                </a>
                                <a className={`nav-link ${route === "/manager/sales" && "active"}`} href={"/manager/sales"}>
                                    <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faChartBar} /></div>실적
                                </a>
                            </>
                            }
                            {permission === "arranger" &&
                            <>
                                <div className="sb-sidenav-menu-heading">주선자(CMO) 메뉴</div>
                                <a className={`nav-link ${route === "/arranger" && "active"}`} href={"/arranger"}>
                                    <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faUsers} /></div>주선
                                </a>
                                <a className={`nav-link ${route === "/arranger/sales" && "active"}`} href={"/arranger/sales"}>
                                    <div className="sb-nav-link-icon"><FontAwesomeIcon className="fas" icon={faChartBar} /></div>실적
                                </a>
                            </>
                            }
                        </div>
                    </div>
                </nav>
            </div>
            <div id="layoutSidenav_content">
                <main>
                    {children}
                </main>
                <footer className="py-4 bg-light mt-auto">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between small">
                            <div className="text-muted">Copyright &copy; Your Website 2020</div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
    )
}

