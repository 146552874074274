import React from "react"
import { inject } from 'mobx-react'
import { action, observable } from "mobx"
import Admin from "../../api/Admin"
import _Component from "../../common/_Component"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class LoginScreen extends _Component {
    @observable count = 0
    @observable email = ""
    @observable password = ""

    @action
    _login = async (e) => {
        e.preventDefault()
        try {
            const response = await Admin.login({ email: this.email, password: this.password })
            if (response && response.success) {
                this.props.loginStore.setLogin(response.token, response.permission)
                alert("로그인 완료")
                const permission = this.props.loginStore.permission
                if (permission === "super") this.props.history.push("/dashboard")
                if (permission === "admin") this.props.history.push("/user")
                if (permission === "manager") this.props.history.push("/manager")
                if (permission === "arranger") this.props.history.push("/arranger")
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _render = () => {
        return (
            <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                        <div className="card-header"><h3
                            className="text-center font-weight-light my-4">로그인</h3></div>
                        <div className="card-body">
                            {/* test */}
                            <form>
                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="inputEmailAddress">이메일</label>
                                    <input
                                        className="form-control py-4"
                                        id="inputEmailAddress"
                                        type="email"
                                        onChange={(value) => this.email = value.target.value}
                                        value={this.email}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="inputPassword">비밀번호</label>
                                    <input
                                        className="form-control py-4"
                                        id="inputPassword"
                                        type="password"
                                        onChange={value => this.password = value.target.value} />
                                </div>
                                <div
                                    className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                    <a className="small" href="/password">비밀번호를 잊으셨나요?</a>
                                    <button className="btn btn-primary" onClick={this._login}>로그인</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default LoginScreen