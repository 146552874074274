import _Component from "../../common/_Component"
import React from "react"
import UserShowLayout from "../../layouts/UserShowLayout"
import GetValue from "../../utils/GetValue"
import { isId } from "../../utils/Utils"
import User from "../../api/User"
import { action, observable } from "mobx"
import { setTrophy } from "../../utils/TrophyUtil"
import Trophy from "../../api/Trophy"
import { inject } from "mobx-react"
@inject((stores) => ({
    loginStore: stores.LoginStore
}))
class UserTrophyScreen extends _Component {
    @observable user = null
    @observable trophy = null
    @observable isTrophy = false
    @observable myPermission = ""

    async componentDidMount() {
        this.myPermission = this.props.loginStore.permission

        const _id = GetValue(this, "this.props.match.params.id")
        if (!_id || !isId(_id)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
        }
        try {
            let response = await User.show(_id)
            if (response && response.success) {
                this.user = response.user
                this.responseMatchCount = response.responseMatchCount
                this.isNewMatch = response.isNewMatch
                this.trophy = setTrophy(response.trophy)
                if (response.trophy) {
                    this.isTrophy = true
                }
            } else {
                alert(response.message)
                return true
            }
            super.componentDidMount()
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _save = async (e) => {
        e.preventDefault()
        this.trophy._user = this.user._id
        try {
            const response = await Trophy.save(this.trophy)
            if (response && response.success) {
                alert(`트로피가 ${this.isTrophy ? "수정" : "저장"}되었습니다`)
                this.isTrophy = true
                //this.props.history.push("/user")
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _render = () => (
        <UserShowLayout user={this.user} title={"트로피"} path={this.path} isNewMatch={this.isNewMatch} responseMatchCount={this.responseMatchCount} permission={this.myPermission}>
            {
                !this.isTrophy &&
                <div className="alert alert-danger">
                    <strong>알림!</strong> 아직 트로피가 등록되지 않은 회원입니다. 데이터 저장시 트로피가 생성됩니다.
                </div>
            }
            <form>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">반려동물</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.pet} onChange={e => this.trophy.pet = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="반려견">반려견</option>
                            <option value="반려묘">반려묘</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.petPost} onChange={e => this.trophy.petPost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">미혼재혼</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.family} onChange={e => this.trophy.family = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="미혼">미혼</option>
                            <option value="돌싱">돌싱</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.familyPost} onChange={e => this.trophy.familyPost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">수상장학</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.award} onChange={e => this.trophy.award = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.awardPost} onChange={e => this.trophy.awardPost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">봉사기부</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.donation} onChange={e => this.trophy.donation = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.donationPost} onChange={e => this.trophy.donationPost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">차량</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.car} onChange={e => this.trophy.car = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.carPost} onChange={e => this.trophy.carPost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">사업/자격면허</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.license} onChange={e => this.trophy.license = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.licensePost} onChange={e => this.trophy.licensePost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">전년도매출</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.sales} onChange={e => this.trophy.sales = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.salesPost} onChange={e => this.trophy.salesPost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">졸업학위</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.graduation} onChange={e => this.trophy.graduation = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.graduationPost} onChange={e => this.trophy.graduationPost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">재직/재학</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.work} onChange={e => this.trophy.work = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.workPost} onChange={e => this.trophy.workPost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">세금납부</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.tax} onChange={e => this.trophy.tax = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.taxPost} onChange={e => this.trophy.taxPost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">소득증명</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.income} onChange={e => this.trophy.income = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.incomePost} onChange={e => this.trophy.incomePost = e.target.checked} /> 공개
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">부동산</label>
                    <div className="col-sm-8">
                        <select className="form-control" value={this.trophy.estate} onChange={e => this.trophy.estate = e.target.value}>
                            <option value="없음">없음</option>
                            <option value="금">금</option>
                            <option value="은">은</option>
                            <option value="동">동</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <input type="checkbox" checked={this.trophy.estatePost} onChange={e => this.trophy.estatePost = e.target.checked} /> 공개
                    </div>
                </div>
                {
                    this.myPermission === 'super' &&
                    <div>
                        <button className="btn btn-primary" style={{ marginRight: 5 }} onClick={this._save}>완료</button>
                        <button className="btn btn-secondary" onClick={this._refresh}>취소</button>
                    </div>
                }
                
            </form>
        </UserShowLayout>
    )
}

export default UserTrophyScreen