import React from "react"
import MenuLayouts from "../layouts/MenuLayouts"
import UserScreen from "../screens/User/UserScreen"
import { Route, Switch } from "react-router-dom"
import UserShowScreen from "../screens/User/UserShowScreen"
import UserRequestScreen from "../screens/User/UserRequestScreen"
import UserResponseScreen from "../screens/User/UserResponseScreen"
import UserProfileScreen from "../screens/User/UserProfileScreen"
import UserTrophyScreen from "../screens/User/UserTrophyScreen"
import UserInterestScreen from "../screens/User/UserInterestScreen"
import UserServiceScreen from "../screens/User/UserServiceScreen"
import UserPasswordScreen from "../screens/User/UserPasswordScreen"
import UserCreateScreen from "../screens/User/UserCreateScreen"
import UserRequestShowScreen from "../screens/User/UserRequestShowScreen"
import NotFountScreen from "../screens/Error/NotFountScreen"

export default ({ match }) => {
    return (
        <Switch>
                <MenuLayouts exact path={match.path} component={UserScreen} title={"회원 목록"} />
                <MenuLayouts path={`${match.path}/create`} component={UserCreateScreen} title={"회원 등록"} />
                <Switch>
                    <MenuLayouts exact path={`${match.path}/:id`} component={UserShowScreen} title={"회원정보"} />
                    <MenuLayouts path={`${match.path}/:id/profile`} component={UserProfileScreen} title={"회원정보"} />
                    <MenuLayouts path={`${match.path}/:id/trophy`} component={UserTrophyScreen} title={"회원정보"} />
                    <MenuLayouts path={`${match.path}/:id/interest`} component={UserInterestScreen} title={"회원정보"} />
                    <MenuLayouts path={`${match.path}/:id/service`} component={UserServiceScreen} title={"회원정보"} />
                    <MenuLayouts path={`${match.path}/:id/password`} component={UserPasswordScreen} title={"회원정보"} />
                    <MenuLayouts path={`${match.path}/:id/response`} component={UserResponseScreen} title={"회원정보"} />
                    <Switch>
                        <MenuLayouts exact path={`${match.path}/:id/request`} component={UserRequestScreen} title={"회원정보"} />
                        <MenuLayouts path={`${match.path}/:id/request/:matchId`} component={UserRequestShowScreen} title={"회원정보"} />
                        <Route component={NotFountScreen} />
                    </Switch>
                    <Route component={NotFountScreen} />
                </Switch>
        </Switch>
    )
}