import React from "react"
import MenuLayouts from "../layouts/MenuLayouts"
import AdminScreen from "../screens/Admin/AdminScreen"
import AdminCreateScreen from "../screens/Admin/AdminCreateScreen"
import AdminShowScreen from "../screens/Admin/AdminShowScreen"
import AdminShowPasswordScreen from "../screens/Admin/AdminShowPasswordScreen"
import AdminManagerScreen from "../screens/Admin/AdminManagerScreen"
import AdminShowManageUserScreen from "../screens/Admin/AdminShowManageUserScreen"
import AdminShowManageSalesScreen from "../screens/Admin/AdminShowManageSalesScreen"
import AdminArrangerScreen from "../screens/Admin/AdminArrangerScreen"
import { Route, Switch } from "react-router-dom"
import AdminShowArrangeUserScreen from "../screens/Admin/AdminShowArrangeUserScreen"
import AdminShowArrangeSalesScreen from "../screens/Admin/AdminShowArrangeSalesScreen"
import NotFountScreen from "../screens/Error/NotFountScreen"
import AdminMyScreen from "../screens/Admin/AdminMyScreen"

export default ({ match }) => {
    return (
        <Switch>
            <MenuLayouts exact path={match.path} component={AdminScreen} title={"관리자 목록"} />
            <MenuLayouts path={`${match.path}/create`} component={AdminCreateScreen} title={"관리자 등록"} />
            <MenuLayouts path={`${match.path}/manager`} component={AdminManagerScreen} title={"매니저 목록"} />
            <MenuLayouts path={`${match.path}/arranger`} component={AdminArrangerScreen} title={"주선자 목록"} />
            <MenuLayouts path={`${match.path}/my`} component={AdminMyScreen} title={"계정정보"} />
            <Switch>
                <MenuLayouts exact path={`${match.path}/:id`} component={AdminShowScreen} title={"계정 정보"} />
                <MenuLayouts path={`${match.path}/:id/manage-user`} component={AdminShowManageUserScreen} title={"담당회원 정보"} />
                <MenuLayouts path={`${match.path}/:id/manage-sales`} component={AdminShowManageSalesScreen} title={"매니저실적"} />
                <MenuLayouts path={`${match.path}/:id/arrange-user`} component={AdminShowArrangeUserScreen} title={"주선회원 정보"} />
                <MenuLayouts path={`${match.path}/:id/arrange-sales`} component={AdminShowArrangeSalesScreen} title={"주선자실적"} />
                <MenuLayouts path={`${match.path}/:id/password`} component={AdminShowPasswordScreen} title={"비밀번호 수정"} />
                <Route component={NotFountScreen} />
            </Switch>
            <Route component={NotFountScreen} />
        </Switch>
    )
}