import _Component from "../../common/_Component"
import React from "react"
import { observable } from "mobx"
import { inject } from "mobx-react"
import Admin from "../../api/Admin"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class AdminMyScreen extends _Component {
    @observable admin = null
    @observable password = ""
    @observable passwordConfirm = ""

    async componentDidMount() {
        const response = await Admin.my()
        if (response && response.success) {
            this.admin = response.admin
        }
        if (this.admin) this.loaded = true
    }

    _update = async (e) => {
        e.preventDefault()
        try {
            const response = await Admin.update(this.admin._id, {
                name: this.admin.name,
                name2nd: this.admin.name2nd,
                email: this.admin.email,
                phone: this.admin.phone,
                password : this.password,
                passwordConfirm : this.passwordConfirm
            })
            if (response && response.success) {
                alert("데이터 수정이 완료되었습니다.")
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _render = () => (
        <div className="card mb-4">
            <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />계정 정보</div>
            <div className="card-body">
            <form>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">이름</label>
                        <div className="col-sm-10">
                            {
                                this.admin.permission === 'admin' ?
                                <input type="text" readOnly className="form-control-plaintext" value={this.admin.name} /> :
                                <input type="text" className="form-control" value={this.admin.name} onChange={e => this.admin.name = e.target.value} />
                            }
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">Email</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" value={this.admin.email} onChange={e => this.admin.email = e.target.value} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">비밀번호</label>
                    <div className="col-sm-10">
                        <input type="password" className="form-control" placeholder="Password" value={this.password} onChange={e => this.password = e.target.value} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">비밀번호 확인</label>
                    <div className="col-sm-10">
                        <input type="password" className="form-control" placeholder="Password" value={this.passwordConfirm} onChange={e => this.passwordConfirm = e.target.value}  />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">휴대전화</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" value={this.admin.phone} onChange={e => this.admin.phone = e.target.value} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">매니저 어필</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" value={this.admin.description} onChange={e => this.admin.description = e.target.value} />
                    </div>
                </div>                             
                <button type="submit" className="btn btn-secondary" onClick={this._update} style={{ marginRight: 5 }}>수정하기</button>
            </form>
            </div>
        </div>
    )
}

export default AdminMyScreen