import Api from "./Api"
import Upload from '../helper/upload.helper'

const url = "photo"
const Photo = {
    upload : async filenames => {
        const result = await Upload.execute(filenames, params => Api.post('photo', params))
        if (result.error)
            return Api.error(result.error, result.status)

        return Api.success({ uploads: result.uploads })
    },
}

export default Photo