import { action, observable } from "mobx"
import Admin from "../api/Admin"

class LoginStore {
    constructor(root) {
        this.root = root
        this.checkLogin()
    }

    @observable isLogin = false
    @observable token = ""
    @observable permission = ""

    @action setLogin(token, permission) {
        localStorage.setItem("token", token)
        localStorage.setItem("permission", permission)
        this.isLogin = true
        this.token = token
        this.permission = permission
    }

    @action checkLogin() {
        const token = localStorage.getItem("token")
        const permission = localStorage.getItem("permission")
        if (token) {
            this.isLogin = true
            this.token = token
            this.permission = permission
        }
    }

    @action logout() {
        localStorage.clear()
        this.isLogin = false
        this.token = ""
        this.permission = ""
    }

    @action async getUser(type="user") {
        let admin = null
        let response = null
        try {
            if (type === "admin") {
                response = await Admin.my()
            }

            if (response && response.success) {
                admin = response.admin
            }
            return admin

        } catch (e) {
            console.log(e)
            return null
        }

    }
}

export default LoginStore