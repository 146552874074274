import axios from "axios"
import querystring from "querystring"
import Config from "../config/config"

const instance = axios.create({
    validateStatus: function (status) {
        return status <= 500 || status === 503
    }
})

const apiRequestBaseUrl = Config.apiRequestBaseUrl

const apiSuccess = (data, message = '', status = 200) => {
    return { ...data, status: status, message: message, success: true }
}

const apiError = (message, status = 500) => {
    Config.__DEV__ && console.log(message)
    return { status: status, message: message, success: false }
}

const executeApi = async (type, url, data = {}, headers = {}) => {
    let response = null
    let finalUrl = `${apiRequestBaseUrl}/${url}`

    let status = 0
    let message = ""

    if ((type === "get" || type === "delete") && Object.keys(data).length > 0) {
        finalUrl = `${finalUrl}?${querystring.stringify(data)}`
    }
    try {
        Config.__DEV__ && console.log(`API URL : ${finalUrl}`)
        /*******************토큰값 추가를 위한 코드**********************/
        let token = localStorage.getItem("token")
        /*************************************************************/
        let config = { headers: {} }
        if (headers) {
            config.headers = headers
        }
        if (token) config.headers.Authorization = `Bearer ${token}`
        switch (type) {
            case "get": {
                response = await instance.get(finalUrl, config)
                break
            }
            case "post": {
                response = await instance.post(finalUrl, data, config)
                break
            }
            case "put": {
                response = await instance.put(finalUrl, data, config)
                break
            }
            case "patch": {
                response = await instance.patch(finalUrl, data, config)
                break
            }
            case "delete": {
                response = await instance.delete(finalUrl, config)
                break
            }
            default: {
                response = null
            }
        }

        if (response) {
            if (response.status) status = response.status
            if (response.data) message = response.data.message
        }

    } catch (e) {
        let errorMsg = e.toString();
        console.log(errorMsg)
        alert(errorMsg)
        if (errorMsg.toLowerCase().includes("network"))
            status = 599
    } finally {
        if (!status) {
            message = "알 수 없는 문제가 발생하였습니다"
        } else if (503 === status) {
            message = "연애해 점검중입니다.\n나중에 다시 시도해주세요.\n기타 장애 발생시 관리센터에 문의 바랍니다"
        } else if (501 <= status) {
            if (message) {
                let serverMsg = message + " (" + status + ")"
                alert(serverMsg)
            }
            message = "네트워크 에러가 발생하였습니다.\n지속적으로 에러 발생시 관리센터에 문의 바랍니다\n(" + status + ")"
        } else if (401 === status || 403 === status) {
            // localStorage.setItem("token", null)
        }

        if (status >= 200 && status < 300)
            return apiSuccess(await response.data.data, message, status)
        else
            return apiError(message, status)
    }
}

const createCaller = () => {
    let wait = false
    return async (func) => {
        if (wait)
            return apiError("이미 처리중입니다. 잠시 후 다시 시도해주세요", 400)

        try {
            wait = true
            return await func()
        } finally {
            wait = false
        }
    }
}

const Api = {
    get: async (url, data = {}, headers = {}) => await executeApi("get", url, data, headers),
    post: async (url, data = {}, headers = {}) => await executeApi("post", url, data, headers),
    put: async (url, data = {}, headers = {}) => await executeApi("put", url, data, headers),
    patch: async (url, data = {}, headers = {}) => await executeApi("patch", url, data, headers),
    delete: async (url, data = {}, headers = {}) => await executeApi("delete", url, data, headers),
    createCaller,
    success: apiSuccess,
    error: apiError,
}

export default Api