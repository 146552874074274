import React, { Component } from "react"
import { action, observable } from "mobx"
import { observer } from "mobx-react"
import CommonApi from "../api/CommonApi"
import Config from "../config/config"
import Admin from "../api/Admin"
import GetValue from "../utils/GetValue"
import { isId } from "../utils/Utils"
import Photo from "../api/Photo"
import * as axios from "axios"

@observer
class _Component extends Component {
    @observable loaded = false
    @observable limit = Config.defaultLimit
    @observable url = ""
    @observable path = ""
    @observable responseMatchCount = 0
    @observable isNewMatch = 0

    componentDidMount() {
        this.loaded = true
        this.url = GetValue(this, "this.props.match.url")
        this.path = GetValue(this, "this.props.match.path")
    }

    _render = () => <></>

    _refresh = (e = null) => {
        if (e) e.preventDefault()
        window.location.reload(false)
    }

    @action
    _changeLimit = (e, _getData, type = "") => {
        e.preventDefault()
        this.limit = e.target.value
        if (type) {
            _getData(type, 1)
        } else {
            _getData(1)
        }
    }

    @action
    _check = (e, list = [], index) => {
        list[index].checked = e.target.checked
        return list
    }

    @action
    _checkAll = (e, list) => {
        return list.map((value) => value.checked = e.target.checked)
    }

    @action
    _getAdmin = async () => {
        const _id = GetValue(this, "this.props.match.params.id")
        if (!_id || !isId(_id)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
            return true
        }
        try {
            const response = await Admin.show(_id)
            if (response && response.success) {
                return response.admin
            } else {
                alert(response.message)
                return null
            }
        } catch (e) {
            alert(e.toString())
            return null
        }
    }

    @action
    _delete = async (e, url, _getData, page, list = null, type = "") => {
        e.preventDefault()
        let ids = []
        if (list) {
            ids = list.filter(value => value.checked && value._id)
                .map(value => value._id)
        }
        const confirm = window.confirm("삭제하시겠습니까?")
        if (confirm) {
            try {
                const response = await CommonApi._delete(url, { _id: ids })
                if (response && response.success) {
                    alert("데이터 삭제가 완료되었습니다.")
                    if (type) _getData(type, page)
                    else _getData(page)
                } else {
                    alert(response.message)
                }
            } catch (e) {
                alert(e.toString())
            }
        }
    }

    setPhotos = (type = "_mainPhoto", data, index = null) => {
        if (type === "_mainPhoto") {
            this._mainPhoto = data
        } else if (type === "_photo") {
            this._photo = data
        } else {
            if (!this[type]) this[type] = []
            if (index === null) {
                this[type] = [...this[type], data]
            } else {
                this[type][index] = data
            }
        }
    }

    removePhotos = (type = "_mainPhoto", index = null) => {
        if (type === "_mainPhoto") {
            this._mainPhoto = ""
        } else {
            this[type].splice(index, 1)
        }
    }

    @action
    upload = async (e, type, index = null) => {
        e.preventDefault()
        try {
            const files = Array.from(e.target.files)
            const file = files && 0 < files.length ? files.find(file => file) : null
            if (!file)
                return
            
            const response = await Photo.upload([file])
            if (response && response.success && 0 < response.uploads.length) {
                this.setPhotos(type, response.uploads[0].filename, index)
            } else {
                alert(response.message)
            }
        } catch (e) {
            e.toString()
        }
    }

    @action
    excel = async (filename, url, params) => {
        const token = localStorage.getItem("token")
        const response = await axios({
            method: 'GET',
            url: `${Config.apiRequestBaseUrl}/${url}`,
            params: params,
            responseType: 'arraybuffer',
            headers: {
                'Content-Disposition': `attachment; filename=${filename}`,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': `Bearer ${token}`
            }
        })
        const responseUrl = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = responseUrl
        link.setAttribute('download', `${filename}`)
        document.body.appendChild(link)
        link.click()
    }

    render() {
        return this.loaded && this._render()
    }

}

export default _Component