import _Component from "../../common/_Component"
import { IconButton } from '@material-ui/core'
import { Delete, Visibility } from "@material-ui/icons"
import React from "react"
import { action, observable } from "mobx"
import { addDash, getAge, getHeight, getMoment, isLineNumber } from "../../utils/Utils"
import Pagination from "../../components/Pagination"
import AdminShowLayout from "../../layouts/AdminShowLayout"
import GetValue from "../../utils/GetValue"
import User from "../../api/User"
import { getProfileUrl } from "../../utils/ImageUtil"
import moment from "moment"
import { inject } from "mobx-react"
import AvailableSuccess from "../../utils/AvailableSuccess"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class AdminShowManageUserScreen extends _Component {
    @observable users = []
    @observable search = ''
    @observable pagination = {}
    @observable name = ""
    @observable status = ""
    @observable successible = ""
    @observable manager = ""
    @observable familyLevel = ""
    @observable abilityLevel = ""
    @observable personalityLevel = ""
    @observable grade = ""
    @observable interest = ""
    @observable permission = ""
    @observable ageLevel = ""
    @observable myPermission = ""

    async componentDidMount() {
        try {
            this.admin = await this._getAdmin()
            this.myPermission = this.props.loginStore.permission
            if (this.admin && this.admin.permission) this.permission = this.admin.permission
            if (GetValue(this, "this.admin.permission") !== "manager") {
                alert("잘못된 접근입니다.")
                this.props.history.goBack()
                return true
            }
            await this._getData()
            super.componentDidMount()
        } catch (e) {
            console.log(e)
        }
    }

    _search = async (e) => {
        e.preventDefault()
        await this._getData(1)
    }

    @action
    _getData = async (page = 1, e) => {
        if (e) e.preventDefault()
        const lineNumber = isLineNumber(this.name)
        const params = {
            page: page,
            limit: this.limit,
            name: lineNumber ? null : this.name,
            lineNumber: lineNumber ? this.name : null,
            familyLevel: this.familyLevel,
            abilityLevel: this.abilityLevel,
            personalityLevel: this.personalityLevel,
            successible: this.successible,
            ageLevel: this.ageLevel,
            grade: this.grade,
            interest: this.interest,
            status: this.status,
            _manager: this.admin._id,
            showManagerUsers: true
        }
        try {
            const response = await User.get(params)
            if (response && response.success) {
                this.users = response.users
                this.pagination = response.pagination
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _exportExcel = async (e, all = true) => {
        e.preventDefault()
        const lineNumber = isLineNumber(this.name)
        let params = {
            page: all ? 1 : this.pagination && this.pagination.currentPage ? this.pagination.currentPage : 1,
            limit: all ? 9999999999 : this.limit,
            name: lineNumber ? null : this.name,
            lineNumber: lineNumber ? this.name : null,
            familyLevel: this.familyLevel,
            abilityLevel: this.abilityLevel,
            personalityLevel: this.personalityLevel,
            ageLevel: this.ageLevel,
            grade: this.grade,
            interest: this.interest,
            status: this.status,
            _manager: this.admin._id,
            successible: this.successible,
        }

        const filename = `${moment().format("YYYY_MM_DD_HH_mm_ss")}_users.xlsx`
        const url = `user/excel`
        try {
            await this.excel(filename, url, params)
        } catch (e) {
            alert(e.toString())
        }
    }

    _render = () => (
        <AdminShowLayout title={"담당회원"} admin={this.admin} _id={this.admin._id} path={this.path} permission={this.permission}>
            <div className="card-body">
                <div className="d-md-flex">
                    <div className="form-group mr-2">
                        <label htmlFor="search">회원검색</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="회원이름 또는 뒷번호" value={this.name} onChange={e => this.name = e.target.value} />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary" type="button" onClick={e => this._search(e)}>검색</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">상태</label>
                        {
                            this.myPermission === "super" ?
                            <select className="form-control" id="filter_status" value={this.status} onChange={e => this.status = e.target.value}>
                                <option value={""}>전체</option>
                                <option value={"wait"}>가입심사</option>
                                <option value={"rejoin"}>재가입</option>
                                <option value={"approval"}>가입완료</option>
                                <option value={"rest"}>휴면</option>
                                <option value={"withdraw"}>탈퇴</option>
                            </select> :
                            <select className="form-control" id="filter_status" value={this.status} onChange={e => this.status = e.target.value}>
                                <option value={""}>전체</option>
                                <option value={"approval"}>가입완료</option>
                                <option value={"rest"}>휴면</option>
                            </select>

                        }
                        
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">성사진행건</label>
                        <select className="form-control" id="request_filter" defaultValue={this.successible} onChange={e => this.successible = e.target.value}>
                            <option value={""}>기본값</option>
                            <option value={"all"}>모두포함</option>
                            <option value={"available"}>성사가능</option>
                            <option value={"final"}>최종성사</option>
                        </select>
                    </div>
                </div>
                <div className="d-md-flex mb-3">
                    <div className="form-group mr-2">
                        <label htmlFor="search">나이</label>
                        <select className="form-control" id="filter_status" value={this.ageLevel} onChange={e => this.ageLevel = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"A"}>20대</option>
                            <option value={"B"}>30대</option>
                            <option value={"C"}>40대이상</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">가</label>
                        <select className="form-control" id="filter_status" value={this.familyLevel} onChange={e => this.familyLevel = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"A"}>A</option>
                            <option value={"B"}>B</option>
                            <option value={"C"}>C</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">능</label>
                        <select className="form-control" id="filter_status" value={this.abilityLevel} onChange={e => this.abilityLevel = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"A"}>A</option>
                            <option value={"B"}>B</option>
                            <option value={"C"}>C</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">성</label>
                        <select className="form-control" id="filter_status" value={this.personalityLevel} onChange={e => this.personalityLevel = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"A"}>A</option>
                            <option value={"B"}>B</option>
                            <option value={"C"}>C</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">등급</label>
                        <select className="form-control" id="filter_status" value={this.grade} onChange={e => this.grade = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"gold"}>골드 이상</option>
                            <option value={"platinum"}>플래티늄 이상</option>
                            <option value={"diamond"}>다이아 이상</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">1순위 이상형</label>
                        <select className="form-control" id="filter_status" value={this.interest} onChange={e => this.interest = e.target.value}>
                            <option value={"all"}>전체</option>
                            <option value={"face"}>외모</option>
                            <option value={"income"}>경제력</option>
                            <option value={"personality"}>성격</option>
                            <option value={"values"}>가치관</option>
                            <option value={"familyBackground"}>가정환경</option>
                        </select>
                    </div>
                </div>
                <div className="d-md-flex mb-3">
                    {
                        this.myPermission === "super" &&
                        <div style={{ marginTop: 32 }}>
                            <a href="/user/create" className="btn btn-outline-success" style={{ marginRight: 5 }}>데이터 추가</a>
                            <button className="btn btn-outline-secondary" style={{ marginRight: 5 }}>선택삭제</button>
                            <button className="btn btn-success" style={{ marginRight: 5 }} onClick={(e) => this._exportExcel(e, false)}>현재페이지엑셀</button>
                            <button className="btn btn-success" onClick={(e) => this._exportExcel(e, true)}>검색결과엑셀</button>
                        </div>
                    }
                </div>
                <div className="table-responsive">
                    <table className="table text-nowrap">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <input type="checkbox" onChange={e => this._checkAll(e, this.users)} />
                                </th>
                                <th scope="col">Atc.</th>
                                {
                                    this.myPermission === 'super' &&
                                    <th scope="col">이메일</th>
                                }
                                <th scope="col"></th>
                                <th scope="col">이름</th>
                                <th scope="col">성사진행건</th>
                                <th scope="col">등급</th>
                                <th scope="col">출생년도</th>
                                <th scope="col">만나이</th>
                                <th scope="col">성별</th>
                                <th scope="col">미혼여부</th>
                                <th scope="col">키</th>
                                <th scope="col">체형</th>
                                {
                                    this.myPermission === "super" &&
                                    <th scope="col">연락처</th>
                                }
                                <th scope="col">상태</th>
                                <th scope="col">매니저</th>
                                <th scope="col">가</th>
                                <th scope="col">능</th>
                                <th scope="col">성</th>
                                <th scope="col">마지막 접속일</th>
                              </tr>
                        </thead>
                        <tbody>
                        {
                            this.users && this.users.length > 0 && this.users.map((value, index) => (
                                <tr key={value._id}>
                                <td>
                                    <input type="checkbox" checked={!!value.checked} onChange={(e) => this.users[index].checked = e.target.checked} />
                                </td>
                                <td>
                                    <IconButton
                                        className="text-info fas"
                                        aria-label="visibility"
                                        style={{ padding: 0, outline: "none" }}
                                        href={`/user/${value._id}`}
                                    >
                                      <Visibility />
                                    </IconButton>
                                    {
                                        this.myPermission === "super" &&
                                        <IconButton
                                            color={"secondary"}
                                            aria-label="delete"
                                            style={{ padding: 0, outline: "none" }}
                                        >
                                        <Delete />
                                        </IconButton>
                                    }
                                </td>
                                {
                                    this.myPermission === "super" &&
                                    <td>{value.email}</td>
                                }
                                 <td className="text-right">
                                        <img className="align-self-center mr-3" style={{
                                            width: 32,
                                            height: 32,
                                            borderRadius: 16
                                        }} src={getProfileUrl(GetValue(value, "value._photos._main"))} alt="profile" />
                                    </td>
                                <td>{value.name}</td>
                                {/*<td>
                                    {
                                        GetValue(value, "value._match[0].status") === "show" &&
                                        ShowType.getJSXElementByUser(value)
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "request" &&
                                        ShownStatus.getJSXElementByUser(value)
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "response" && GetValue(value, "value._match[0]._request._user") === value._id &&
                                        <span className="badge badge-pill badge-success">이어줘</span>
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "response" && GetValue(value, "value._match[0]._response._user") === value._id && GetValue(value, "value._match[0]._response.answer") === null &&
                                        <span className="badge badge-pill badge-success">이어줘받음</span>
                                    }                                        
                                    {
                                        GetValue(value, "value._match[0].status") === "wait" &&
                                        <span className="badge badge-pill badge-info">성사대기</span>
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "success" &&
                                        <span className="badge badge-pill badge-primary">매칭성사</span>
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "fail" &&
                                        <span className="badge badge-pill badge-secondary">매칭실패</span>
                                    }
                                </td>*/}
                                <td>
                                    {AvailableSuccess.getJSXElementByUser(value)}
                                </td>
                                <td>
                                    {value.grade === "standard" && "스탠다드"}
                                    {value.grade === "gold" && "골드"}
                                    {value.grade === "platinum" && "플래티넘"}
                                    {value.grade === "diamond" && "다이아몬드"}
                                </td>
                                <td>{value.birth}</td>
                                <td>{getAge(value.birth)}</td>
                                <td>{value.gender === "male" ? "남" : "여"}</td>
                                <td>{value.married}</td>
                                <td>{getHeight(value.height.value)}</td>
                                <td>{value.body.value}</td>
                                {
                                    this.myPermission === "super" &&
                                    <td>{addDash(value.phone)}</td>
                                }
                                <td>
                                    {value.status === "wait" && "가입심사"}
                                    {value.status === "rejoin" && "재가입"}
                                    {value.status === "approval" && "가입완료"}
                                    {value.status === "reject" && "심사거절"}
                                    {value.status === "withdraw" && "탈퇴"}
                                </td>
                                <td>{GetValue(value, "value._manager.name")}</td>
                                <td>{value.familyLevel}</td>
                                <td>{value.abilityLevel}</td>
                                <td>{value.personalityLevel}</td>
                                <td>{getMoment(value.loginAt)}</td>
                            </tr>
                            ))
                        }
                        </tbody>
                      </table>
                </div>
                    <div className="d-flex">
                    <div className="d-flex mr-auto">
                        <div>
                            <select className="form-control form-control-sm" value={this.limit} onChange={e => this._changeLimit(e, this._getData.bind(this))}>
                                    <option value={"1"}>1</option>
                                    <option value={"10"}>10</option>
                                    <option value={"20"}>20</option>
                                    <option value={"30"}>30</option>
                                    <option value={"40"}>40</option>
                                    <option value={"50"}>50</option>
                              </select>
                        </div>
                    </div>
                    <div>
                        <nav aria-label="Page navigation example">
                            <Pagination handle={this._getData.bind(this)} pagination={this.pagination} />
                        </nav>
                    </div>
                </div>
            </div>
        </AdminShowLayout>
    )
}

export default AdminShowManageUserScreen