import React from "react"
import MenuLayouts from "../layouts/MenuLayouts"
import ProductScreen from "../screens/Product/ProductScreen"
import { Route, Switch } from "react-router-dom"
import ProductCreateScreen from "../screens/Product/ProductCreateScreen"
import ProductShowScreen from "../screens/Product/ProductShowScreen"
import NotFountScreen from "../screens/Error/NotFountScreen"

export default ({ match }) => {
    return (
        <Switch>
            <MenuLayouts exact path={match.path} component={ProductScreen} title={"상품 목록"} />
            <MenuLayouts path={`${match.path}/create`} component={ProductCreateScreen} title={"상품 등록"} />
            <MenuLayouts path={`${match.path}/:id`} component={ProductShowScreen} title={"상품 수정"} />
            <Route component={NotFountScreen} />
        </Switch>
    )
}