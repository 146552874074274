import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faTable } from "@fortawesome/free-solid-svg-icons"
import { observer } from "mobx-react"

export default observer((props) => {
    const { children, title, admin, _id, path, permission } = props
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        if (title && admin && _id && path && permission) {
            setLoaded(true)
        }
    }, [title, admin, _id, path, permission])
    return loaded && (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="media">
                                <div className="media-body d-flex my-auto">
                                    <div className="mr-2">
                                        <label className="small mb-0">이름</label>
                                        <div>{admin.name}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="list-group list-group-flush">
                        {
                            permission === "manager" &&
                            <>
                                <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/admin/:id/manage-user" && "active"}`}>
                                    <a href={`/admin/${_id}/manage-user`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                        <div className="small">담당회원</div>
                                        <div className="small">
                                            <ul className="list-inline list-unstyled">
                                                <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                            </ul>
                                        </div>
                                    </a>
                                </li>
                                <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/admin/:id/manage-sales" && "active"}`}>
                                    <a href={`/admin/${_id}/manage-sales`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                        <div className="small">실적정보</div>
                                        <div className="small">
                                            <ul className="list-inline list-unstyled">
                                                <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                            </ul>
                                        </div>
                                    </a>
                                </li>
                            </>
                        }
                        {
                            permission === "arranger" &&
                            <>
                                <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/admin/:id/arrange-user" && "active"}`}>
                                    <a href={`/admin/${_id}/arrange-user`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                        <div className="small">담당회원</div>
                                        <div className="small">
                                            <ul className="list-inline list-unstyled">
                                                <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                            </ul>
                                        </div>
                                    </a>
                                </li>
                                <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/admin/:id/arrange-sales" && "active"}`}>
                                    <a href={`/admin/${_id}/arrange-sales`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                        <div className="small">실적정보</div>
                                        <div className="small">
                                            <ul className="list-inline list-unstyled">
                                                <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                            </ul>
                                        </div>
                                    </a>
                                </li>
                            </>
                        }
                            <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/admin/:id" && "active"}`}>
                                <a href={`/admin/${_id}`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                    <div className="small">계정정보</div>
                                    <div className="small">
                                        <ul className="list-inline list-unstyled">
                                            <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                            <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path==="/admin/:id/password" && "active"}`}>
                                <a href={`/admin/${_id}/password`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                    <div className="small">비밀번호 수정</div>
                                    <div className="small">
                                        <ul className="list-inline list-unstyled">
                                            <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-9">
                {
                    path === "/admin/:id/manage-sales" || path === "/admin/:id/arrange-sales" ?
                        <div className="card mb-4">
                            <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />{title}</div>
                            {children}
                        </div>
                    :
                        <div className="card mb-4">
                            <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />{title}</div>
                            <div className="card-body">
                                {children}
                            </div>
                        </div>
                }
                </div>
            </div>
        </div>
    )
})
