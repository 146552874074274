import { Modal, Card } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { getProfileUrl } from "../utils/ImageUtil"
import GetValue from "../utils/GetValue"
import Recommend from "../api/Recommend"
import Match from "../api/Match"
import Ban from "../api/Ban"
import AvailableSuccess from "../utils/AvailableSuccess"

export default (props) => {
    const { type, availableSuccess, matchingStep, _meetAt, handle, user1, user2, modal, areas, _matchId, _getRecommends, _getData, } = props
    const [show, setShow] = useState(false)
    const [rate, setRate] = useState("")
    const [meetAt, setMeetAt] = useState(_meetAt)
    const getMeetAt = () => {
        const value = meetAt ? moment(meetAt).format('YYYY-MM-DD[T]HH:mm') : null
        return value
    }
    const changeMeetAt = e => {
        const input = e.target.value
        const value = moment(input).format('YYYY-MM-DD HH:mm:ss')
        if (meetAt !== value)
            setMeetAt(value)
    }
    
    useEffect(() => {
        if (user1 && user2 && modal && areas && type && _matchId) {
            setShow(true)
        }
    }, [user2, modal, user1, areas, type, _matchId])

    const closeModal = (e) => {
        e.preventDefault()
        hide()
    }
    const hide = () => {
        setShow(false)
        handle(false)
    }

    const _availableSuccess = () => {
        return AvailableSuccess.Result.Available == availableSuccess || 
            AvailableSuccess.Result.Final == availableSuccess ||
            AvailableSuccess.Result.OldVersion == availableSuccess
    }

    const _successButtonLabel = () => {
        let label = ""
        if (matchingStep) {
            if (1 == matchingStep)
                label = "양측 이어줘필요"
            else if (2 == matchingStep)
                label = "성사대기"
            else if (3 <= matchingStep)
                label = "만료됨"
        }

        if (availableSuccess) {
            switch (availableSuccess) {
                case AvailableSuccess.Result.Available:
                    label = "매칭수락"
                    break
                case AvailableSuccess.Result.Final:
                    label = "최종성사"
                    break
                case AvailableSuccess.Result.Wait:
                    label = "대기"
                    break
                case AvailableSuccess.Result.OldVersion:
                    label = "수락(이전버전)"
                    break
            }
        }
        
        return label
    }

    const _saveMatchStatus = async (e, status) => {
        e.preventDefault()
        try {
            let params = { status: status }
            if ('wait' === type && 'success' === status) {
                if (!meetAt) {
                    alert('미팅 날짜를 입력해주세요')
                    return
                }
                params.meetAt = meetAt
            }

            const response = await Match.update(_matchId, params)
            if (response && response.success) {
                if (_getData)
                    await _getData(1)

                if (299 == response.status) {
                    alert(response.message)
                } else {
                    if (status === "success") {
                        alert("매칭이 수락되었습니다.")
                    } else {
                        alert("매칭이 거절되었습니다.")
                    }
                }
                hide()
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    const _modifyMeetAt = async e => {
        e.preventDefault()
        try {
            if (!meetAt) {
                alert('미팅 날짜를 입력해주세요')
                return
            }
            const response = await Match.updateMeetAt(_matchId, meetAt)
            if (response && response.success) {
                if (_getData)
                    await _getData(1)

                alert("미팅 날짜가 수정되었습니다.")
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    const _saveRecommend = async (e) => {
        e.preventDefault()
        try {
            // NOTE: jason_wang: 상대가 나를 차단했는지 확인
            const resBan = await Ban.get(user2._id)
            if (resBan.success && resBan.banList) {
                const name = user1.name
                const nameSliced = 2 < name.length ? name.slice(-2) : name

                const phone = user1.phone
                const phoneSliced = 4 < phone.length ? phone.slice(-4) : phone

                if (resBan.banList.find(item => nameSliced === item.name && phoneSliced === item.phone)) {
                    alert('상대회원의 차단리스트 조회결과 요청회원을 차단중입니다')
                    return
                }
            }

            const resCheck = await Match.check({
                userId: user1._id,
                resUserId: user2._id
            })

            if (resCheck.success) {
                const params = {
                    _match: _matchId,
                    _request: user1._id,
                    _response: user2._id,
                    rate: rate,
                }

                if (resCheck.match.length > 0 || resCheck.recommend.length > 0) {
                    const confirm = window.confirm("이전 매치기록이 있습니다. 그래도 진행하시겠습니까?")
                    if (confirm) {
                        const response = await Recommend.save(params)
                        if (response && response.success) {
                            await _getRecommends(1)
                            alert("추천이 완료되었습니다.")
                            hide()
                        } else {
                            alert(response.message)
                        }
                    } else {
                        alert('추천 취소')
                    }
                } else {
                    const response = await Recommend.save(params)
                    if (response && response.success) {
                        await _getRecommends(1)
                        alert("추천이 완료되었습니다.")
                        hide()
                    } else {
                        alert(response.message)
                    }
                }
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    const _renderProfile = (user) => (
        <div className="col-md-6">
            <div className="card mb-4">
                <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />{user.name} 프로필</div>
                <div className="card-body">
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">이름</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" value={user.name} disabled />
                        </div>
                    </div>
                    
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">등급</label>
                        <div className="col-sm-10">
                            <select className="form-control" disabled value={user.grade}>
                                <option value="standard">스탠다드</option>
                                <option value="gold">골드</option>
                                <option value="platinum">플래티넘</option>
                                <option value="diamond">다이아</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">출생년도</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" value={user.birth} disabled />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">성별</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user.gender}>
                            <option value="male">남</option>
                            <option value="female">여</option>
                        </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">지역</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user._area._id}>
                            {
                                areas && areas.length > 0 && areas.map((value) =>
                                    <option key={value._id} value={value._id}>{`${value.city} ${value.name}`}</option>)
                            }
                        </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">종교</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user.religion}>
                            <option value="기독교">기독교</option>
                            <option value="불교">불교</option>
                            <option value="천주교">천주교</option>
                            <option value="무교">무교</option>
                            <option value="기타">기타</option>
                        </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">결혼</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user.married}>
                            <option value="미혼">미혼</option>
                            <option value="돌싱">돌싱</option>
                        </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">흡연</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user.smoking}>
                            <option value="흡연">흡연</option>
                            <option value="비흡연">비흡연</option>
                        </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">음주</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user.drinking}>
                            <option value="즐겨함">즐겨함</option>
                            <option value="가끔">가끔</option>
                            <option value="안함">안함</option>
                        </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">타투</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user.tattoo}>
                            <option value="">데이터 없음</option>
                            <option value="안함">안함</option>
                            <option value="크고화려함">크고화려함</option>
                            <option value="손바닥정도">손바닥정도</option>
                            <option value="포인트살짝">포인트살짝</option>
                        </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">키</label>
                        <div className="col-sm-8">
                            <select className="form-control" disabled value={user.height.value}>
                                <option value="1">155미만</option>
                                <option value="2">155~159</option>
                                <option value="3">160~164</option>
                                <option value="4">165~169</option>
                                <option value="5">170~174</option>
                                <option value="6">175~179</option>
                                <option value="7">180~184</option>
                                <option value="8">185~189</option>
                                <option value="9">190~194</option>
                                <option value="10">195이상</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" disabled checked={user.height.visible} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">체형</label>
                        <div className="col-sm-8">
                            <select className="form-control" disabled value={user.body.value}>
                                <option value="마른">마른</option>
                                <option value="보통">보통</option>
                                <option value="통통">통통</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" disabled checked={user.body.visible} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">성격</label>
                        <div className="col-sm-8">
                            {/* <select className="form-control" disabled value={user.personality.value}>
                                <option value="외향적인">외향적인</option>
                                <option value="내향적인">내향적인</option>
                            </select> */}
                            <textarea disabled className="form-control mr-1" name="personality" defaultValue={user.personality.value} cols="5" rows="1" />
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" disabled checked={user.personality.visible} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">최종학력</label>
                        <div className="col-sm-8">
                            <select className="form-control" disabled value={user.education.value}>
                                <option value="고등학교졸">고등학교졸</option>
                                <option value="2년제졸">전문대학교졸</option>
                                <option value="4년제졸">대학교졸</option>
                                <option value="대학원졸">대학원졸</option>
                                <option value="대학(원)재학">대학(원)재학</option>
                                <option value="해외대학졸">해외대학(원)졸</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" disabled checked={user.education.visible} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">직업</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" disabled value={user.job.value} />
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" disabled checked={user.job.visible} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">4대보험</label>
                        <div className="col-sm-8">
                            <select className="form-control" disabled value={user.insurance.value}>
                                <option value="직장가입자">직장가입자</option>
                                <option value="지역가입자">지역가입자</option>
                                <option value="미가입자">미가입자</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" disabled checked={user.insurance.visible} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">작년소득</label>
                        <div className="col-sm-8">
                            <select className="form-control" disabled value={user.income.value}>
                                <option value="1">0~2000</option>
                                <option value="2">2000~4000</option>
                                <option value="3">4000~6000</option>
                                <option value="4">6000~8000</option>
                                <option value="5">8000~1억</option>
                                <option value="6">1억 이상</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" disabled checked={user.income.visible} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">차량소유</label>
                        <div className="col-sm-8">
                            <select className="form-control" disabled value={user.car.value}>
                                <option value={true}>있음</option>
                                <option value={false}>없음</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" disabled checked={user.car.visible} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">종합부동산</label>
                        <div className="col-sm-8">
                            <select className="form-control" disabled value={user.estate.value}>
                                <option value="1">0~1억</option>
                                <option value="2">1억~3억</option>
                                <option value="3">3억~6억</option>
                                <option value="4">6억~10억</option>
                                <option value="5">10~20억</option>
                                <option value="6">20억 이상</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" disabled checked={user.estate.visible} />  공개
                        </div>
                    </div>
                    {/* <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">인증정보</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" cols="30" rows="3" disabled value={user.values} />
                        </div>
                    </div> */}
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">매력포인트</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" cols="30" rows="3" disabled value={user.advantage} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">이상형</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" cols="30" rows="3" disabled value={user.idealType} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">데이트 스타일</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" cols="30" rows="3" disabled value={user.want} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">매니저님 이것만은 안돼요.</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" cols="30" rows="3" disabled value={user.talkTo} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">코맨트</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" cols="30" rows="3" disabled value={user.memo} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">프로필 사진</label>
                        <div className="col-sm-10">
                            <div className="row">
                                {
                                    GetValue(user, "user._photos._main") &&
                                    <div className="col-sm-4">
                                    <img className="img-thumbnail w-100" src={getProfileUrl(user._photos._main)} alt="" />
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">개인 사진</label>
                        <div className="col-sm-10">
                            <div className="row">
                                {
                                    GetValue(user, "user._photos._privateR", []).map((value, index) => {
                                        return (
                                            <div key={value._id} className="col-sm-4">
                                                <img className="img-thumbnail w-100" src={getProfileUrl(value)} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">취미 일상 사진</label>
                        <div className="col-sm-10">
                            <div className="row">
                                {
                                    GetValue(user, "user._photos._hobbyLife", []).map((value, index) => {
                                        return (
                                            <div key={value._id} className="col-sm-4">
                                                <img className="img-thumbnail w-100" src={getProfileUrl(value)} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">가 (가족력)</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user.familyLevel}>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                        </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">능 (능력)</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user.abilityLevel}>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                        </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">성 (성격)</label>
                        <div className="col-sm-10">
                        <select className="form-control" disabled value={user.personalityLevel}>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                        </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const _renderTrophy = (user) => (
        <div className="col-md-6">
            <div className="card mb-4">
                <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />{user.name} 트로피</div>
                <div className="card-body">
                    {user.trophy ?
                        <form action="">
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">반려동물</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.pet.value")}>
                                    <option value="없음">없음</option>
                                    <option value="개">개</option>
                                    <option value="고양이">고양이</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.pet.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">가족관계증명</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.family.value")}>
                                    <option value="없음">없음</option>
                                    <option value="미혼">미혼</option>
                                    <option value="돌싱">돌싱</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.family.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">수상/장학</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.award.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.award.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">봉사/기부</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.donation.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.donation.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">차량</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.car.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.car.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">자격/면허</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.license.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.license.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">전년도매출</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.sales.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.sales.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">졸업/학위증명</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.graduation.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.graduation.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">재직/재학증명</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.work.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.work.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">세금납부등록</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.tax.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.tax.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">소득증명</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.income.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.income.post")} />  공개
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">부동산자산</label>
                            <div className="col-sm-8">
                                <select className="form-control" disabled value={GetValue(user, "user.trophy.estate.value")}>
                                    <option value="없음">없음</option>
                                    <option value="금">금</option>
                                    <option value="은">은</option>
                                    <option value="동">동</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input type="checkbox" disabled checked={GetValue(user, "user.trophy.estate.post")} />  공개
                            </div>
                        </div>
                    </form>
                        :
                        <div className="text-center">
                            <Card.Title className="text-center">트로피가 아직 등록되지 않은 회원입니다.</Card.Title>
                        </div>
                    }
                </div>
            </div>
        </div>
    )

    const _renderInterest = (user) => (
        <div className="col-md-6">
            <div className="card mb-4">
                <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />{user.name} 이상형</div>
                <div className="card-body">
                    {
                        user.interest ?
                            <form action="">
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">외모</label>
                            <div className="col-sm-6">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.face.value")}>
                                    <option value="이쁨/잘생김">이쁨/잘생김</option>
                                    <option value="귀여운/준수함">귀여운/준수함</option>
                                    <option value="평범이상">평범이상</option>
                                </select>
                            </div>
                            <div className="col-sm-4">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.face.rank")}>
                                    <option value="1">1순위</option>
                                    <option value="2">2순위</option>
                                    <option value="3">3순위</option>
                                    <option value="4">4순위</option>
                                    <option value="5">5순위</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">경제력</label>
                            <div className="col-sm-6">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.income.value")}>
                                    <option value="상관없음">상관없음</option>
                                    <option value="비슷하면됨">비슷하면됨</option>
                                    <option value="중요함">중요함</option>
                                </select>
                            </div>
                            <div className="col-sm-4">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.income.rank")}>
                                    <option value="1">1순위</option>
                                    <option value="2">2순위</option>
                                    <option value="3">3순위</option>
                                    <option value="4">4순위</option>
                                    <option value="5">5순위</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">성격</label>
                            <div className="col-sm-6">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.personality.value")}>
                                    <option value="내향적인">내향적인</option>
                                    <option value="외향적인">외향적인</option>
                                </select>
                            </div>
                            <div className="col-sm-4">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.personality.rank")}>
                                    <option value="1">1순위</option>
                                    <option value="2">2순위</option>
                                    <option value="3">3순위</option>
                                    <option value="4">4순위</option>
                                    <option value="5">5순위</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">가치관</label>
                            <div className="col-sm-6">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.values.value")}>
                                    <option value="현실적">현실적</option>
                                    <option value="사회도덕적">사회도덕적</option>
                                    <option value="미래지향적">미래지향적</option>
                                </select>
                            </div>
                            <div className="col-sm-4">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.values.rank")}>
                                    <option value="1">1순위</option>
                                    <option value="2">2순위</option>
                                    <option value="3">3순위</option>
                                    <option value="4">4순위</option>
                                    <option value="5">5순위</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">가정환경</label>
                            <div className="col-sm-6">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.familyBackground.value")}>
                                    <option value="중요함">중요함</option>
                                    <option value="둘만좋으면됨">둘만 좋으면됨</option>
                                </select>
                            </div>
                            <div className="col-sm-4">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.familyBackground.rank")}>
                                    <option value="1">1순위</option>
                                    <option value="2">2순위</option>
                                    <option value="3">3순위</option>
                                    <option value="4">4순위</option>
                                    <option value="5">5순위</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">키</label>
                            <div className="col-sm-10">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.height")}>
                                    <option value="상관없음">상관없음</option>
                                    <option value="나와10cm이내">나와10cm이내</option>
                                    <option value="나와20cm이내">나와20cm이내</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">직업군</label>
                            <div className="col-sm-10">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.job")}>
                                    <option value="상관없음">상관없음</option>
                                    <option value="도전적">도전적</option>
                                    <option value="안정적">안정적</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">나이대</label>
                            <div className="col-sm-10">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.age")}>
                                    <option value="상관없음">상관없음</option>
                                    <option value="동연하">동연하</option>
                                    <option value="동연상">동연상</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">관심사</label>
                            <div className="col-sm-10">
                                <select className="form-control" disabled value={GetValue(user, "user.interest.interest")}>
                                    <option value="예술운동">예술운동</option>
                                    <option value="일상여행">일상여행</option>
                                    <option value="교육,자기계발">교육,자기계발</option>
                                    <option value="음악,친목모임">음악,친목모임</option>
                                    <option value="기타">기타</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">매치범위</label>
                            <div className="col-sm-10">
                                <select className="form-control" disabled value={GetValue(user, "user.matchRange")}>
                                    <option value="all">모두다</option>
                                    <option value="standard">스탠다드만</option>
                                    <option value="gold">골드이상</option>
                                    <option value="platinum">플래티넘이상</option>
                                    <option value="diamond">다이아이상</option>
                                </select>
                            </div>
                        </div>
                    </form>
                            :
                            <div className="text-center">
                            <Card.Title className="text-center">이상형이 아직 등록되지 않은 회원입니다.</Card.Title>
                    </div>
                    }
                </div>
            </div>
        </div>
    )

    return (
        <Modal show={show}
               size="xl"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               onHide={hide}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="userShowModalLabel">회원매칭하기</h5>
                    <button type="button" className="close" onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        {_renderProfile(user1)}
                        {_renderProfile(user2)}
                    </div>
                    <div className="row">
                        {_renderTrophy(user1)}
                        {_renderTrophy(user2)}
                    </div>
                    <div className="row">
                        {_renderInterest(user1)}
                        {_renderInterest(user2)}
                    </div>
                </div>
                <div className="modal-footer">
                    {
                        ("request" === type) &&
                        <>
                            <div className="input-group" style={{ width: 150 }}>
                                <input type="number" className="form-control" placeholder="매칭확률" value={rate} onChange={e => setRate(e.target.value)} />
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon2">%</span>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={_saveRecommend}>후보추천등록</button>
                        </>
                    }
                    {
                        (("wait" === type && (!matchingStep || 1 === matchingStep)) || 'success' === type) &&
                        <>
                            <div className="input-group" style={{ width: 250 }}>
                                <input type={"datetime-local"} className="form-control" data-date-format={"YYYY-MM-DD HH:mm"} value={getMeetAt()} onChange={e => changeMeetAt(e)} placeholder={"미팅 시간을 입력해주세요"} />
                            </div>
                        </>
                    }
                    {
                        ("response" === type || "wait" === type) &&
                        <>
                            <button type="button" className="btn btn-primary" onClick={e => _saveMatchStatus(e, "success")} disabled={!_availableSuccess()}>{_successButtonLabel()}</button>
                            <button type="button" className="btn btn-warning" onClick={e => _saveMatchStatus(e, "fail")}>매칭거절</button>
                        </>
                    }
                    {
                        ('success' === type) &&
                        <>
                            <button type="button" className="btn btn-info" onClick={e => _modifyMeetAt(e)}>미팅날짜수정</button>
                        </>
                    }
                    <button type="button" className="btn btn-secondary" onClick={closeModal}>확인</button>
                </div>
            </div>
        </Modal>
    )
}