import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react"
import { getGrade, getStatus } from "../utils/Utils"
import { getProfileUrl } from "../utils/ImageUtil"
import GetValue from "../utils/GetValue"
import { faAngleRight, faTable } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default observer((props) => {
    const { children, user, title, path, isNewMatch, responseMatchCount, permission} = props
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (user) {
            setLoaded(true)
        }
    }, [user])

    return loaded && (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="media">
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img className="align-self-center mr-3" style={{width:64, height:64, borderRadius:32}} src={getProfileUrl(GetValue(user, "user._photos._main"))} alt="image" />
                                <div className="media-body d-flex my-auto">
                                    <div className="mr-2">
                                        <label className="small mb-0">이름</label>
                                        <div>{GetValue(user, "user.name")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/user/:id/request" && "active"}`} >
                                <a href={`/user/${user._id}/request`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                    <div className="small">소개/이어줘 요청</div>
                                    <div className="small">
                                        <ul className="list-inline list-unstyled">
                                            { isNewMatch &&
                                                <li className="list-inline-item">
                                                    <span className="badge badge-pill badge-danger">new</span>
                                                </li>
                                            }
                                            <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                            <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/user/:id/response" && "active"}`} >
                                <a href={`/user/${user._id}/response`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                    <div className="small">받은매칭요청</div>
                                    <div className="small">
                                        <ul className="list-inline list-unstyled">
                                            {  Number(responseMatchCount) > 0 &&
                                                <li className="list-inline-item">
                                                     <span className="badge badge-pill badge-danger">{responseMatchCount}</span>
                                                </li>
                                            }
                                            <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                            <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/user/:id" && "active"}`} >
                                <a href={`/user/${user._id}`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                    <div className="small">계정정보</div>
                                    <div className="small">
                                        <ul className="list-inline list-unstyled">
                                            <li className="list-inline-item"><span className="badge badge-pill badge-danger">{getStatus(user.status)}</span></li>
                                            <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                            <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/user/:id/profile" && "active"}`} >
                                <a href={`/user/${user._id}/profile`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                    <div className="small">프로필</div>
                                    <div className="small">
                                        <ul className="list-inline list-unstyled">
                                            <li className="list-inline-item"><span className="badge badge-pill badge-danger">{getGrade(user.grade)}</span></li>
                                            <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                            <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/user/:id/trophy" && "active"}`} >
                                <a href={`/user/${user._id}/trophy`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                    <div className="small">트로피</div>
                                    <div className="small">
                                        <ul className="list-inline list-unstyled">
                                            <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                            <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/user/:id/interest" && "active"}`} >
                                <a href={`/user/${user._id}/interest`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                    <div className="small">이상형</div>
                                    <div className="small">
                                        <ul className="list-inline list-unstyled">
                                            <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                            <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/user/:id/service" && "active"}`} >
                                <a href={`/user/${user._id}/service`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                    <div className="small">이용서비스</div>
                                    <div className="small">
                                        <ul className="list-inline list-unstyled">
                                            <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                            {
                                permission === 'super' &&
                                <li className={`list-group-item justify-content-between align-items-center list-group-item-action ${path === "/user/:id/password" && "active"}`} >
                                    <a href={`/user/${user._id}/password`} className="d-block d-flex text-dark no-decoration justify-content-between">
                                        <div className="small">비밀번호 수정</div>
                                        <div className="small">
                                            <ul className="list-inline list-unstyled">
                                                <li className="list-inline-item"><FontAwesomeIcon icon={faAngleRight} className="fas" /></li>
                                            </ul>
                                        </div>
                                    </a>
                                </li>
                            }
                        </ul>
                        <div className="p-3">
                                코맨트
                            <div className="mt-2">
                                {user.memo}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="card mb-4">
                        <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />{title}</div>
                        <div className="card-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
