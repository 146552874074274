import Api from "./Api"

const url = "manager"
const Match = {
    get: async (params = {}) => {
        return await Api.get(`${url}`, params)
    },
    show: async (_manager) => {
        return await Api.get(`${url}/${_manager}`)
    },
    my: async () => {
        return await Api.get(`${url}/my`)
    }
}

export default Match