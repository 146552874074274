import _Component from "../../common/_Component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import { IconButton } from '@material-ui/core'
import { Delete, Visibility } from "@material-ui/icons"
import React from "react"
import { action, observable } from "mobx"
import { getMoment, isLineNumber } from "../../utils/Utils"
import Pagination from "../../components/Pagination"
import Arranger from "../../api/Arranger"

class ArrangerScreen extends _Component {
    @observable search = ''
    @observable admins = []
    @observable pagination = {}
    @observable name = ''
    @observable phone = ''
    @observable arrange = { name: "", phone: "" }

    async componentDidMount() {
        try {
            await this._getData()
            super.componentDidMount()
        } catch (e) {
            console.log(e)
        }
    }

    @action
    _save = async (e) => {
        e.preventDefault()
        const params = {...this.arrange }
        try {
            const response = await Arranger.save(params)
            if (response && response.success) {
                alert("주선자 정보 입력 완료")
                await this._getData(1)
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _search = async (e) => {
        e.preventDefault()
        await this._getData(1)
    }

    @action
    _getData = async (page = 1, e) => {
        if (e) e.preventDefault()
        const lineNumber = isLineNumber(this.name)
        const params = {
            page: page,
            limit: this.limit,
            name: lineNumber ? null : this.name,
            lineNumber: lineNumber ? this.name : null,
            status: this.status,
            date: this.date,
        }
        try {
            const response = await Arranger.myUser(params)
            if (response && response.success) {
                this.arrangers = response.arrangers
                this.pagination = response.pagination
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _render = () => (
        <div className="container-fluid">
            <div className="card mb-4">
                <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />주선리스트</div>
                <div className="card-body">
                    <div>
                        <form action="" className="d-md-flex">
                            <div className="form-group mr-2">
                                <label>이름</label>
                                <input type="text" className="form-control" placeholder="이름" value={this.arrange.name} onChange={e => this.arrange.name = e.target.value}/>
                            </div>
                            <div className="form-group mr-2">
                                <label>휴대전화</label>
                                <input type="text" className="form-control" placeholder="'-'없이 입력"  value={this.arrange.phone} onChange={e => this.arrange.phone = e.target.value}/>
                            </div>
                            <div className="form-group mr-2">
                                <button className="btn btn-primary" style={{ marginTop: 31 }} onClick={this._save}>등록</button>
                            </div>
                            <div className="form-group mr-2">
                                <button className="btn btn-outline-secondary"  style={{ marginTop: 31 }} onClick={e => this._delete(e, "arranger", this._getData.bind(this), this.pagination.currentPage, this.arrangers)}>선택삭제</button>
                            </div>
                        </form>
                    </div>
                    <div className="divider"></div>
                    <div className="d-md-flex">
                        <div className="form-group mr-2">
                            <label htmlFor="search">회원검색</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="회원이름 또는 뒷번호" value={this.name} onChange={e => this.name = e.target.value} />
                                <div className="input-group-append">
                                  <button className="btn btn-outline-secondary" type="button" onClick={e => this._search(e)}>검색</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mr-2">
                            <label htmlFor="search">상태</label>
                            <select className="form-control" id="filter_status" value={this.status} onChange={e => this.status = e.target.value}>
                                <option value={""}>전체</option>
                                <option value={true}>유효</option>
                                <option value={false}>무효</option>
                            </select>
                        </div>
                        <div className="form-group mr-2">
                            <label htmlFor="search">확정일</label>
                            <select className="form-control" id="filter_status" value={this.request} onChange={e => this.date = e.target.value}>
                                <option value={""}>전체</option>
                                <option value={"today"}>오늘확정</option>
                            </select>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table text-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input type="checkbox" onChange={e => this._checkAll(e, this.arrangers)} />
                                    </th>
                                    <th scope="col">Atc.</th>
                                    <th scope="col">등록일</th>
                                    <th scope="col">이름</th>
                                    <th scope="col">휴대전화</th>
                                    <th scope="col">상태</th>
                                    <th scope="col">포인트</th>
                                    <th scope="col">확정일</th>
                                  </tr>
                            </thead>
                            <tbody>
                            {
                                this.arrangers && this.arrangers.length > 0 && this.arrangers.map((value, index) => (
                                    <tr key={value._id}>
                                    <td>
                                        <input type="checkbox" checked={!!value.checked} onChange={(e) => this.arrangers[index].checked = e.target.checked} />
                                    </td>
                                    <td>
                                        {
                                            value._user && value._user._id &&
                                            <IconButton
                                                className="text-info fas"
                                                aria-label="visibility"
                                                style={{ padding: 0, outline: "none" }}
                                                //href={`/user/${value._user._id}`}
                                            >
                                          <Visibility />
                                        </IconButton>
                                        }
                                        <IconButton
                                            color={"secondary"}
                                            aria-label="delete"
                                            style={{ padding: 0, outline: "none" }}
                                            onClick={e => this._delete(e, `arranger/${value._id}`, this._getData.bind(this), this.pagination.currentPage)}
                                        >
                                          <Delete />
                                        </IconButton>
                                    </td>
                                    <td>{getMoment(value.createdAt)}</td>
                                    <td>{value.name}</td>
                                    <td>{value.phone}</td>
                                    <td>{value.status === "success" ? "유효" : "무효"}</td>
                                    <td>{value.point}</td>
                                    <td>{getMoment(value.pointedAt, "YYYY.MM.DD")}</td>
                                </tr>
                                ))
                            }
                            </tbody>
                          </table>
                    </div>
                        <div className="d-flex">
                        <div className="d-flex mr-auto">
                            <div>
                                <select className="form-control form-control-sm" value={this.limit} onChange={e => this._changeLimit(e, this._getData.bind(this))}>
                                        <option value={"1"}>1</option>
                                        <option value={"10"}>10</option>
                                        <option value={"20"}>20</option>
                                        <option value={"30"}>30</option>
                                        <option value={"40"}>40</option>
                                        <option value={"50"}>50</option>
                                  </select>
                            </div>
                        </div>
                        <div>
                            <nav aria-label="Page navigation example">
                                <Pagination handle={this._getData.bind(this)} pagination={this.pagination} />
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ArrangerScreen