import Api from "./Api"
const url = "match"
const Match = {
    get : async (params = {}) => {
        return await Api.get(`${url}`, params)
    },
    show : async (id) => {
        return await Api.get(`${url}/${id}`)
    },
    update : async (id, params = {}) => {
        return await Api.patch(`${url}/${id}`, params)
    },
    updateMeetAt: async (id, meetAt) => {
        return await Api.patch(`${url}/${id}/meet`, { meetAt, })
    },
    create : async (params = {}) => {
        return await Api.post(`${url}`, params)
    },
    check : async (params = {}) => {
        return await Api.get(`${url}/check`, params)
    }
}

export default Match