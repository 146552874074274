import Api from "./Api"
const url = "dashboard"
const Dashboard = {
    get : async (params = {}) => {
        return await Api.get(`${url}`, params)
    },
    getForAdmin: async (params = {}) => {
        return await Api.get(`${url}/admin`, params)
    },
}

export default Dashboard