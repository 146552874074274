import React from "react"
import _Component from "../../common/_Component"
import { observable } from "mobx"
import Admin from "../../api/Admin"

export default class Password extends _Component {
    @observable email = ""

    _sendEmail = async (e) => {
        e.preventDefault()
        try {
            const response = await Admin.password({ email: this.email })
            if (response && response.success) {
                alert("잠시뒤 이메일로 임시 비밀번호가 발급됩니다. 메일이 오지 않는다면 스팸메일함을 확인 바랍니다.")
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _render = () => {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="card shadow-lg border-0 rounded-lg mt-5">
                            <div className="card-header">
                                <h3 className="text-center font-weight-light my-4">비밀번호 찾기</h3>
                            </div>
                            <div className="card-body">
                                <div className="small mb-3 text-muted">가입한 이메일을 입력해주세요.</div>
                                <form>
                                    <div className="form-group">
                                        <label className="small mb-1" htmlFor="inputEmailAddress">이메일</label>
                                        <input
                                            className="form-control py-4"
                                            id="inputEmailAddress"
                                            type="email"
                                            aria-describedby="emailHelp"
                                            value={this.email}
                                            onChange={(e) => this.email = e.target.value}
                                        />
                                    </div>
                                    <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                        <a className="small" href="/login">로그인으로 돌아가기</a>
                                        <button className="btn btn-primary" onClick={this._sendEmail}>새로운 비밀번호 받기</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}