import GetValue from "./GetValue"

export const setInterest = (interest) => {
    return {
        face  : GetValue(interest, "interest.face.value" , "이쁨/잘생김"),
        faceRank : GetValue(interest, "interest.face.rank", 1),
        income :  GetValue(interest, "interest.income.value" , "상관없음"),
        incomeRank : GetValue(interest, "interest.income.rank", 1),
        personality : GetValue(interest, "interest.personality.value" , "내향적인"),
        personalityRank : GetValue(interest, "interest.personality.rank", 1),
        values : GetValue(interest, "interest.values.value" , "현실적"),
        valuesRank : GetValue(interest, "interest.values.rank", 1),
        familyBackground : GetValue(interest, "interest.familyBackground.value" , "중요함"),
        familyBackgroundRank : GetValue(interest, "interest.familyBackground.rank", 1),
        height : GetValue(interest, "interest.height" , "상관없음"),
        job : GetValue(interest, "interest.job" , "상관없음"),
        age : GetValue(interest, "interest.age" , "상관없음"),
        interest : GetValue(interest, "interest.interest" , "기타"),
    }
}