import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { action, observable } from 'mobx'
import _Component from '../../common/_Component'
import Product from '../../api/Product'
import { inject } from "mobx-react"
import GetValue from "../../utils/GetValue"
import { isId } from "../../utils/Utils"
import Goods from '../../helper/goods.helper'

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class ProductShowScreen extends _Component {
    @observable _id = ''
    @observable name = ''
    @observable price = ''
    @observable type = ''
    @observable gender = 'all'
    @observable showItem = null
    @observable connectItem = null
    @observable standardTicket = null
    @observable goldTicket = null
    @observable platinumTicket = null
    @observable diamondTicket = null

    componentDidMount = async () => {
        try {
            const id = GetValue(this, "this.props.match.params.id")
            if (!id || !isId(id)) {
                alert("잘못된 접근입니다.")
                this.history.goBack()
                return true
            }
            const response = await Product.show(id)
            if (response && response.success) {
                const { product } = response
                this._id = product._id
                this.name = product.name
                this.type = product.type
                this.gender = product.gender

                this.showItem = Goods.create(product.showItem_free, product.showItem_paid, product.showItem_price)

                this.connectItem = Goods.create(product.connectItem_free, product.connectItem_paid, product.connectItem_price)

                this.standardTicket = Goods.create(product.standardTicket_free, product.standardTicket_paid, product.standardTicket_price)

                this.goldTicket = Goods.create(product.goldTicket_free, product.goldTicket_paid, product.goldTicket_price)

                this.platinumTicket = Goods.create(product.platinumTicket_free, product.platinumTicket_paid, product.platinumTicket_price)

                this.diamondTicket = Goods.create(product.diamondTicket_free, product.diamondTicket_paid, product.diamondTicket_price)

                this.price = this.getTotalPrice()
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
        super.componentDidMount()
    }

    @action
    _save = async (e) => {
        e.preventDefault()
        const param = {
            name: this.name,
            type: this.type,
            gender: this.gender,

            showItem_free: Goods.free(this.showItem),
            showItem_paid: Goods.paid(this.showItem),
            showItem_price: Goods.price(this.showItem),

            connectItem_free: Goods.free(this.connectItem),
            connectItem_paid: Goods.paid(this.connectItem),
            connectItem_price: Goods.price(this.connectItem),

            standardTicket_free: Goods.free(this.standardTicket),
            standardTicket_paid: Goods.paid(this.standardTicket),
            standardTicket_price: Goods.price(this.standardTicket),

            goldTicket_free: Goods.free(this.goldTicket),
            goldTicket_paid: Goods.paid(this.goldTicket),
            goldTicket_price: Goods.price(this.goldTicket),

            platinumTicket_free: Goods.free(this.platinumTicket),
            platinumTicket_paid: Goods.paid(this.platinumTicket),
            platinumTicket_price: Goods.price(this.platinumTicket),

            diamondTicket_free: Goods.free(this.diamondTicket),
            diamondTicket_paid: Goods.paid(this.diamondTicket),
            diamondTicket_price: Goods.price(this.diamondTicket),
        }

        try {
            const response = await Product.update(this._id, param)
            if (response && response.success) {
                alert("상품이 수정되었습니다.")
                this.props.history.push("/product")
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _delete = async (e) => {
        e.preventDefault()
        const confirm = window.confirm("삭제하시겠습니까?")
        if (confirm) {
            try {
                const response = await Product.delete(this._id)
                if (response && response.success) {
                    alert("상품이 삭제되었습니다.")
                    this.props.history.push('/product')
                } else {
                    alert(response.message)
                }
            } catch (e) {
                alert(e.toString())
            }
        }
    }

    getPriceString = (price) => {
        return `${price} 원`
    }

    getTotalPrice = () => {
        const price = Goods.price(this.showItem) +
            Goods.price(this.connectItem) +
            Goods.price(this.standardTicket) +
            Goods.price(this.goldTicket) +
            Goods.price(this.platinumTicket) +
            Goods.price(this.diamondTicket)
        return price.toString()
    }

    @action
    modifyPrice = action => {
        if (action)
            action()
        this.price = this.getTotalPrice()
    }

    renderGoods = (label, goods) => {
        return (<tr>
            <td width="20%">{label}</td>
            <td width="20%">
                <input
                    type="number"
                    className="form-control"
                    placeholder="무료"
                    value={Goods.free(goods)}
                    onChange={(e) => Goods.setFree(goods, e.target.value)}
                />
            </td>
            <td width="20%">
                <input
                    type="number"
                    className="form-control"
                    placeholder="유료"
                    value={Goods.paid(goods)}
                    onChange={(e) => Goods.setPaid(goods, e.target.value)}
                />
            </td>
            <td width="40%">
                <div className="input-group">
                    <input
                        type="number"
                        className="form-control"
                        placeholder="유료가격"
                        value={Goods.price(goods)}
                        onChange={(e) => this.modifyPrice(() => Goods.setPrice(goods, e.target.value))}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">원</span>
                    </div>
                </div>
            </td>
        </tr>)
    }

    _render = () => (
        <div className="container-fluid">
            <div className="card mb-4">
                <div className="card-header">
                    <FontAwesomeIcon icon={faTable} className="fas mr-1" />상품 수정
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">ID</label>
                            <div className="col-sm-10">
                                <input type="text" readOnly className="form-control-plaintext" value={this._id} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">상품이름</label>
                            <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="상품이름"
                                value={this.name}
                                onChange={(e) => this.name = e.target.value}
                            />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">최종가격</label>
                            <div className="col-sm-10">
                                <label className="form-control-plaintext">{this.getPriceString(this.price)}</label>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">타입</label>
                            <div className="col-sm-10">
                                <select name="status" className="form-control" value={this.type} onChange={(e) => this.type = e.target.value}>
                                    <option value="">선택</option>
                                    <option value="charge">충전아이템</option>
                                    <option value="ticket">성사이용권</option>
                                    <option value="premium">프리미엄이용권</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label small">구매가능성별</label>
                            <div className="col-sm-10">
                                <select
                                    className="form-control"
                                    value={this.gender}
                                    onChange={e => this.gender = e.target.value}
                                >
                                    <option value="all">모두</option>
                                    <option value="male">남성만</option>
                                    <option value="female">여성만</option>
                                </select>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table text-nowrap">
                                <thead>
                                    <tr>
                                        <th scope="col">항목</th>
                                        <th scope="col">무료</th>
                                        <th scope="col">유료</th>
                                        <th scope="col">유료가격</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderGoods("더보여줘", this.showItem)}
                                    {this.renderGoods("이어줘", this.connectItem)}
                                    {this.renderGoods("스탠다드", this.standardTicket)}
                                    {this.renderGoods("골드", this.goldTicket)}
                                    {this.renderGoods("플래티넘", this.platinumTicket)}
                                    {this.renderGoods("다이아", this.diamondTicket)}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <button className="btn btn-primary" style={{ marginRight: 5 }} onClick={this._save}>수정</button>
                            <button className="btn btn-secondary" onClick={this._delete}>삭제</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ProductShowScreen
