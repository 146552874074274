import _Component from "../../common/_Component"
import React from "react"
import { observable } from "mobx"
import Manager from "../../api/Manager"
import AdminShowLayout from "../../layouts/AdminShowLayout"
import GetValue from "../../utils/GetValue"
import { addComma, isId } from "../../utils/Utils"
import MathHelper from "../../helper/math.helper"

class AdminShowManageSalesScreen extends _Component {
    @observable manager = null
    @observable sales = null
    @observable admin = null
    @observable manageUsersCount = 0
    @observable permission = ""

    async componentDidMount() {
        try {
            this.admin = await this._getAdmin()
            if (this.admin && this.admin.permission) this.permission = this.admin.permission
            if (GetValue(this, "this.admin.permission") !== "manager") {
                alert("잘못된 접근입니다.")
                this.props.history.goBack()
                return true
            }
            const _id = GetValue(this, "this.props.match.params.id")
            if (!_id || !isId(_id)) {
                alert("잘못된 접근입니다.")
                this.props.history.goBack()
                return true
            }
            const response = await Manager.show(_id)
            if (response && response.success) {
                this.manageUsersCount = response.manageUsersCount
                this.sales = response.sales
                super.componentDidMount()
            } else {
                alert(response.message)
            }
        } catch (e) {
            console.log(e)
        }
    }

    _render = () => (
        <AdminShowLayout title={"실적"} admin={this.admin} _id={this.admin._id} path={this.path} permission={this.permission}>
            <div className="table-responsive">
                <table className="table text-nowrap">
                    <tbody>
                        <tr>
                            <th>관리회원수</th>
                            <td>{addComma(this.manageUsersCount)}</td>
                        </tr>
                        <tr>
                            <th>당월매출</th>
                            <td>{addComma(GetValue(this, "this.sales[0].revenueForThisMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>전월매출</th>
                            <td>{addComma(GetValue(this, "this.sales[0].revenueForLastMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>당월결제매출</th>
                            <td>{addComma(GetValue(this, "this.sales[0].salesRevenueForThisMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>전월결제매출</th>
                            <td>{addComma(GetValue(this, "this.sales[0].salesRevenueForLastMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>당월결제수</th>
                            <td>{addComma(GetValue(this, "this.sales[0].salesForThisMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>전월결제수</th>
                            <td>{addComma(GetValue(this, "this.sales[0].salesForLastMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>당월성사매출</th>
                            <td>{addComma(MathHelper.subtract(GetValue(this, "this.sales[0].revenueForThisMonth", 0), GetValue(this, "this.sales[0].salesRevenueForThisMonth", 0)))}</td>
                        </tr>
                        <tr>
                            <th>전월성사매출</th>
                            <td>{addComma(MathHelper.subtract(GetValue(this, "this.sales[0].revenueForLastMonth", 0), GetValue(this, "this.sales[0].salesRevenueForLastMonth", 0)))}</td>
                        </tr>
                        <tr>
                            <th>당월성사수</th>
                            <td>{addComma(GetValue(this, "this.sales[0].successForThisMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>전월성사수</th>
                            <td>{addComma(GetValue(this, "this.sales[0].successForLastMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>당월매치수</th>
                            <td>{addComma(MathHelper.add(GetValue(this, "this.sales[0].successForThisMonth", 0), GetValue(this, "this.sales[0].failForThisMonth", 0)))}</td>
                        </tr>
                        <tr>
                            <th>전월매치수</th>
                            <td>{addComma(MathHelper.add(GetValue(this, "this.sales[0].successForLastMonth", 0), GetValue(this, "this.sales[0].failForLastMonth", 0)))}</td>
                        </tr>
                        <tr>
                            <th>당월실패수</th>
                            <td>{addComma(GetValue(this, "this.sales[0].failForThisMonth", 0))}</td>
                        </tr>
                        <tr>
                            <th>전월실패수</th>
                            <td>{addComma(GetValue(this, "this.sales[0].failForLastMonth", 0))}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </AdminShowLayout>
    )
}

export default AdminShowManageSalesScreen