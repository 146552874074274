import _Component from "../../common/_Component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import { IconButton } from '@material-ui/core'
import { Delete, Visibility, Sort } from "@material-ui/icons"
import React from "react"
import { action, observable } from "mobx"
import Manager from "../../api/Manager"
import { addComma, getMoment, isLineNumber } from "../../utils/Utils"
import Pagination from "../../components/Pagination"
import GetValue from "../../utils/GetValue"
import MathHelper from "../../helper/math.helper"
import Admin from "../../api/Admin"

class AdminManagerScreen extends _Component {
    @observable search = ''
    @observable managers = []
    @observable pagination = {}
    @observable admin = null
    @observable sort = ""
    @observable sortType = ""

    async componentDidMount() {
        const response = await Admin.my()
        try {
            if (response && response.success) {
                this.admin = response.admin
            }
            await this._getData()
            super.componentDidMount()
        } catch (e) {
            console.log(e)
        }
    }

    _search = async (e) => {
        e.preventDefault()
        await this._getData(1)
    }

    @action
    _getData = async (page = 1) => {
        try {
            const lineNumber = isLineNumber(this.search)
            const response = await Manager.get({ 
                page: page,
                name: lineNumber ? null : this.search,
                lineNumber: lineNumber ? this.search : null,
                limit: this.limit, 
                _admin: this.admin._id,
                sort: this.sort,
                sortType: this.sortType
            })
            if (response && response.success) {
                this.managers = response.managers
                this.pagination = response.pagination
                this.loaded = true
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _setSort = async (target, type) => {
        this.sort = target
        this.sortType = type
        await this._getData()
    }

    _render = () => (
        <div className="container-fluid">
            <div className="card mb-4">
                <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />매니저 목록</div>
                <div className="card-body">
                    <div className="d-md-flex">
                        <div className="form-group mr-2">
                            <label htmlFor="search">매니저검색</label>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="매니저이름 또는 뒷번호" value={this.search} onChange={e => this.search = e.target.value} />
                                <div className="input-group-append">
                                  <button className="btn btn-outline-secondary" type="button" onClick={this._search}>검색</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-md-flex mb-3">
                        <div style={{ marginTop: 32 }}>
                            <a href={"/admin/create"} className="btn btn-outline-success" style={{ marginRight: 5 }}>데이터 추가</a>
                            <button
                                className="btn btn-outline-secondary"
                                onClick={e => this._delete(e, "admin", this._getData.bind(this), this.pagination.currentPage, this.admins)}>선택삭제
                            </button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table text-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input type="checkbox" onChange={e => this._checkAll(e, this.admins)} />
                                    </th>
                                    <th scope="col">Atc.</th>
                                    <th scope="col">이메일</th>
                                    <th scope="col">
                                        이름
                                        <IconButton
                                            aria-label="name"
                                            style={{ padding: 0, outline: "none" }}
                                            // onClick={e => this._delete(e, `user/${value._id}`, this._getData.bind(this), this.pagination.currentPage)}
                                            onClick={() => this._setSort('name', !this.sortType || this.sortType < 0 ? 1 : -1 )}
                                        >
                                        <Sort />
                                        </IconButton>
                                    </th>
                                    <th scope="col">담당관리자</th>
                                    <th scope="col">직급</th>
                                    <th scope="col">관리회원수</th>
                                    <th scope="col" className="separator-left">당월매출</th>
                                    <th scope="col">전월매출</th>
                                    <th scope="col" className="separator-left">당월 결제매출</th>
                                    <th scope="col">전월 결제매출</th>
                                    <th scope="col" className="separator-left">당월 결제수</th>
                                    <th scope="col">전월 결제수</th>
                                    <th scope="col" className="separator-left">당월 성사매출</th>
                                    <th scope="col">전월 성사매출</th>
                                    <th scope="col" className="separator-left">당월 성사수</th>
                                    <th scope="col">전월 성사수</th>
                                    <th scope="col" className="separator-left">당월 매치건수</th>
                                    <th scope="col">전월 매치건수</th>
                                    <th scope="col" className="separator-left">당월 실패수</th>
                                    <th scope="col">전월 실패수</th>
                                    <th scope="col" className="separator-left">마지막 접속일</th>
                                    <th scope="Øcol">등록일</th>
                                    <th scope="col">수정일</th>
                                  </tr>
                            </thead>
                            <tbody>
                            {
                                this.managers && this.managers.length > 0 && this.managers.map((value, index) => (
                                    <tr key={value._id}>
                                    <td>
                                        <input type="checkbox" checked={!!value.checked} onChange={(e) => this.admins = this._check(e, this.admins, index)} />
                                    </td>
                                    <td>
                                        <IconButton
                                            className="text-info fas"
                                            aria-label="visibility"
                                            style={{ padding: 0, outline: "none" }}
                                            href={`/admin/${value._id}`}
                                        >
                                          <Visibility />
                                        </IconButton>
                                        <IconButton
                                            color={"secondary"}
                                            aria-label="delete"
                                            style={{ padding: 0, outline: "none" }}
                                            onClick={e => this._delete(e, `admin/${value._id}`, this._getData.bind(this), this.pagination.currentPage)}
                                        >
                                          <Delete />
                                        </IconButton>
                                    </td>
                                    <td>{value.email}</td>
                                    <td>{value.name}</td>
                                    <td>{value._admin_docs.length > 0 ? value._admin_docs[0].name : '' }</td>
                                    <td>{value.position}</td>
                                    <td>{addComma(GetValue(value, "value._user.count", 0))}</td>
                                    <td className="separator-left">{addComma(GetValue(value, "value._manager[0].revenueForThisMonth", 0))}</td>
                                    <td>{addComma(GetValue(value, "value._manager[0].revenueForLastMonth", 0))}</td>
                                    <td className="separator-left">{addComma(GetValue(value, "value._manager[0].salesRevenueForThisMonth", 0))}</td>
                                    <td>{addComma(GetValue(value, "value._manager[0].salesRevenueForLastMonth", 0))}</td>
                                    <td className="separator-left">{addComma(GetValue(value, "value._manager[0].salesForThisMonth", 0))}</td>
                                    <td>{addComma(GetValue(value, "value._manager[0].salesForLastMonth", 0))}</td>
                                    <td className="separator-left">{addComma(MathHelper.subtract(GetValue(value, "value._manager[0].revenueForThisMonth", 0), GetValue(value, "value._manager[0].salesRevenueForThisMonth", 0)))}</td>
                                    <td>{addComma(MathHelper.subtract(GetValue(value, "value._manager[0].revenueForLastMonth", 0), GetValue(value, "value._manager[0].salesRevenueForLastMonth", 0)))}</td>
                                    <td className="separator-left">{addComma(GetValue(value, "value._manager[0].successForThisMonth", 0))}</td>
                                    <td>{addComma(GetValue(value, "value._manager[0].successForLastMonth", 0))}</td>
                                    <td className="separator-left">{addComma(MathHelper.add(GetValue(value, "value._manager[0].successForThisMonth", 0), GetValue(value, "value._manager[0].failForThisMonth", 0)))}</td>
                                    <td>{addComma(MathHelper.add(GetValue(value, "value._manager[0].successForLastMonth", 0), GetValue(value, "value._manager[0].failForLastMonth", 0)))}</td>
                                    <td className="separator-left">{addComma(GetValue(value, "value._manager[0].failForThisMonth", 0))}</td>
                                    <td>{addComma(GetValue(value, "value._manager[0].failForLastMonth", 0))}</td>
                                    <td className="separator-left">{value.loginAt ? getMoment(value.loginAt) : ""}</td>
                                    <td>{getMoment(value.createdAt)}</td>
                                    <td>{getMoment(value.updatedAt)}</td>
                                </tr>
                                ))
                            }
                            </tbody>
                          </table>
                    </div>
                    <div className="d-flex">
                        <div className="d-flex mr-auto">
                            <div>
                                <select className="form-control form-control-sm" value={this.limit} onChange={e => this._changeLimit(e, this._getData.bind(this))}>
                                    <option value={"10"}>10</option>
                                    <option value={"20"}>20</option>
                                    <option value={"30"}>30</option>
                                    <option value={"40"}>40</option>
                                    <option value={"50"}>50</option>
                                  </select>
                            </div>
                        </div>
                        <div>
                            <nav aria-label="Page navigation example">
                                <Pagination handle={this._getData.bind(this)} pagination={this.pagination} />
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminManagerScreen