const MathHelper = {
    add: (a, b) => {
        return Number(a) + Number(b)
    },
    
    subtract: (a, b) => {
        return Number(a) - Number(b)
    },

    multiply: (a, b) => {
        return Number(a) * Number(b)
    },

    divide: (a, b) => {
        const value_a = Number(a)
        const value_b = Number(b)
        return 0 < value_b ? value_a / value_b : 0
    },
}

export default MathHelper