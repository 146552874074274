let Config = {}

Config.__LIVE__ = process.env.REACT_APP_WEB_ENV === "production"
Config.__STAGE__ = process.env.REACT_APP_WEB_ENV === "stage"
Config.__DEV__ = process.env.REACT_APP_WEB_ENV === "development"

Config.apiUrl = Config.__LIVE__ ? process.env.REACT_APP_API_URL : Config.__STAGE__ ? process.env.REACT_APP_API_URL_STAGE : process.env.REACT_APP_API_URL_DEV
Config.apiRequestBaseUrl = `${Config.apiUrl}${process.env.REACT_APP_API_ADD_URL}`
Config.staticImageUrl = `${Config.apiUrl}/public/images`

Config.clientUrl = Config.__LIVE__ ? process.env.REACT_APP_CLIENT_URL : Config.__STAGE__ ? process.env.REACT_APP_CLIENT_URL_STAGE : process.env.REACT_APP_CLIENT_URL_DEV

Config.adminUrl = Config.__LIVE__ ? process.env.REACT_APP_ADMIN_URL : Config.__STAGE__ ? process.env.REACT_APP_ADMIN_URL_STAGE : process.env.REACT_APP_ADMIN_URL_DEV

Config.moduleUrl = Config.__LIVE__ ? process.env.REACT_APP_MODULE_URL : Config.__STAGE__ ? process.env.REACT_APP_MODULE_URL_STAGE : process.env.REACT_APP_MODULE_URL_DEV

Config.photoUrl = Config.__LIVE__ ? process.env.REACT_APP_PHOTO_URL : Config.__STAGE__ ? process.env.REACT_APP_PHOTO_URL_STAGE : process.env.REACT_APP_PHOTO_URL_DEV

Config.defaultLimit = 10
export default Config