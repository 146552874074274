import Api from "./Api"

const url = "user"
const User = {
    get: async (params = {}) => {
        return await Api.get(`${url}`, params)
    },
    show: async (id) => {
        return await Api.get(`${url}/${id}`)
    },
    getFromManager: async (params = {}) => {
        return await Api.get(`${url}/manager`, params)
    },
    update: async (id, params = {}) => {
        return await Api.patch(`${url}/${id}`, params)
    },
    updateAll: async (id, params = {}) => {
        return await Api.put(`${url}/${id}`, params)
    },
    save: async (params = {}) => {
        return await Api.post(`${url}`, params)
    },
    delete: async (id) => {
        return await Api.delete(`${url}/${id}`)
    },
    recommend: async (id, params) => {
        return await Api.get(`${url}/${id}/recommend`, params)
    },
}

export default User