import Api from "./Api"
const Product = {
    get : async (params = {}) => {
        return await Api.get("product", params)
    },
    show : async (id) => {
        return await Api.get(`product/${id}`)
    },
    save : async (params = {}) => {
        return await Api.post("product", params)
    },
    update : async (id, params = {}) => {
        return await Api.patch(`product/${id}`, params)
    },
    delete : async (id) => {
        return await Api.delete(`product/${id}`)
    },
}

export default Product