export default (object, depth, defaultPrint = '') => {
    const _parseArrayData = (state, str) => {
        let result = {
            result: false, data: ""
        }
        const leftIndex = str.indexOf("[")
        const rightIndex = str.indexOf("]")
        if (leftIndex > 0 && rightIndex > 0) {
            let number = str.substring(leftIndex + 1, rightIndex)
            if (state && state[str.substring(0, leftIndex)] && state[str.substring(0, leftIndex)].length > Number(number) && state[str.substring(0, leftIndex)][number]) {
                result = {
                    result: true, data: state[str.substring(0, leftIndex)][number]
                }
            }
        }
        return result
    }
    const _checkDepth = (state, str = '') => {
        if (str.indexOf('.') >= 0) {
            let array = str.split('.')
            if (state && array[0]) {
                str = str.substring((str.indexOf('.') + 1), str.length)
                const parseArray = _parseArrayData(state, array[0])
                if (parseArray.result) {
                    return parseArray.data ? _checkDepth(parseArray.data, str) : ""
                } else {
                    return _checkDepth(state[array[0]], str)
                }

            } else {
                return ""
            }
        } else {
            const parseArray = _parseArrayData(state, str)
            if (parseArray.result) {
                return parseArray.data ? parseArray.data : ""
            }
            return state && state[str] ? state[str] : ""
        }
    }

    if (depth.indexOf('.') > 0) {
        let depthStr = depth.substring((depth.indexOf('.') + 1), depth.length)
        let check = _checkDepth(object, depthStr)
        return check ? check : defaultPrint
    } else {
        return object ? object : defaultPrint
    }
}