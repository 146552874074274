import _Component from "../../common/_Component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { observable } from "mobx"
import { addComma } from "../../utils/Utils"
import GetValue from "../../utils/GetValue"
import Manager from "../../api/Manager"
import MathHelper from "../../helper/math.helper"

class ManagerSalesScreen extends _Component {
    @observable search = ''
    @observable managers = []
    @observable pagination = {}

    async componentDidMount() {
        try {
            const response = await Manager.my()
            if (response && response.success) {
                this.manageUsersCount = response.manageUsersCount
                this.sales = response.sales
                super.componentDidMount()
            } else {
                alert(response.message)
            }
        } catch (e) {
            console.log(e)
        }
    }

    _render = () => (
        <div className="container-fluid">
            <div className="card mb-4">
                <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />실적</div>
                <div className="table-responsive">
                    <table className="table text-nowrap">
                        <tbody>
                            <tr>
                                <th>관리회원수</th>
                                <td>{addComma(this.manageUsersCount)}</td>
                            </tr>
                            <tr>
                                <th>당월매출</th>
                                <td>{addComma(GetValue(this, "this.sales[0].revenueForThisMonth", 0))}</td>
                            </tr>
                            <tr>
                                <th>전월매출</th>
                                <td>{addComma(GetValue(this, "this.sales[0].revenueForLastMonth", 0))}</td>
                            </tr>
                            <tr>
                                <th>당월결제매출</th>
                                <td>{addComma(GetValue(this, "this.sales[0].salesRevenueForThisMonth", 0))}</td>
                            </tr>
                            <tr>
                                <th>전월결제매출</th>
                                <td>{addComma(GetValue(this, "this.sales[0].salesRevenueForLastMonth", 0))}</td>
                            </tr>
                            <tr>
                                <th>당월결제수</th>
                                <td>{addComma(GetValue(this, "this.sales[0].salesForThisMonth", 0))}</td>
                            </tr>
                            <tr>
                                <th>전월결제수</th>
                                <td>{addComma(GetValue(this, "this.sales[0].salesForLastMonth", 0))}</td>
                            </tr>
                            <tr>
                                <th>당월성사매출</th>
                                <td>{addComma(MathHelper.subtract(GetValue(this, "this.sales[0].revenueForThisMonth", 0), GetValue(this, "this.sales[0].salesRevenueForThisMonth", 0)))}</td>
                            </tr>
                            <tr>
                                <th>전월성사매출</th>
                                <td>{addComma(MathHelper.subtract(GetValue(this, "this.sales[0].revenueForLastMonth", 0), GetValue(this, "this.sales[0].salesRevenueForLastMonth", 0)))}</td>
                            </tr>
                            <tr>
                                <th>당월성사수</th>
                                <td>{addComma(GetValue(this, "this.sales[0].successForThisMonth", 0))}</td>
                            </tr>
                            <tr>
                                <th>전월성사수</th>
                                <td>{addComma(GetValue(this, "this.sales[0].successForLastMonth", 0))}</td>
                            </tr>
                            <tr>
                                <th>당월매치수</th>
                                <td>{addComma(MathHelper.add(GetValue(this, "this.sales[0].successForThisMonth", 0), GetValue(this, "this.sales[0].failForThisMonth", 0)))}</td>
                            </tr>
                            <tr>
                                <th>전월매치수</th>
                                <td>{addComma(MathHelper.add(GetValue(this, "this.sales[0].successForLastMonth", 0), GetValue(this, "this.sales[0].failForLastMonth", 0)))}</td>
                            </tr>
                            <tr>
                                <th>당월실패수</th>
                                <td>{addComma(GetValue(this, "this.sales[0].failForThisMonth", 0))}</td>
                            </tr>
                            <tr>
                                <th>전월실패수</th>
                                <td>{addComma(GetValue(this, "this.sales[0].failForLastMonth", 0))}</td>
                            </tr>
                        </tbody>
                    </table>
            </div>
            </div>
        </div>
    )
}
export default ManagerSalesScreen