import GetValue from "./GetValue"

export const setTrophy = (trophy) => {
    return {
        pet: GetValue(trophy, "trophy.pet.value", "없음"),
        petPost: GetValue(trophy, "trophy.pet.post", false),
        family: GetValue(trophy, "trophy.family.value", "미혼"),
        familyPost: GetValue(trophy, "trophy.family.post", false),
        award: GetValue(trophy, "trophy.award.value", "없음"),
        awardPost: GetValue(trophy, "trophy.award.post", false),
        donation: GetValue(trophy, "trophy.donation.value", "없음"),
        donationPost: GetValue(trophy, "trophy.donation.post", false),
        car: GetValue(trophy, "trophy.car.value", "없음"),
        carPost: GetValue(trophy, "trophy.car.post", false),
        sales: GetValue(trophy, "trophy.sales.value", "없음"),
        salesPost: GetValue(trophy, "trophy.sales.post", false),
        license: GetValue(trophy, "trophy.license.value", "없음"),
        licensePost: GetValue(trophy, "trophy.license.post", false),
        graduation: GetValue(trophy, "trophy.graduation.value", "없음"),
        graduationPost: GetValue(trophy, "trophy.graduation.post", false),
        work: GetValue(trophy, "trophy.work.value", "없음"),
        workPost: GetValue(trophy, "trophy.work.post", false),
        tax: GetValue(trophy, "trophy.tax.value", "없음"),
        taxPost: GetValue(trophy, "trophy.tax.post", false),
        income: GetValue(trophy, "trophy.income.value", "없음"),
        incomePost: GetValue(trophy, "trophy.income.post", false),
        estate: GetValue(trophy, "trophy.estate.value", "없음"),
        estatePost: GetValue(trophy, "trophy.estate.post", false),
    }
}