import moment from "moment"
import GetValue from "./GetValue"

export const getPermission = (permission) => {
    if (permission === "super") return "슈퍼관리자"
    if (permission === "admin") return "일반관리자"
    if (permission === "manager") return "매니저"
    if (permission === "arranger") return "주선자"
}

export const getStatus = (status) => {
    if (status === "wait") return "가입심사"
    if (status === "rejoin") return "재가입"
    if (status === "approval") return "가입완료"
    if (status === "reject") return "가입거절"
}

export const getGrade = (grade) => {
    if (grade === "default") return ""
    if (grade === "gold") return "골드"
    if (grade === "standard") return "스탠다드"
    if (grade === "platinum") return "플래티넘"
    if (grade === "diamond") return "다이아"
}

export const getRecommend = (grade) => {
    if (grade === "recommend") return "추천중"
    if (grade === "success") return "성공"
    if (grade === "fail") return "실패"
}

export const getHeight = (number) => {
    // 1: 155미만, 2: 155-159, 3:160-164, 4:165-169, 5:170-174, 6:175-179, 7:180-184, 8:185-189, 9:190-194, 10:195이상
    let result = ""
    switch (number) {
        case 1 :
            result = "155미만"
            break
        case 2 :
            result = "155-159"
            break
        case 3 :
            result = "160-164"
            break
        case 4 :
            result = "165-169"
            break
        case 5 :
            result = "170-174"
            break
        case 6 :
            result = "175-179"
            break
        case 7 :
            result = "180-184"
            break
        case 8 :
            result = "185-189"
            break
        case 9 :
            result = "190-194"
            break
        case 10 :
            result = "195이상"
            break
        default :
            result = ""
    }
    return result
}

export const getAge = birth => {
    // NOTE: jason_wang: 일본식 나이 계산 로직
    // return Number(moment().format("YYYY")) - Number(moment(birth).format("YYYY")) + 1
    // NOTE: jason_wang: 만 나이 계산 로직
    return moment().diff(birth, 'years')
}

export const getMoment = (timestamp, format = "YYYY-MM-DD HH:mm:ss") => {
    if (timestamp) return moment(timestamp).format(format)
    else return ""
}

export const diffDays = (date) => {
    if (!date) return 0
    else {
        const start = moment(date);
        const end= moment();
        const duration = moment.duration(end.diff(start));
        return  Math.floor(duration.asDays())
    }
}

export const addDash = (phone) => {
    return phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")
}

export const getYears = () => {
    let years = []
    let maxYear = Number(moment().format("YYYY"))
    const minYear = 1900
    while (maxYear >= minYear) {
        years = [...years, maxYear]
        maxYear--
    }
    return years
}

export const getInterestTypeFromRank = (interest = null, rank = 0) => {
    let result = ""
    if (interest && rank && rank > 0) {
        if (Number(GetValue(interest, "interest.face.rank", 0))=== rank) return `${GetValue(interest, "interest.face.value")}(외모)`
        if (Number(GetValue(interest, "interest.income.rank", 0))=== rank) return `${GetValue(interest, "interest.income.value")}(경재력)`
        if (Number(GetValue(interest, "interest.personality.rank", 0))=== rank) return `${GetValue(interest, "interest.personality.value")}(성격)`
        if (Number(GetValue(interest, "interest.values.rank", 0))=== rank) return `${GetValue(interest, "interest.values.value")}(가치관)`
        if (Number(GetValue(interest, "interest.familyBackground.rank", 0))=== rank) return `${GetValue(interest, "interest.familyBackground.value")}(가정환경)`
    }
    return result
}

export const getMatchValueToExpression = (value) => {
    let result = ""
    if (value === "all") result = "모두다"
    if (value === "standard") result = "스탠다드만"
    if (value === "gold") result = "골드이상"
    if (value === "platinum") result = "플래티넘이상"
    if (value === "diamond") result = "다이아만"
    return result
}

export const getMatchRanges = (grade) => {
    let result = []
    if (grade === "standard") result = ["all", "standard"]
    if (grade === "gold") result = ["all", "gold"]
    if (grade === "platinum") result = ["all", "gold", "platinum"]
    if (grade === "diamond") result = ["all", "gold", "platinum", "diamond"]
    return result
}

export const addComma = (input) => {
    const number = typeof input === "number" ?
        input :
        Number(input)
    return number.toLocaleString()
}

export const isId = (id) => {
    return !!id.match(/^[0-9a-fA-F]{24}$/)
}

export const isLineNumber = value => {
    if (!value)
        return false

    return value.match(/^[0-9]{4}$/) ? true : false
}
