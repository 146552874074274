import _Component from '../../common/_Component'
import React from 'react'
import Dashboard from '../../api/Dashboard'
import { observable } from 'mobx'
import { addComma } from '../../utils/Utils'
import { inject } from 'mobx-react'

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class DashboardScreen extends _Component {
    @observable all = 0
    @observable withdraw = 0
    @observable registerForLastMonth = 0
    @observable registerForThisMonth = 0
    @observable waitForApproval = 0
    @observable admin = null
    @observable revenueForLastMonth = 0
    @observable revenueForThisMonth = 0
    @observable salesRevenueForLastMonth = 0
    @observable salesRevenueForThisMonth = 0
    @observable successForLastMonth = 0
    @observable successForThisMonth = 0
    @observable successForToday = 0
    @observable myPermission = ""

    componentDidMount = async () => {
        this.myPermission = this.props.loginStore.permission
        if (!this.myPermission || ('super' !== this.myPermission && 'admin' !== this.myPermission)) {
            alert(`권한이 없습니다: ${this.myPermission}`)
            return
        }

        const api = 'super' === this.myPermission ? Dashboard.get.bind(Dashboard) :
            'admin' === this.myPermission ? Dashboard.getForAdmin.bind(Dashboard) : null
        if (api) {
            const response = await api()
            if (response && response.success) {
                this.all = response.all
                this.withdraw = response.withdraw
                this.registerForLastMonth = response.registerForLastMonth
                this.registerForThisMonth = response.registerForThisMonth
                this.waitForApproval = response.waitForApproval
                this.revenueForLastMonth = response.revenueForLastMonth
                this.revenueForThisMonth = response.revenueForThisMonth
                this.salesRevenueForLastMonth = response.salesRevenueForLastMonth
                this.salesRevenueForThisMonth = response.salesRevenueForThisMonth
                this.successForLastMonth = response.successForLastMonth
                this.successForThisMonth = response.successForThisMonth
                this.successForToday = response.successForToday
                super.componentDidMount()
            } else {
                alert(response.message)
            }
        } else {
            alert(`API 를 찾을 수 없습니다: ${this.myPermission}`)
        }
    }

    _render = () => {
        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md'>
                        <div className='card bg-primary text-white'>
                            <div className='card-body'>
                                <h6>누적회원</h6>
                                <h1>{addComma(this.all)}</h1>
                            </div>
                        </div>
                    </div>
                    <div className='col-md'>
                        <div className='card bg-light'>
                            <div className='card-body'>
                                <h6>탈퇴회원</h6>
                                <h1>{addComma(this.withdraw)}</h1>
                            </div>
                        </div>
                    </div>
                    <div className='col-md'>
                        <div className='card bg-success text-white'>
                            <div className='card-body'>
                                <h6>전월가입자</h6>
                                <h1>{addComma(this.registerForLastMonth)}</h1>
                            </div>
                        </div>
                    </div>
                    <div className='col-md'>
                        <div className='card bg-warning text-white'>
                            <div className='card-body'>
                                <h6>당월가입자</h6>
                                <h1>{addComma(this.registerForThisMonth)}</h1>
                            </div>
                        </div>
                    </div>
                    {
                        'super' === this.myPermission &&
                        (<div className='col-md'>
                            <div className='card bg-info text-white'>
                                <div className='card-body'>
                                    <h6>승인대기자</h6>
                                    <h1>{addComma(this.waitForApproval)}</h1>
                                </div>
                            </div>
                        </div>)
                    }
                    <div className='table-responsive' style={{ padding: 10, marginTop: 50 }}>
                        <table className='table  table-bordered'>
                            <thead>
                                <tr>
                                    <th scope='col'>전월총매출</th>
                                    <th scope='col'>당월총매출</th>
                                    <th scope='col'>전월 결제 매출</th>
                                    <th scope='col'>당월 결제 매출</th>
                                    <th scope='col'>전월 총 성사</th>
                                    <th scope='col'>당월 현재 총성사</th>
                                    <th scope='col'>오늘 총성사</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{addComma(this.revenueForLastMonth)}</td>
                                    <td>{addComma(this.revenueForThisMonth)}</td>
                                    <td>{addComma(this.salesRevenueForLastMonth)}</td>
                                    <td>{addComma(this.salesRevenueForThisMonth)}</td>
                                    <td>{addComma(this.successForLastMonth)}</td>
                                    <td>{addComma(this.successForThisMonth)}</td>
                                    <td>{addComma(this.successForToday)}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default DashboardScreen