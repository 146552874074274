import React from 'react'
import ReactDOM from 'react-dom'
import moment from "moment"
import GetValue from "./GetValue"

const ShowType = {
    getJSXElementByUser: user => {
        const showType = Number(GetValue(user, "user._match[0].showType", "0"))
        return ShowType.toJSXElement(showType)
    },

    getJSXElementByMatch: match => {
        const showType = Number(GetValue(match, "match.showType", "0"))
        return ShowType.toJSXElement(showType)
    },

    toJSXElement: showType => {
        switch (showType) {
            case -1:
            case 2:
                return <span className="badge badge-pill badge-danger">특별소개</span>

            case 1:
                return <span className="badge badge-pill badge-danger">더보여줘</span>

            case 0:
            default:
                return <span className="badge badge-pill badge-danger">소개해줘</span>
        }
    },
}

export default ShowType