import _Component from "../../common/_Component"
import React from "react"
import Config from "../../config/config"
import UserShowLayoutMulti from "../../layouts/UserShowLayoutMulti"
import { action, observable } from "mobx"
import {
    addDash,
    getAge,
    getHeight,
    getGrade,
    getInterestTypeFromRank,
    getMoment,
    getRecommend,
    getStatus,
    isId,
    isLineNumber,
} from "../../utils/Utils"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Delete, Visibility, Sort } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"
import MatchModal from "../../components/MatchModal"
import Pagination from "../../components/Pagination"
import GetValue from "../../utils/GetValue"
import Admin from "../../api/Admin"
import User from "../../api/User"
import Match from "../../api/Match"
import Area from "../../api/Area"
import Recommend from "../../api/Recommend"
import Ban from "../../api/Ban"
import { Card } from "react-bootstrap"
import { getProfileUrl } from "../../utils/ImageUtil"
import { inject } from "mobx-react"
import AvailableSuccess from "../../utils/AvailableSuccess"
import ShowType from "../../utils/ShowType"
import ShownStatus from "../../utils/ShownStatus"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class UserRequestShowScreen extends _Component {
    @observable user = null
    @observable users = []
    @observable usersPagination = {}
    @observable recommends = []
    @observable recommendPagination = {}
    @observable managers = []
    @observable match = null
    @observable _userId = ""
    @observable _matchId = ""
    @observable name = ""
    @observable manager = ""
    @observable familyLevel = ""
    @observable abilityLevel = ""
    @observable personalityLevel = ""
    @observable ageLevel = ""
    @observable grade = ""
    @observable interest = ""
    @observable user2 = null
    @observable modal = false
    @observable areas = []
    @observable recommendLimit = Config.defaultLimit
    @observable modalMode = "request"
    @observable sort = ""
    @observable sortType = ""
    @observable myPermission = ""

    async componentDidMount() {
        this.myPermission = this.props.loginStore.permission
        this._userId = GetValue(this, "this.props.match.params.id")
        this._matchId = GetValue(this, "this.props.match.params.matchId")
        if (!this._userId || !isId(this._userId) || !this._matchId || !isId(this._matchId)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
            return true
        }
        try {
            await this._getAreas()
            await this._getUser()
            await this._getMatch()
            await this._getManagers()
            await this._getUsers()
            await this._getRecommends()
            super.componentDidMount()
        } catch (e) {
            console.log(e)
        }
    }

    @action
    _getAreas = async () => {
        try {
            let response = await Area.get()
            if (response && response.success) {
                this.areas = response.areas
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _getUser = async () => {
        try {
            let response = await User.show(this._userId)
            if (response && response.success) {
                this.user = response.user
                this.user.trophy = response.trophy
                this.user.interest = response.interest
                this.responseMatchCount = response.responseMatchCount
                this.isNewMatch = response.isNewMatch
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _getUsers = async (page = 1) => {
        try {
            const lineNumber = isLineNumber(this.name)
            const params = {
                page: page,
                limit: this.limit,
                name: lineNumber ? null : this.name,
                lineNumber: lineNumber ? this.name : null,
                _manager: this.manager,
                familyLevel: this.familyLevel,
                abilityLevel: this.abilityLevel,
                ageLevel: this.ageLevel,
                personalityLevel: this.personalityLevel,
                grade: this.grade,
                interest: this.interest,
                _match: this._matchId,
                sort: this.sort,
                sortType: this.sortType
            }
            let response = await User.recommend(this._userId, params)
            if (response && response.success) {
                this.users = response.users
                this.usersPagination = response.pagination
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _getRecommends = async (page = 1) => {
        try {
            const params = {
                page: page,
                limit: this.recommendLimit,
                _match: this._matchId,
                _request: this.user._id
            }
            let response = await Recommend.get(params)
            if (response && response.success) {
                this.recommends = response.recommends
                this.recommendPagination = response.pagination
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _getMatch = async () => {
        try {
            const response = await Match.show(this._matchId)
            if (response && response.success) {
                this.match = response.matches[0]
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _getManagers = async () => {
        try {
            const response = await Admin.get({ type: "manager" })
            if (response && response.success) {
                this.managers = response.admins.sort(function(a, b) {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                });
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _search = async (e) => {
        e.preventDefault()
        await this._getUsers()
    }

    _showModal = async (e, userId, modalMode) => {
        if (!userId) {
            alert("유저ID 를 찾을 수 없는 회원입니다");
            return;
        }
        
        this.modalMode = modalMode
        e.preventDefault()
        try {
            const response = await User.show(userId)
            if (response && response.success) {
                this.user2 = response.user
                this.user2.trophy = response.trophy
                this.user2.interest = response.interest
                this.handleModal()
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    handleModal = (show = true) => {
        this.modal = show
        if (show === false) {
            this.user2 = null
        }
    }

    _changeLimit = async (e, type = "users") => {
        e.preventDefault()
        if (type === "users") {
            this.limit = e.target.value
            await this._getUsers(1)
        } else {
            this.recommendLimit = e.target.value
            await this._getRecommends(1)
        }
    }

    _saveRecommends = async (e) => {
        e.preventDefault()
        let _responses = []
        let rates = []
        let result = true
        this.users.forEach((value) => {
            if (value.checked) {
                _responses = [..._responses, value._id]
                if (!value.rate || value.rate < 1) {
                    alert("매칭확률이 입력되지 않은 회원이 존재합니다.")
                    result = false
                }
                rates = [...rates, value.rate]
            }
        })

        if (!result) return false

        if (_responses.length < 1) {
            alert("추천하실 회원이 선택되지 않았습니다.")
            return true
        }

        const params = {
            _match: this._matchId,
            _request: this._userId,
            _responses: _responses,
            rates: rates
        }
        try {
            const response = await Recommend.saveMany(params)
            if (response && response.success) {
                alert("추천이 완료되었습니다.")
                this._refresh(e)
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _updateRequest = async (e) => {
        e.preventDefault()
        try {
            const response = await Match.update(this._matchId, { status: "request" })
            if (response && response.success) {
                alert("해당 회원에게 매칭요청을 보냈습니다")
                this._refresh(e)
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert('?')
            alert(e.toString())
        }
    }

    @action
    _setRates = (e, index) => {
        this.users[index].rate = e.target.value
    }

    @action
    _setSort = async (target, type) => {
        this.sort = target
        this.sortType = type
        await this._getUsers()
    }

    @action
    _checkedUser = async (index, checkedUser, value) => {
        if (value === true) {
            // NOTE: jason_wang: 상대가 나를 차단했는지 확인
            const resBan = await Ban.get(checkedUser)
            if (resBan.success && resBan.banList) {
                const name = this.user.name
                const nameSliced = 2 < name.length ? name.slice(-2) : name

                const phone = this.user.phone
                const phoneSliced = 4 < phone.length ? phone.slice(-4) : phone

                if (resBan.banList.find(item => nameSliced === item.name && phoneSliced === item.phone)) {
                    alert('상대회원의 차단리스트 조회결과 요청회원을 차단중입니다')
                    return
                }
            }

            let params = {
                userId: this.user._id,
                resUserId: checkedUser
            }

            const resCheck = await Match.check(params)
            if (resCheck.success) {
                if ( resCheck.match.length > 0 || resCheck.recommend.length > 0 ) {
                    const confirm = window.confirm("이전 매치기록이 있습니다. 그래도 진행하시겠습니까?")
                    if(confirm) {
                        this.users[index].checked = value
                    }
                } else {
                    this.users[index].checked = value
                }
            } else {
                alert('매치기록을 조회 할 수 없습니다.')
            }
        } else {
            this.users[index].checked = value
        }
    }

    _renderMatch = () => (
        <div className="card mb-4">
            <div className="card-header">
                <FontAwesomeIcon icon={faTable} className="fas mr-1" />소개/이어줘 정보
            </div>
            <div className="table-responsive">
                <table className="table text-nowrap">
                    <thead>
                    <tr>
                        <th scope="col">요청</th>
                        <th scope="col">소개요청일</th>
                        <th scope="col">소개추천일</th>
                        <th scope="col">수정일</th>
                        <th scope="col">ID</th>
                        {
                            (this.match.status === "show") &&
                            <th scope="col">상태변경</th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {
                                    this.match.status === "show" &&
                                    ShowType.getJSXElementByMatch(this.match)
                                }
                                {
                                    this.match.status === "request" &&
                                    ShownStatus.getJSXElementByMatch(this.match)
                                }
                                {
                                    this.match.status === "response" && this.match._request._user && this.match._request._user._id === this.user._id && 
                                    <span className="badge badge-pill badge-success">이어줘</span>
                                }
                                {
                                    this.match.status === "response" && this.match._response._user && this.match._response._user._id === this.user._id && this.matchs._response.answer === null &&
                                    <span className="badge badge-pill badge-success">이어줘받음</span>
                                }
                                {
                                    this.match.status === "wait" &&
                                    <span className="badge badge-pill badge-info">성사대기</span>
                                }
                                {
                                    this.match.status === "success" &&
                                    <span className="badge badge-pill badge-primary">매칭성사</span>
                                }
                                {
                                    this.match.status === "fail" &&
                                    <span className="badge badge-pill badge-secondary">매칭실패</span>
                                }
                            </td>
                            <td>{getMoment(this.match.createdAt)}</td>
                            {
                                GetValue(this, "this.match._request.answer") ?
                                    <td>{getMoment(GetValue(this, "this.match._request.answer"))}</td>
                                    :
                                    <td>응답대기</td>
                            }
                            <td>{getMoment(this.match.updatedAt)}</td>
                            <td>{this.match._id}</td>
                            {
                                (this.match.status === "show") &&
                                <td><button type="button" className="btn btn-success" style={{ marginRight: 5 }} onClick={this._updateRequest}>매칭요청</button></td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )

    _renderChooseProfile = () => {
        const match = this.match
        const response = match ? match.response : null
        const user = response ? response._user : null
        return (
            <div className="card mb-4">
                <div className="card-header">
                    <FontAwesomeIcon icon={faTable} className="fas mr-1" />선택된 회원 정보
                </div>
                <div className="table-responsive">
                    {
                        user ?
                        <table className="table text-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">Act</th>
                                    <th scope="col"></th>
                                    <th scope="col">이름</th>
                                    {/* <th scope="col">연락처</th> */}
                                    <th scope="col">등급</th>
                                    <th scope="col">상대매니저</th>
                                    <th scope="col">상대응답</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <IconButton
                                            className="text-info fas"
                                            aria-label="visibility"
                                            style={{ padding: 0, outline: "none" }}
                                            onClick={e => this._showModal(e, user._id, match.status)}// TODO: jason_wang: 여기서 다 할수 있는게 맞는가?
                                        >
                                            <Visibility />
                                        </IconButton>
                                    </td>
                                    <td className="text-right">
                                        <img className="align-self-center mr-3" style={{
                                            width: 32,
                                            height: 32,
                                            borderRadius: 16
                                        }} src={getProfileUrl(GetValue(user, "user._photos._main"))} alt="profile" />
                                    </td>
                                    <td>
                                        {GetValue(user, "user.name")}
                                    </td>
                                    {/* <td>{GetValue(user, "user.phone")}</td> */}
                                    <td>{getGrade(GetValue(user, "user.grade"))}</td>

                                    <td>{GetValue(response, "response._manager.name", "매니저없음")}</td>
                                    <td>{getMoment(GetValue(response, "response.answer"))}</td>
                                </tr>
                            </tbody>
                        </table>
                        :
                        <div className="text-center" style={{ marginTop: 20, marginBottom: 20 }}>
                            <Card.Title className="text-center">선택된 회원 정보가 존재하지 않습니다</Card.Title>
                        </div>
                    }
                </div>
            </div>
        )
    }

    _renderRecommend = () => (
        <div className="card mb-4">
            <div className="card-header">
                <FontAwesomeIcon icon={faTable} className="fas mr-1" />추천한 회원 정보
            </div>
            <div className={"card-body"}>
                {
                    this.myPermission === "super" &&
                    <div className="d-flex mr-auto">
                        <div className="input-group">
                            <button className="btn btn-outline-secondary"
                                type="button"
                                onClick={e => this._delete(e, "recommend", this._getRecommends.bind(this), this.recommendPagination.currentPage, this.recommends)}
                                style={{ marginBottom: 10 }}>선택삭제
                            </button>
                        </div>
                    </div>
                }
                <div className="table-responsive">
                    <table className="table text-nowrap">
                        <thead>
                        <tr>
                            <th scope="col">
                                <input type="checkbox" onChange={e => this._checkAll(e, this.recommends)} />
                            </th>
                            <th scope="col">Act</th>
                            <th scope="col">상태</th>
                            <th scope="col">매칭확률</th>
                            <th scope="col">이름</th>
                            <th scope="col">만나이</th>
                            {/* <th scope="col">연락처</th> */}
                            <th scope="col">담당매니저</th>
                            <th scope="col">추천시간</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.recommends && this.recommends.length > 0 && this.recommends.map((value, index) => (
                                <tr key={index}>
                                    <td>
                                        <input type="checkbox" checked={!!value.checked} onChange={e => this.recommends[index].checked = e.target.checked} />
                                    </td>
                                    <td>
                                        {GetValue(value, "value._response._id") &&
                                        <IconButton
                                            className="text-info fas"
                                            aria-label="visibility"
                                            style={{ padding: 0, outline: "none" }}
                                            onClick={e => this._showModal(e, value._response ? value._response._id : null, "recommend")}
                                        >
                                            <Visibility />
                                            </IconButton>
                                        }
                                        {
                                            (this.myPermission === "super" || this.match.status === "show")&&
                                            <IconButton
                                                color={"secondary"}
                                                aria-label="delete"
                                                style={{ padding: 0, outline: "none" }}
                                                onClick={e => this._delete(e, `recommend/${value._id}`, this._getRecommends.bind(this), this.recommendPagination)}
                                            >
                                            <Delete />
                                            </IconButton>
                                        }
                                        
                                    </td>
                                    <td>{getRecommend(value.status)}</td>
                                    <td>{value.rate}%</td>
                                    <td>{GetValue(value, "value._response.name", "데이터없음")}</td>
                                    {/* <td>{GetValue(value, "value._response.email")}</td> */}
                                    <td>{getAge(value._response ? value._response.birth : "1990-01-01")}</td>
                                    {/* <td>{addDash(GetValue(value, "value._response.phone"))}</td> */}
                                    <td>{GetValue(value, "value._response._manager.name", "매니저없음")}</td>
                                    <td>{getMoment(value.createdAt)}</td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                </div>
                 <div className="d-flex">
                    <div className="d-flex mr-auto">
                        <div>
                            <select className="form-control form-control-sm" value={this.recommendLimit} onChange={e => this._changeLimit(e, "recommends")}>
                                    <option value={"10"}>10</option>
                                    <option value={"20"}>20</option>
                                    <option value={"30"}>30</option>
                                    <option value={"40"}>40</option>
                                    <option value={"50"}>50</option>
                              </select>
                        </div>
                    </div>
                    <div>
                        <nav aria-label="Page navigation example">
                                <Pagination handle={this._getRecommends.bind(this)} pagination={this.recommendPagination} />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )

    _renderUser = () => (
        <div className="card mb-4">
            <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />후보선택</div>
            <div className="card-body">
                <div className="d-md-flex">
                    <div className="form-group mr-2">
                        <label htmlFor="search">회원검색</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="회원이름 또는 뒷번호" value={this.name} onChange={e => this.name = e.target.value} />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary" type="button" onClick={this._search}>검색</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">매니저</label>
                        <select className="form-control" id="filter_status" value={this.manager} onChange={e => this.manager = e.target.value}>
                            <option value="">전체</option>
                            {this.managers.map((value, index) =>
                                <option key={index} value={value._id}>{value.name}</option>)}
                        </select>
                    </div>
                </div>
                <div className="d-md-flex mb-3">
                    <div className="form-group mr-2">
                        <label htmlFor="search">나이</label>
                        <select className="form-control" id="filter_status" value={this.ageLevel} onChange={e => this.ageLevel = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"A"}>20대</option>
                            <option value={"B"}>30대</option>
                            <option value={"C"}>40대이상</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">가</label>
                        <select className="form-control" id="filter_status" value={this.familyLevel} onChange={e => this.familyLevel = e.target.value}>
                            <option value="">전체</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">능</label>
                        <select className="form-control" id="filter_status" value={this.abilityLevel} onChange={e => this.abilityLevel = e.target.value}>
                            <option value="">전체</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">성</label>
                        <select className="form-control" id="filter_status" value={this.personalityLevel} onChange={e => this.personalityLevel = e.target.value}>
                            <option value="">전체</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">등급</label>
                        <select className="form-control" id="filter_status" value={this.grade} onChange={e => this.grade = e.target.value}>
                            <option value="">전체</option>
                            <option value={"gold"}>골드 이상</option>
                            <option value={"platinum"}>플래티넘 이상</option>
                            <option value={"diamond"}>다이아 이상</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">1순위 이상형</label>
                        <select className="form-control" id="filter_status" value={this.interest} onChange={e => this.interest = e.target.value}>
                            <option value={"all"}>전체</option>
                            <option value={"face"}>외모</option>
                            <option value={"income"}>경제력</option>
                            <option value={"personality"}>성격</option>
                            <option value={"values"}>가치관</option>
                            <option value={"familyBackground"}>가정환경</option>형
                        </select>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table text-nowrap">
                        <thead>
                        <tr>
                            <th scope="col">
                                <input type="checkbox" onChange={e => this._checkAll(e, this.users)} />
                            </th>
                            <th scope="col">Atc.</th>
                            <th scope="col">매칭확률</th>
                            {/* <th scope="col">이메일</th> */}
                            <th scope="col">
                                이름
                                <IconButton
                                    color={"success"}
                                    aria-label="name"
                                    style={{ padding: 0, outline: "none" }}
                                    onClick={() => this._setSort('name', !this.sortType || this.sortType < 0 ? 1 : -1 )}
                                >
                                    <Sort />
                                </IconButton>
                            </th>
                            <th scope="col">등급</th>
                            <th scope="col">
                                만나이
                                <IconButton
                                    color={"success"}
                                    aria-label="name"
                                    style={{ padding: 0, outline: "none" }}
                                    onClick={() => this._setSort('birth', !this.sortType || this.sortType < 0 ? 1 : -1 )}
                                >
                                    <Sort />
                                </IconButton>
                            </th>
                            <th scope="col">
                                키
                                <IconButton
                                    color={"success"}
                                    aria-label="name"
                                    style={{ padding: 0, outline: "none" }}
                                    onClick={() => this._setSort('height', !this.sortType || this.sortType < 0 ? 1 : -1 )}
                                >
                                    <Sort />
                                </IconButton>
                            </th>
                            <th scope="col">성별</th>
                            <th scope="col">체형</th>
                            <th scope="col">미혼여부</th>
                            <th scope="col">직업</th>
                            <th scope="col">거주지</th>
                            <th scope="col">
                                매니저
                                <IconButton
                                    color={"success"}
                                    aria-label="name"
                                    style={{ padding: 0, outline: "none" }}
                                    onClick={() => this._setSort('_manager.name', !this.sortType || this.sortType < 0 ? 1 : -1)}
                                >
                                    <Sort />
                                </IconButton>
                            </th>
                            <th scope="col">가</th>
                            <th scope="col">능</th>
                            <th scope="col">성</th>
                            <th scope="col">1순위</th>
                            <th scope="col">2순위</th>
                            <th scope="col">3순위</th>
                            <th scope="col">관심나이대</th>
                            <th scope="col">관심직업군</th>
                            {/* <th scope="col">연락처</th> */}
                            <th scope="col">등록일</th>
                            <th scope="col">수정일</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.users && this.users.length > 0 && this.users.map((value, index) => (
                                <tr key={index}>
                                    <td>
                                        {/* <input type="checkbox" checked={!!value.checked} onChange={e => this.users[index].checked = e.target.checked} /> */}
                                        <input type="checkbox" checked={!!value.checked} onChange={e => this._checkedUser(index, value._id, e.target.checked)} />
                                    </td>
                                    <td>
                                            <IconButton
                                                className="text-info fas"
                                                aria-label="visibility"
                                                style={{ padding: 0, outline: "none" }}
                                                onClick={e => this._showModal(e, value._id, "request")}
                                            >
                                              <Visibility />
                                            </IconButton>
                                    </td>
                                    <td>
                                        <div className="input-group input-group-sm" style={{ width: 100 }}>
                                            <input type="number" className="form-control form-control" onChange={e => this._setRates(e, index)} />
                                            <div className="input-group-append">
                                              <span className="input-group-text" id="basic-addon2">%</span>
                                            </div>
                                        </div>
                                    </td>
                                    {/* <td>{value.email}</td> */}
                                    <td>{value.name}</td>
                                    <td>{getGrade(value.grade)}</td>
                                    <td>{getAge(value.birth)}</td>
                                    <td>{getHeight(value.height.value)}</td>
                                    <td>{value.gender === "male" ? "남" : "여"}</td>
                                    <td>{value.body.value}</td>
                                    <td>{value.married}</td>
                                    <td>{GetValue(value, "value.job.value")}</td>
                                    <td>{GetValue(value, "value._area.city") ? `${GetValue(value, "value._area.city")} ${GetValue(value, "value._area.name")}` : GetValue(value, "value._area.name")}</td>
                                    <td>{GetValue(value, "value._manager.name", "매니저없음")}</td>
                                    <td>{value.familyLevel}</td>
                                    <td>{value.abilityLevel}</td>
                                    <td>{value.personalityLevel}</td>
                                    <td>{getInterestTypeFromRank(GetValue(value, "value._interest", null), 1)}</td>
                                    <td>{getInterestTypeFromRank(GetValue(value, "value._interest", null), 2)}</td>
                                    <td>{getInterestTypeFromRank(GetValue(value, "value._interest", null), 3)}</td>
                                    <td>{GetValue(value, "value._interest.age")}</td>
                                    <td>{GetValue(value, "value._interest.job")}</td>
                                    {/* <td>{addDash(value.phone)}</td> */}
                                    <td>{getMoment(value.createdAt)}</td>
                                    <td>{getMoment(value.updatedAt)}</td>
                                </tr>
                                )
                            )
                        }
                        </tbody>
                      </table>
                </div>
                <div className="d-flex">
                    <div className="d-flex mr-auto">
                        <div>
                            <select className="form-control form-control-sm" value={this.limit} onChange={e => this._changeLimit(e, "users")}>
                                    <option value={"10"}>10</option>
                                    <option value={"20"}>20</option>
                                    <option value={"30"}>30</option>
                                    <option value={"40"}>40</option>
                                    <option value={"50"}>50</option>
                              </select>
                        </div>
                    </div>
                    <div>
                        <nav aria-label="Page navigation example">
                                <Pagination handle={this._getUsers.bind(this)} pagination={this.usersPagination} />
                        </nav>
                    </div>
                </div>
                <div>
                    <button type="button" className="btn btn-primary" style={{ marginRight: 5 }} onClick={this._saveRecommends}>완료</button>
                    <button type="button" className="btn btn-secondary" onClick={this._refresh}>취소</button>
                </div>
            </div>
        </div>
    )

    _render = () => (
        <UserShowLayoutMulti user={this.user} path={this.path} isNewMatch={this.isNewMatch} responseMatchCount={this.responseMatchCount} permission={this.myPermission}>
            {
                this.modal &&
                <MatchModal
                    areas={this.areas}
                    modal={this.modal}
                    user1={this.user}
                    user2={this.user2}
                    handle={this.handleModal.bind(this)}
                    type={this.modalMode}
                    _matchId={this._matchId}
                    _getRecommends={this._getRecommends.bind(this)}
                />
            }
            <div className="col-md-9">
                {this._renderMatch()}
                {
                    this.match.status === "show" || this.match.status === "request" ?
                        <>
                        {this._renderRecommend()}
                            {this.match.status !== "request" && this._renderUser()}
                        </>
                        :
                        <>
                            {this._renderChooseProfile()}
                            {this._renderRecommend()}
                        </>
                }

            </div>
        </UserShowLayoutMulti>
    )
}

export default UserRequestShowScreen