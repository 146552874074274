import _Component from "../../common/_Component"
import React from "react"
import UserShowLayout from "../../layouts/UserShowLayout"
import { action, observable } from "mobx"
import GetValue from "../../utils/GetValue"
import { getYears, isId } from "../../utils/Utils"
import { setUser } from "../../utils/UserUtil"
import User from "../../api/User"
import Config from "../../config/config"
import Area from "../../api/Area"
import { inject } from "mobx-react"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class UserProfileScreen extends _Component {
    @observable years = []
    @observable _mainPhoto = null
    @observable _privateRPhoto = []
    @observable _hobbyLifePhoto = []
    @observable user = null
    @observable areas = []
    @observable _user = null
    @observable myPermission = ""

    async componentDidMount() {
        this.myPermission = this.props.loginStore.permission

        const _id = GetValue(this, "this.props.match.params.id")
        if (!_id || !isId(_id)) {
            alert("잘못된 접근입니다.")
            this.props.history.goBack()
            return true
        }
        try {
            let response = await User.show(_id)
            if (response && response.success) {
                this.responseMatchCount = response.responseMatchCount
                this.isNewMatch = response.isNewMatch
                this.user = setUser(response.user)
                this._user = response.user
                this.user._id = GetValue(response, "response.user._id")
                this._mainPhoto = GetValue(this, "this.user._main")
                this._privateRPhoto = GetValue(this, "this.user._privateR")
                this._hobbyLifePhoto = GetValue(this, "this.user._hobbyLife")
            } else {
                alert(response.message)
                return true
            }
            response = await Area.get()
            if (response && response.success) {
                this.areas = response.areas
            } else {
                alert("지역 정보를 불러오는데 실패하였습니다. 다시 시도해주시기 바랍니다.")
                return true
            }
            this.years = getYears()
            super.componentDidMount()
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _update = async (e) => {
        e.preventDefault()
        this.user._main = this._mainPhoto
        this.user._privateR = this._privateRPhoto
        this.user._hobbyLife = this._hobbyLifePhoto
        try {
            let response = await User.updateAll(this.user._id, this.user)
            if (response && response.success) {
                alert("수정이 완료되었습니다.")
                return true
                //this.props.history.push("/user")
            } else {
                alert(response.message)
                return true
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    onChangePersonality = (e) => {
        const value = e.target.dataset.value
        if (e.target.checked) {
            this.user.personality = [...this.user.personality, value]
        } else {
            this.user.personality = this.user.personality.filter(item => item !== value)
        }
    }

    @action
    onChnageAdventage = (e) => {
        const value = e.target.dataset.value
        if (e.target.checked) {
            this.user.advantage = [...this.user.advantage, value]
        } else {
            this.user.advantage = this.user.advantage.filter(item => item !== value)
        }
    }

    @action
    onChnageIdealType = (e) => {
        const value = e.target.dataset.value
        if (e.target.checked) {
            this.user.idealType = [...this.user.idealType, value]
        } else {
            this.user.idealType = this.user.idealType.filter(item => item !== value)
        }
    }

    @action
    onChnageWant = (e) => {
        const value = e.target.dataset.value
        if (e.target.checked) {
            this.user.want = [...this.user.want, value]
        } else {
            this.user.want = this.user.want.filter(item => item !== value)
        }
    }

    _render = () => {
        return (
            <UserShowLayout user={this._user} title={"프로필"} path={this.path} isNewMatch={this.isNewMatch} responseMatchCount={this.responseMatchCount} permission={this.myPermission}>
                <form>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">이름</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" value={this.user.name} onChange={e => this.user.name = e.target.value} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">등급</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.grade} onChange={e => this.user.grade = e.target.value}>
                                <option value="standard">스탠다드</option>
                                <option value="gold">골드</option>
                                <option value="platinum">플래티넘</option>
                                <option value="diamond">다이아</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">출생년도</label>
                        <div className="col-sm-10">
                            <input type={"date"} data-date-format={"YYYY-MM-DD"} className={"form-control"} value={this.user.birth} onChange={e => this.user.birth = e.target.value} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">성별</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.gender} onChange={e => this.user.gender = e.target.value}>
                                <option value="male">남</option>
                                <option value="female">여</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">지역</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user._area} onChange={e => this.user._area = e.target.value}>
                                <option value="">지역을 선택해 주세요</option>
                                {
                                    this.areas.map(value =>
                                        <option key={value._id} value={value._id}>{`${value.city} ${value.name}`}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">종교</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.religion} onChange={e => this.user.religion = e.target.value}>
                                <option value="기독교">기독교</option>
                                <option value="불교">불교</option>
                                <option value="천주교">천주교</option>
                                <option value="무교">무교</option>
                                <option value="기타">기타</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">결혼</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.married} onChange={e => this.user.married = e.target.value}>
                                <option value="미혼">미혼</option>
                                <option value="돌싱">돌싱</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">흡연</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.smoking} onChange={e => this.user.smoking = e.target.value}>
                                <option value="흡연">흡연</option>
                                <option value="비흡연">비흡연</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">음주</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.drinking} onChange={e => this.user.drinking = e.target.value}>
                                <option value="즐겨함">즐겨함</option>
                                <option value="가끔">가끔</option>
                                <option value="안함">안함</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">타투</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.tattoo} onChange={e => this.user.tattoo = e.target.value}>
                                <option value="" disabled={true}>선택</option>
                                <option value="크고화려함">크고화려함</option>
                                <option value="손바닥정도">손바닥정도</option>
                                <option value="포인트살짝">포인트살짝</option>
                                <option value="안함">안함</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">키</label>
                        <div className="col-sm-8">
                            <select className="form-control" value={this.user.height} onChange={e => this.user.height = e.target.value}>
                                <option value="1">155미만</option>
                                <option value="2">155~159</option>
                                <option value="3">160~164</option>
                                <option value="4">165~169</option>
                                <option value="5">170~174</option>
                                <option value="6">175~179</option>
                                <option value="7">180~184</option>
                                <option value="8">185~189</option>
                                <option value="9">190~194</option>
                                <option value="10">195이상</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" checked={this.user.heightVisible} onChange={e => this.user.heightVisible = e.target.checked} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">체형</label>
                        <div className="col-sm-8">
                            <select className="form-control" value={this.user.body} onChange={e => this.user.body = e.target.value}>
                                <option value="마른">마른</option>
                                <option value="보통">보통</option>
                                <option value="통통">통통</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" checked={this.user.bodyVisible} onChange={e => this.user.bodyVisible = e.target.checked} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">성격</label>
                        {/* <div className="col-sm-8">
                        <select className="form-control" value={this.user.personality} onChange={e => this.user.personality = e.target.value}>
                            <option value="외향적인">외향적인</option>
                            <option value="내향적인">내향적인</option>
                        </select>
                    </div> */}
                        <div className="col-sm-8">
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="낙천적인" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '낙천적인')} /> 낙천적인
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="도발적인" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '도발적인')} /> 도발적인
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="털털한" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '털털한')} /> 털털한
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="단순한" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '단순한')} /> 단순한
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="정직한" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '정직한')} /> 정직한
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="친절한" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '친절한')} /> 친절한
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="주도적인" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '주도적인')} /> 주도적인
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="도도한" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '도도한')} /> 도도한
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="애교있는" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '애교있는')} /> 애교있는
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="경청하는" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '경청하는')} /> 경청하는
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="중간입장" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '중간입장')} /> 중간입장
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="조용한" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '조용한')} /> 조용한
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="호기심 많은" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '호기심 많은')} /> 호기심 많은
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="리액션한" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '리액션한')} /> 리액션한
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="꼼꼼한" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '꼼꼼한')} /> 꼼꼼한
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="서포터즈" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '서포터즈')} /> 서포터즈
                                </span>

                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="플렉스한" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '플렉스한')} /> 플렉스한
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="personality" data-value="끈기있는" onChange={this.onChangePersonality} checked={this.user.personality.some(item => item === '끈기있는')} /> 끈기있는
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" checked={this.user.personalityVisible} onChange={e => this.user.personalityVisible = e.target.checked} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">최종학력</label>
                        <div className="col-sm-8">
                            <select className="form-control" value={this.user.education} onChange={e => this.user.education = e.target.value}>
                                <option value="고등학교졸">고등학교졸</option>
                                <option value="2년제졸">2년제졸</option>
                                <option value="4년제졸">4년제졸</option>
                                <option value="대학원졸">대학원졸</option>
                                <option value="대학(원)재학">대학(원)재학</option>
                                <option value="해외대학졸">해외대학졸</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" checked={this.user.educationVisible} onChange={e => this.user.educationVisible = e.target.checked} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">직업</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" value={this.user.job} onChange={e => this.user.job = e.target.value} />
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" checked={this.user.jobVisible} onChange={e => this.user.jobVisible = e.target.checked} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">4대보험</label>
                        <div className="col-sm-8">
                            <select className="form-control" value={this.user.insurance} onChange={e => this.user.insurance = e.target.value}>
                                <option value="직장가입자">직장가입자</option>
                                <option value="지역가입자">지역가입자</option>
                                <option value="미가입자">미가입자</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" checked={this.user.insuranceVisible} onChange={e => this.user.insuranceVisible = e.target.checked} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">작년소득</label>
                        <div className="col-sm-8">
                            <select className="form-control" value={this.user.income} onChange={e => this.user.income = e.target.value}>
                                <option value="1">0~2000</option>
                                <option value="2">2000~4000</option>
                                <option value="3">4000~6000</option>
                                <option value="4">6000~8000</option>
                                <option value="5">8000~1억</option>
                                <option value="6">1억 이상</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" checked={this.user.incomeVisible} onChange={e => this.user.incomeVisible = e.target.checked} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">차량소유</label>
                        <div className="col-sm-8">
                            <select className="form-control" value={this.user.car} onChange={e => this.user.car = e.target.value}>
                                <option value={true}>있음</option>
                                <option value={false}>없음</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" checked={this.user.carVisible} onChange={e => this.user.carVisible = e.target.checked} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">종합부동산</label>
                        <div className="col-sm-8">
                            <select className="form-control" value={this.user.estate} onChange={e => this.user.estate = e.target.value}>
                                <option value="1">0~1억</option>
                                <option value="2">1억~3억</option>
                                <option value="3">3억~6억</option>
                                <option value="4">6억~10억</option>
                                <option value="5">10~20억</option>
                                <option value="6">20억 이상</option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <input type="checkbox" checked={this.user.estateVisible} onChange={e => this.user.estateVisible = e.target.checked} /> 공개
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">인증정보</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" cols="30" rows="10" value={this.user.values} onChange={e => this.user.values = e.target.value} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">매력포인트</label>
                        <div className="col-sm-10">
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="분위기 메이커" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '분위기 메이커')} /> 분위기 메이커
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="뛰어난 커리어" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '뛰어난 커리어')} /> 뛰어난 커리어
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="허세 없어요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '허세 없어요')} /> 허세 없어요
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="손이 예뻐요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '손이 예뻐요')} /> 손이 예뻐요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="뭐든 잘 먹어요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '뭐든 잘 먹어요')} /> 뭐든 잘 먹어요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="요리를 잘해요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '요리를 잘해요')} /> 요리를 잘해요
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="잘 듣는 편이에요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '잘 듣는 편이에요')} /> 잘 듣는 편이에요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="운동 감각" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '운동 감각')} /> 운동 감각
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="넓은 어깨" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '넓은 어깨')} /> 넓은 어깨
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="주도적인" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '주도적인')} /> 주도적인
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="도도한" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '도도한')} /> 도도한
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="애교있는" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '애교있는')} /> 애교있는
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="장난기가 많아요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '장난기가 많아요')} /> 장난기가 많아요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="섬세해요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '섬세해요')} /> 섬세해요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="보조개" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '보조개')} /> 보조개
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="엉덩이가 예뻐요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '엉덩이가 예뻐요')} /> 엉덩이가 예뻐요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="비율이 좋아요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '비율이 좋아요')} /> 비율이 좋아요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="큰 눈" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '큰 눈')} /> 큰 눈
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="워커홀릭" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '워커홀릭')} /> 워커홀릭
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="짙은 눈썹" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '짙은 눈썹')} /> 짙은 눈썹
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="솔직해요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '솔직해요')} /> 솔직해요
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="유머감각" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '유머감각')} /> 유머감각
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="경제력" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '경제력')} /> 경제력
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="오똑한 콧날" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '오똑한 콧날')} /> 오똑한 콧날
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="쌍커풀 없는 눈" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '쌍커풀 없는 눈')} /> 쌍커풀 없는 눈
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="애교가 많아요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '애교가 많아요')} /> 애교가 많아요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="대화를 잘해요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '대화를 잘해요')} /> 대화를 잘해요
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="배려심이 깊어요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '배려심이 깊어요')} /> 배려심이 깊어요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="표현을 잘해요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '표현을 잘해요')} /> 표현을 잘해요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="탁월한 패션감각" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '탁월한 패션감각')} /> 탁월한 패션감각
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="목소리가 좋아요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '목소리가 좋아요')} /> 목소리가 좋아요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="웃음이 많아요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '웃음이 많아요')} /> 웃음이 많아요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="게임을 잘해요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '게임을 잘해요')} /> 게임을 잘해요
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="웃는게 예뻐요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '웃는게 예뻐요')} /> 웃는게 예뻐요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="예의가 발라요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '예의가 발라요')} /> 예의가 발라요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="다정해요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '다정해요')} /> 다정해요
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="털털해요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '털털해요')} /> 털털해요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="다리가 예뻐요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '다리가 예뻐요')} /> 다리가 예뻐요
                                </span>
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="섹시한 타투" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '섹시한 타투')} /> 섹시한 타투
                                </span>
                            </div>
                            <div className="mb-2 d-flex">
                                <span className="mr-3 col-4">
                                    <input type="checkbox" name="advantage" data-value="시사에 밝아요" onChange={this.onChnageAdventage} checked={this.user.advantage.some(item => item === '시사에 밝아요')} /> 시사에 밝아요
                                </span>
                            </div>
                        </div>
                        {/* <div className="col-sm-10">
                        <textarea className="form-control" cols="30" rows="10" value={this.user.advantage} onChange={e => this.user.advantage = e.target.value} />
                    </div> */}
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">이상형</label>
                        {/* <div className="col-sm-10">
                        <textarea className="form-control" cols="30" rows="10" value={this.user.idealType} onChange={e => this.user.idealType = e.target.value} />
                    </div> */}
                        <div className="col-sm-10">
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="유머러스한 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '유머러스한 사람')} /> 유머러스한 사람
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="대화가 많은 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '대화가 많은 사람')} /> 대화가 많은 사람
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="배울게 많은 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '배울게 많은 사람')} /> 배울게 많은 사람
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="귀여운 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '귀여운 사람')} /> 귀여운 사람
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="시간여유 많은 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '시간여유 많은 사람')} /> 시간여유 많은 사람
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="일 잘하는 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '일 잘하는 사람')} /> 일 잘하는 사람
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="애교가 많은 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '애교가 많은 사람')} /> 애교가 많은 사람
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="허세가 없는 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '허세가 없는 사람')} /> 허세가 없는 사람
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="얼굴보다 마음" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '얼굴보다 마음')} /> 얼굴보다 마음
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="털털한 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '털털한 사람')} /> 털털한 사람
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="예의바른 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '예의바른 사람')} /> 예의바른 사람
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="인기가 많은 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '인기가 많은 사람')} /> 인기가 많은 사람
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="경제력 탁월한 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '경제력 탁월한 사람')} /> 경제력 탁월한 사람
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="진보성향인 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '진보성향인 사람')} /> 진보성향인 사람
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="중도성향인 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '중도성향인 사람')} /> 중도성향인 사람
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="idealType" data-value="보수성향의 사람" onChange={this.onChnageIdealType} checked={this.user.idealType.some(item => item === '보수성향의 사람')} /> 보수성향의 사람
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">데이트 스타일</label>
                        {/* <div className="col-sm-10">
                        <textarea className="form-control" cols="30" rows="10" value={this.user.want} onChange={e => this.user.want = e.target.value} />
                    </div> */}
                        <div className="col-sm-10">
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 드라이브 하기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 드라이브 하기')} /> 함께 드라이브 하기
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="주말 데이트" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '주말 데이트')} /> 주말 데이트
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="집 데이트" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '집 데이트')} /> 집 데이트
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="도시락 나들이" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '도시락 나들이')} /> 도시락 나들이
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 산책하기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 산책하기')} /> 함께 산책하기
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 여행하기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 여행하기')} /> 함께 여행하기
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 게임하기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 게임하기')} /> 함께 게임하기
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="카페 데이트" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '카페 데이트')} /> 카페 데이트
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="친구같은 편한 만남" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '친구같은 편한 만남')} /> 친구같은 편한 만남
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 운동하기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 운동하기')} /> 함께 운동하기
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 술 마시기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 술 마시기')} /> 함께 술 마시기
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 맛집 투어" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 맛집 투어')} /> 함께 맛집 투어
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="즉흥적인 깜짝 만남" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '즉흥적인 깜짝 만남')} /> 즉흥적인 깜짝 만남
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="꾸준히 연락하기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '꾸준히 연락하기')} /> 꾸준히 연락하기
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="통화 자주하기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '통화 자주하기')} /> 통화 자주하기
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="짧더라도 자주 만나기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '짧더라도 자주 만나기')} /> 짧더라도 자주 만나기
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 전시회 관람" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 전시회 관람')} /> 함께 전시회 관람
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="영화 관람" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '영화 관람')} /> 영화 관람
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 스포츠 관람" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 스포츠 관람')} /> 함께 스포츠 관람
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="함께 공연 관람" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '함께 공연 관람')} /> 함께 공연 관람
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="서로 사진 찍어주기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '서로 사진 찍어주기')} /> 서로 사진 찍어주기
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="노래방 데이트" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '노래방 데이트')} /> 노래방 데이트
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="취미활동 공유하기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '취미활동 공유하기')} /> 취미활동 공유하기
                                </div>
                                <div className="mr-3 col-6">
                                    <input type="checkbox" name="want" data-value="발전적인 대화 나누기" onChange={this.onChnageWant} checked={this.user.want.some(item => item === '발전적인 대화 나누기')} /> 발전적인 대화 나누기
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">매니저님 이것만은 안돼요</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" cols="30" rows="10" value={this.user.talkTo} onChange={e => this.user.talkTo = e.target.value} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">프로필 사진</label>
                        <div className="col-sm-10">
                            <div className="mb-1">
                                <label className="btn btn-sm btn-primary" htmlFor={"_main"}>+ 추가</label>
                                <input id={"_main"} type="file" accept="image/jpeg, image/png" className="btn btn-sm btn-primary" style={{ display: "none" }} onChange={(e) => this.upload(e, "_mainPhoto")} onClick={e => e.target.value = null} />
                            </div>
                            <div className="row">
                                {
                                    this._mainPhoto &&
                                    <div className="col-sm-4">
                                        <img className="img-thumbnail w-100 previmg" src={`${Config.photoUrl}${this._mainPhoto}`} alt="" />
                                        <div className="text-center mt-1">
                                            <label className="btn btn-sm btn-secondary" htmlFor={"_main"} style={{
                                                marginTop: 5,
                                                marginRight: 5
                                            }}>수정</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">개인 사진(필수)</label>
                        <div className="col-sm-10">
                            <div className="mb-1">
                                <label className="btn btn-sm btn-primary" htmlFor={"_privateR"}>+ 추가</label>
                                <input id={"_privateR"} type="file" accept="image/jpeg, image/png" className="btn btn-sm btn-primary" style={{ display: "none" }} onChange={(e) => this.upload(e, "_privateRPhoto")} onClick={e => e.target.value = null} />
                            </div>
                            <div className="row">
                                {
                                    this._privateRPhoto && this._privateRPhoto.length > 0 && this._privateRPhoto.map((value, index) => {
                                        return (
                                            <div className="col-sm-4" key={index}>
                                                <img className="img-thumbnail w-100 previmg" src={`${Config.photoUrl}${value}`} alt="" />
                                                <div className="text-center mt-1">
                                                    <label className="btn btn-sm btn-secondary" htmlFor={`_privateR${index}`} style={{
                                                        marginTop: 5,
                                                        marginRight: 5
                                                    }}>수정</label>
                                                    <label className="btn btn-sm btn-light" style={{ marginTop: 5 }} onClick={e => this.removePhotos("_privateRPhoto", index)}>삭제</label>
                                                    <input id={`_privateR${index}`} type="file" accept="image/jpeg, image/png" className="btn btn-sm btn-primary" style={{ display: "none" }} onChange={(e) => this.upload(e, "_privateRPhoto", index)} onClick={e => e.target.value = null} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">취미 일상 사진(필수)</label>
                        <div className="col-sm-10">
                            <div className="mb-1">
                                <label className="btn btn-sm btn-primary" htmlFor={"_hobbyLife"}>+ 추가</label>
                                <input id={"_hobbyLife"} type="file" accept="image/jpeg, image/png" className="btn btn-sm btn-primary" style={{ display: "none" }} onChange={(e) => this.upload(e, "_hobbyLifePhoto")} onClick={e => e.target.value = null} />
                            </div>
                            <div className="row">
                                {
                                    this._hobbyLifePhoto && this._hobbyLifePhoto.length > 0 && this._hobbyLifePhoto.map((value, index) => {
                                        return (
                                            <div className="col-sm-4" key={index}>
                                                <img className="img-thumbnail w-100 previmg" src={`${Config.photoUrl}${value}`} alt="" />
                                                <div className="text-center mt-1">
                                                    <label className="btn btn-sm btn-secondary" htmlFor={`_hobbyLife${index}`} style={{
                                                        marginTop: 5,
                                                        marginRight: 5
                                                    }}>수정</label>
                                                    <label className="btn btn-sm btn-light" style={{ marginTop: 5 }} onClick={e => this.removePhotos("_hobbyLifePhoto", index)}>삭제</label>
                                                    <input id={`_hobbyLife${index}`} type="file" accept="image/jpeg, image/png" className="btn btn-sm btn-primary" style={{ display: "none" }} onChange={(e) => this.upload(e, "_hobbyLifePhoto", index)} onClick={e => e.target.value = null} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">가 (가족력)</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.familyLevel} onChange={e => this.user.familyLevel = e.target.value}>
                                <option value="">없음</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">능 (능력)</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.abilityLevel} onChange={e => this.user.abilityLevel = e.target.value}>
                                <option value="">없음</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">성 (성격)</label>
                        <div className="col-sm-10">
                            <select className="form-control" value={this.user.personalityLevel} onChange={e => this.user.personalityLevel = e.target.value}>
                                <option value="">없음</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                            </select>
                        </div>
                    </div>
                    {
                        this.myPermission === 'super' &&
                        <div>
                            <button className="btn btn-primary" onClick={this._update} style={{ marginRight: 5 }}>수정</button>
                            <button className="btn btn-secondary" onClick={e => this.props.history.refresh()}>취소</button>
                        </div>
                    }
                </form>
            </UserShowLayout>
        )
    }
}

export default UserProfileScreen