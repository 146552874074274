import _Component from "../../common/_Component"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import { IconButton } from "@material-ui/core"
import { Delete, Visibility, Sort} from "@material-ui/icons"
import PeopleIcon from '@material-ui/icons/People';
import Pagination from "../../components/Pagination"
import { action, observable } from "mobx"
import Admin from "../../api/Admin"
import User from "../../api/User"
import GetValue from "../../utils/GetValue"
import { getProfileUrl } from "../../utils/ImageUtil"
import { addDash, getAge, getHeight, getInterestTypeFromRank, getMoment, isLineNumber } from "../../utils/Utils"
import { inject } from "mobx-react"
import moment from "moment"
import Match from "../../api/Match"
import AvailableSuccess from "../../utils/AvailableSuccess"
import ShowType from "../../utils/ShowType"
import ShownStatus from "../../utils/ShownStatus"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class UserScreen extends _Component {
    @observable users = []
    @observable managers = []
    @observable name = ""
    @observable status = ""
    @observable requestFilter = ""
    @observable successible = ""
    @observable manager = ""
    @observable familyLevel = ""
    @observable abilityLevel = ""
    @observable personalityLevel = ""
    @observable ageLevel = ""
    @observable grade = ""
    @observable interest = ""
    @observable gender = ""
    @observable pagination = {}
    @observable permission = ""
    @observable myPermission = ""
    @observable tableFilterLabel = ""
    @observable tableSuccessibleLabel = ""
    @observable sort = ""
    @observable sortType = ""
    @observable admin = null

    async componentDidMount() {
        const permission = this.props.loginStore.permission
        this.permission = permission
        this.myPermission = permission
        if (permission === "manager") {
            // this.props.history.push("/manager")
            // return true
            const response = await Admin.my()
            this.manager = response.admin._id

        }
        if (permission === "arranger") {
            this.props.history.push("/arranger")
            return true
        }

        if (permission === "admin") {
            const response = await Admin.my()
            if (response && response.success) {
                this.admin = response.admin
            }
        }

        try {
            await this._getManagers()
            await this._getData()
            super.componentDidMount()
        } catch (e) {
            console.log(e)
        }
    }

    @action
    _setSort = async (target, type) => {
        this.sort = target
        this.sortType = type
        await this._getData()
    }

    @action
    _getManagers = async () => {
        try {
            let params = {
                type: "manager"
            }
            if(this.admin) {
                params._admin = this.admin._id
            }
            const response = await Admin.get(params)
            if (response && response.success) {
                this.managers = response.admins.sort(function (a, b) {
                    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _getData = async (page = 1, e) => {
        if (e) e.preventDefault()
        const lineNumber = isLineNumber(this.name)
        let params = {
            page: page,
            limit: this.limit,
            name: lineNumber ? null : this.name,
            lineNumber: lineNumber ? this.name : null,
            _manager: this.manager,
            familyLevel: this.familyLevel,
            abilityLevel: this.abilityLevel,
            personalityLevel: this.personalityLevel,
            ageLevel: this.ageLevel,
            grade: this.grade,
            interest: this.interest,
            gender: this.gender,
            status: this.status,
            requestFilter: this.requestFilter,
            successible: this.successible,
            history: "connect",
            sort: this.sort,
            sortType: this.sortType
        }

        if(this.myPermission !== 'super') {
            params.forManager = true
        }

        try {
            const response = await User.get(params)
            if (response && response.success) {
                this.users = response.users
                this.pagination = response.pagination
                this.tableFilterLabel = this.requestFilter
                this.tableSuccessibleLabel = this.successible
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _exportExcel = async (e, all = true) => {
        e.preventDefault()
        const lineNumber = isLineNumber(this.name)
        let params = {
            page: all ? 1 : this.pagination && this.pagination.currentPage ? this.pagination.currentPage : 1,
            limit: all ? 9999999999 : this.limit,
            name: lineNumber ? null : this.name,
            lineNumber: lineNumber ? this.name : null,
            _manager: this.manager,
            familyLevel: this.familyLevel,
            abilityLevel: this.abilityLevel,
            personalityLevel: this.personalityLevel,
            ageLevel: this.ageLevel,
            grade: this.grade,
            interest: this.interest,
            gender: this.gender,
            status: this.status,
            request: this.requestFilter,
            successible: this.successible,
        }

        const filename = `${moment().format("YYYY_MM_DD_HH_mm_ss")}_users.xlsx`
        const url =  `user/excel`
        try {
            await this.excel(filename, url, params)
        } catch (e) {
            alert(e.toString())
        }
    }

    _refresh = () => {
        // if (e) e.preventDefault()
        window.location.reload(false)
    }

    _updateRequest = async (userId) => {
        // e.preventDefault()
        try {
            const response = await Match.create({_user: userId, showType: -1, silent: true})
            if (response && response.success) {
                alert("(특별)소개해줘 상태를 추가하였습니다")
                // this._refresh()
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _getTotalConnect = user => {
        let count = 0
        const history = user ? user.history : null
        if (history) {
            if (history.connectItem_free)
                count += Number(history.connectItem_free)
            if (history.connectItem_paid)
                count += Number(history.connectItem_paid)
        }
        return count
    }

    _getPaidConnect = user => {
        let count = 0
        const history = user ? user.history : null
        if (history) {
            // if (history.connectItem_free)
            //     count += Number(history.connectItem_free)
            if (history.connectItem_paid)
                count += Number(history.connectItem_paid)
        }
        return count
    }

    _render = () => (
        <div className="card mb-4">
            <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />회원 목록</div>
            <div className="card-body">
                <div className="d-md-flex">
                    <div className="form-group mr-2">
                        <label htmlFor="search">회원검색</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="회원이름 또는 뒷번호" value={this.name} onChange={e => this.name = e.target.value} />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary" type="button" onClick={e => this._getData(1, e)}>검색</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">상태</label>
                        <select className="form-control" id="filter_status" value={this.status} onChange={e => this.status = e.target.value}>
                            <option value={""}>전체</option>
                            {
                                this.myPermission === 'super' &&
                                <option value={"wait"}>가입심사</option>
                            }
                            {
                                this.myPermission === 'super' &&
                                <option value={"rejoin"}>재가입</option>
                            }
                            <option value={"approval"}>가입완료</option>
                            {
                                this.myPermission === 'super' &&
                                <option value={"reject"}>가입거절</option>

                            }
                            <option value={"rest"}>휴면</option>
                            {
                                this.myPermission === 'super' &&
                                <option value={"withdraw"}>탈퇴</option>
                            }
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">요청 리스트</label>
                        <select className="form-control" id="request_filter" defaultValue={this.requestFilter} onChange={e => this.requestFilter = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"show"}>소개해줘</option>
                            <option value={"request"}>소개함</option>
                            <option value={"response"}>이어줘</option>
                            <option value={"response1"}>이어줘받음</option>
                            <option value={"wait"}>성사대기</option>
                            <option value={"success"}>매칭성사</option>
                            {/* <option value={"getmanagermatching"}>받은요청</option> */}
                            <option value={"fail"}>매칭실패</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">성사진행건</label>
                        <select className="form-control" id="request_filter" defaultValue={this.successible} onChange={e => this.successible = e.target.value}>
                            <option value={""}>기본값</option>
                            <option value={"all"}>모두포함</option>
                            <option value={"available"}>성사가능</option>
                            <option value={"final"}>최종성사</option>
                        </select>
                    </div>
                    {
                        this.myPermission === 'super' || this.myPermission === 'admin' ?
                        <div className="form-group mr-2">
                            <label htmlFor="search">매니저</label>
                            <select className="form-control" id="filter_status" value={this.manager} onChange={e => this.manager = e.target.value}>
                                <option value="">전체</option>
                                {
                                    this.myPermission === 'super' && <option value="undefined">배정없음</option>
                                }
                                {
                                    this.managers && 0 < this.managers.length &&
                                    this.managers.map((value, index) => <option key={index} value={value._id}>{value.name}</option>)
                                }
                            </select>
                        </div> :
                        null
                    }
                    
                </div>
                <div className="d-md-flex mb-3">
                    <div className="form-group mr-2">
                        <label htmlFor="search">나이</label>
                        <select className="form-control" id="filter_status" value={this.ageLevel} onChange={e => this.ageLevel = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"A"}>20대</option>
                            <option value={"B"}>30대</option>
                            <option value={"C"}>40대이상</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">가</label>
                        <select className="form-control" id="filter_status" value={this.familyLevel} onChange={e => this.familyLevel = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"A"}>A</option>
                            <option value={"B"}>B</option>
                            <option value={"C"}>C</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">능</label>
                        <select className="form-control" id="filter_status" value={this.abilityLevel} onChange={e => this.abilityLevel = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"A"}>A</option>
                            <option value={"B"}>B</option>
                            <option value={"C"}>C</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">성</label>
                        <select className="form-control" id="filter_status" value={this.personalityLevel} onChange={e => this.personalityLevel = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"A"}>A</option>
                            <option value={"B"}>B</option>
                            <option value={"C"}>C</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">등급</label>
                        <select className="form-control" id="filter_status" value={this.grade} onChange={e => this.grade = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"gold"}>골드 이상</option>
                            <option value={"platinum"}>플래티넘 이상</option>
                            <option value={"diamond"}>다이아 이상</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">1순위 이상형</label>
                        <select className="form-control" id="filter_status" value={this.interest} onChange={e => this.interest = e.target.value}>
                            <option value={"all"}>전체</option>
                            <option value={"face"}>외모</option>
                            <option value={"income"}>경제력</option>
                            <option value={"personality"}>성격</option>
                            <option value={"values"}>가치관</option>
                            <option value={"familyBackground"}>가정환경</option>
                        </select>
                    </div>
                    <div className="form-group mr-2">
                        <label htmlFor="search">성별</label>
                        <select className="form-control" id="filter_status" value={this.gender} onChange={e => this.gender = e.target.value}>
                            <option value={""}>전체</option>
                            <option value={"male"}>남성</option>
                            <option value={"female"}>여성</option>
                        </select>
                    </div>
                </div>
                <div className="d-md-flex mb-3">
                    <div style={{ marginTop: 32 }}>
                        {
                            this.myPermission === "super" &&
                            <>
                            <a href="/user/create" className="btn btn-outline-success" style={{ marginRight: 5 }}>데이터 추가</a>
                            <button className="btn btn-outline-secondary" style={{ marginRight: 5 }} onClick={e => this._delete(e, "user", this._getData.bind(this), this.pagination.currentPage, this.users)}>선택삭제</button>
                            </>
                        }
                        {
                            this.myPermission === "super" &&
                            <>
                            <button className="btn btn-success" style={{ marginRight: 5 }} onClick={(e) => this._exportExcel(e, false)}>현재페이지엑셀</button>
                            <button className="btn btn-success" onClick={(e) => this._exportExcel(e, true)}>검색결과엑셀</button>
                            </>
                        }
                    </div>
                </div>
                <div className="d-md-flex mb-3">

                </div>
                <div className="table-responsive">
                    <table className="table text-nowrap">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <input type="checkbox" onChange={e => this._checkAll(e, this.users)} />
                                </th>
                                <th scope="col">Atc.</th>
                                {
                                    this.myPermission === 'super' &&
                                    <th scope="col">이메일</th>
                                }
                                <th scope="col"></th>
                                <th scope="col">
                                    이름
                                    <IconButton
                                        color={"success"}
                                        aria-label="name"
                                        style={{ padding: 0, outline: "none" }}
                                        onClick={() => this._setSort('name', !this.sortType || this.sortType < 0 ? 1 : -1 )}
                                    >
                                      <Sort />
                                    </IconButton>
                                </th>
                                <th scope="col">
                                    {this.tableFilterLabel === 'show' && '소개해줘' }
                                    {this.tableFilterLabel === 'request' && '소개함'}
                                    {this.tableFilterLabel === 'response' && '이어줘'}
                                    {this.tableFilterLabel === 'response1' && '이어줘 받음'}
                                    {this.tableFilterLabel === 'wait' && '성사대기'}
                                    {this.tableFilterLabel === 'success' && '성공'}
                                    {this.tableFilterLabel === 'fail' && '실패'}
                                    {!this.tableFilterLabel && '-'}
                                    &nbsp; 요청수
                                </th>
                                <th scope="col">
                                    {this.tableSuccessibleLabel === 'all' && '성사진행건 : 모두포함'}
                                    {this.tableSuccessibleLabel === 'final' && '성사진행건 : 최종성사'}
                                    {this.tableSuccessibleLabel === 'available' && '성사진행건 : 성사가능'}
                                    {!this.tableSuccessibleLabel && '성사진행건'}
                                </th>
                                {/*<th scope="col">최근요청</th>*/}
                                <th scope="col">등급</th>
                                <th scope="col">출생년도</th>
                                <th scope="col">
                                    만나이
                                    <IconButton
                                        color={"success"}
                                        aria-label="name"
                                        style={{ padding: 0, outline: "none" }}
                                        onClick={() => this._setSort('birth', !this.sortType || this.sortType < 0 ? 1 : -1 )}
                                    >
                                      <Sort />
                                    </IconButton>
                                </th>
                                <th scope="col">성별</th>
                                <th scope="col">직업</th>
                                <th scope="col">거주지</th>
                                <th scope="col">미혼여부</th>
                                <th scope="col">
                                    키
                                    <IconButton
                                        color={"success"}
                                        aria-label="name"
                                        style={{ padding: 0, outline: "none" }}
                                        onClick={() => this._setSort('height', !this.sortType || this.sortType < 0 ? 1 : -1 )}
                                    >
                                      <Sort />
                                    </IconButton>
                                </th>
                                <th scope="col">체형</th>
                                {
                                    this.myPermission === 'super' &&
                                    <th scope="col">연락처</th>
                                }
                                <th scope="col">상태</th>
                                <th scope="col">
                                    (유료)이어줘
                                    <IconButton
                                        color={"success"}
                                        aria-label="name"
                                        style={{ padding: 0, outline: "none" }}
                                        onClick={() => this._setSort('history.connectItem_paid', !this.sortType || this.sortType < 0 ? 1 : -1)}
                                    >
                                        <Sort />
                                    </IconButton>
                                </th>
                                <th scope="col">
                                    매니저
                                    <IconButton
                                        color={"success"}
                                        aria-label="name"
                                        style={{ padding: 0, outline: "none" }}
                                        onClick={() => this._setSort('_manager.name', !this.sortType || this.sortType < 0 ? 1 : -1)}
                                    >
                                        <Sort />
                                    </IconButton>
                                </th>
                                <th scope="col">가</th>
                                <th scope="col">능</th>
                                <th scope="col">성</th>
                                <th scope="col">1순위</th>
                                <th scope="col">2순위</th>
                                <th scope="col">3순위</th>
                                <th scope="col">관심나이대</th>
                                <th scope="col">관심직업군</th>
                                <th scope="col">매치범위</th>
                                <th scope="col">
                                    마지막 접속일
                                    <IconButton
                                        color={"success"}
                                        aria-label="name"
                                        style={{ padding: 0, outline: "none" }}
                                        onClick={() => this._setSort('loginAt', !this.sortType || this.sortType < 0 ? 1 : -1 )}
                                    >
                                      <Sort />
                                    </IconButton>
                                </th>
                                <th scope="col">
                                    등록일
                                    <IconButton
                                        color={"success"}
                                        aria-label="name"
                                        style={{ padding: 0, outline: "none" }}
                                        onClick={() => this._setSort('createdAt', !this.sortType || this.sortType < 0 ? 1 : -1 )}
                                    >
                                      <Sort />
                                    </IconButton>
                                </th>
                                <th scope="col">수정일</th>
                              </tr>
                        </thead>
                        <tbody>
                        {
                            this.users && this.users.length > 0 && this.users.map((value, index) => (
                                <tr key={value._id}>
                                <td>
                                    <input type="checkbox" checked={!!value.checked} onChange={(e) => this.users[index].checked = e.target.checked} />
                                </td>
                                <td>
                                    
                                    <IconButton
                                        className="text-info fas"
                                        aria-label="visibility"
                                        style={{ padding: 0, outline: "none" }}
                                        href={`/user/${value._id}`}
                                        target="_blank"
                                    >
                                      <Visibility />
                                    </IconButton>
                                    {
                                        this.myPermission === "super" &&
                                        <IconButton
                                            color={"secondary"}
                                            aria-label="delete"
                                            style={{ padding: 0, outline: "none" }}
                                            onClick={e => this._delete(e, `user/${value._id}`, this._getData.bind(this), this.pagination.currentPage)}
                                        >
                                        <Delete />
                                        </IconButton>
                                    }
                                    

                                </td>
                                {
                                    this.myPermission === "super" &&
                                    <td>{value.email}</td>
                                }
                                 <td className="text-right">
                                    <img className="align-self-center mr-3" style={{
                                        width: 32,
                                        height: 32, 
                                        borderRadius: 16
                                    }} src={getProfileUrl(GetValue(value, "value._photos._main"))} alt="profile" />
                                    <IconButton
                                        color={"success"}
                                        aria-label="matching"
                                        style={{ padding: 0, outline: "none", marginLeft:5 }}
                                        // onClick={e => this._delete(e, `user/${value._id}`, this._getData.bind(this), this.pagination.currentPage)}
                                        onClick={() => this._updateRequest(value._id)}
                                    >
                                      <PeopleIcon />
                                    </IconButton>
                                </td>
                                <td>{value.name}</td>
                                <td className="text-center">
                                    {
                                        !value.matchCount || value.matchCount.length === 0 ?
                                            '-' :
                                            <span className="badge badge-pill badge-danger">{value.matchCount[0].count}</span>
                                    }
                                </td>
                                <td>
                                    {AvailableSuccess.getJSXElementByUser(value)}
                                </td>
                                {/*<td>
                                    {
                                        GetValue(value, "value._match[0].status") === "show"  &&
                                        ShowType.getJSXElementByUser(value)
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "request" &&
                                        ShownStatus.getJSXElementByUser(value)
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "response" && value._match[0]._request._user === value._id &&
                                        <span className="badge badge-pill badge-success">이어줘</span>
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "response" && value._match[0]._response._user === value._id && value._match[0]._response.answer === null &&
                                        <span className="badge badge-pill badge-success">이어줘받음</span>
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "wait" &&
                                        <span className="badge badge-pill badge-info">성사대기</span>
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "success" &&
                                        <span className="badge badge-pill badge-primary">매칭성사</span>
                                    }
                                    {
                                        GetValue(value, "value._match[0].status") === "fail" &&
                                        <span className="badge badge-pill badge-secondary">매칭실패</span>
                                    }
                                </td>*/}                                
                                <td>
                                    {value.grade === "standard" && "스탠다드"}
                                    {value.grade === "gold" && "골드"}
                                    {value.grade === "platinum" && "플래티넘"}
                                    {value.grade === "diamond" && "다이아몬드"}
                                </td>
                                <td>{value.birth}</td>
                                <td>{getAge(value.birth)}</td>
                                <td>{value.gender === "male" ? "남" : "여"}</td>
                                <td>{GetValue(value, "value.job.value")}</td>
                                <td>{GetValue(value, "value._area.city") ? `${GetValue(value, "value._area.city")} ${GetValue(value, "value._area.name")}` : GetValue(value, "value._area.name")}</td>
                                <td>{value.married}</td>
                                <td>{getHeight(value.height.value)}</td>
                                <td>{value.body.value}</td>
                                {
                                    this.myPermission === 'super' &&
                                    <td>{addDash(value.phone)}</td>
                                }
                                <td>
                                    {value.status === "wait" && "가입심사"}
                                    {value.status === "rejoin" && "재가입"}
                                    {value.status === "approval" && "가입완료"}
                                    {value.status === "reject" && "심사거절"}
                                    {value.status === "rest" && "휴면"}
                                    {value.status === "withdraw" && "탈퇴"}
                                </td>
                                <td>{this._getPaidConnect(value)}</td>
                                <td>{GetValue(value, "value._manager.name", "매니저없음")}</td>
                                <td>{value.familyLevel}</td>
                                <td>{value.abilityLevel}</td>
                                <td>{value.personalityLevel}</td>
                                <td>{getInterestTypeFromRank(GetValue(value, "value._interest", null), 1)}</td>
                                <td>{getInterestTypeFromRank(GetValue(value, "value._interest", null), 2)}</td>
                                <td>{getInterestTypeFromRank(GetValue(value, "value._interest", null), 3)}</td>
                                <td>{GetValue(value, "value._interest.age")}</td>
                                <td>{GetValue(value, "value._interest.job")}</td>
                                <td>{GetValue(value, "value.matchRange")}</td>
                                <td>{getMoment(value.loginAt)}</td>
                                <td>{getMoment(value.createdAt)}</td>
                                <td>{getMoment(value.updatedAt)}</td>
                            </tr>
                            ))
                        }
                        </tbody>
                      </table>
                </div>
                    <div className="d-flex">
                    <div className="d-flex mr-auto">
                        <div>
                            <select className="form-control form-control-sm" value={this.limit} onChange={e => this._changeLimit(e, this._getData.bind(this))}>
                                    <option value={"10"}>10</option>
                                    <option value={"20"}>20</option>
                                    <option value={"30"}>30</option>
                                    <option value={"40"}>40</option>
                                    <option value={"50"}>50</option>
                              </select>
                        </div>
                    </div>
                    <div>
                        <nav aria-label="Page navigation example">
                            <Pagination handle={this._getData.bind(this)} pagination={this.pagination} />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserScreen