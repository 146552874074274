import React from "react"
import MenuLayouts from "../layouts/MenuLayouts"
import { Route, Switch } from "react-router-dom"
import ArrangerScreen from "../screens/Arranger/ArrangerScreen"
import ArrangerSalesScreen from "../screens/Arranger/ArrangerSalesScreen"
import NotFountScreen from "../screens/Error/NotFountScreen"

export default ({ match }) => {
    return (
            <Switch>
                <MenuLayouts exact path={match.path} component={ArrangerScreen} title={"연애해 CMO 관리자"} />
                <MenuLayouts path={`${match.path}/sales`} component={ArrangerSalesScreen} title={"주선자 실적"} />
                <Route component={NotFountScreen} />
            </Switch>
    )
}