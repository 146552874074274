import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { action, observable } from 'mobx'
import _Component from '../../common/_Component'
import Admin from '../../api/Admin'
import { inject } from "mobx-react"
import GetValue from "../../utils/GetValue"
import Config from '../../config/config'
import Photo from '../../api/Photo'

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class AdminCreateScreen extends _Component {
    @observable name = ''
    @observable name2nd = ''
    @observable email = ''
    @observable password = ''
    @observable passwordConfirm = ''
    @observable phone = ''
    @observable permission = ''
    @observable position = ''
    @observable admin = null
    @observable _photo = null
    @observable isTeamIndex = false
    @observable website = ""
    @observable myPermission = ""

    componentDidMount = async () => {
        this.admin = await this.props.loginStore.getUser("admin")
        this.loaded = true
        this.myPermission = this.props.loginStore.permission

    }

    @action
    _save = async (e) => {
        e.preventDefault()
        let param = {
            name: this.name,
            name2nd: this.name2nd,
            email: this.email,
            password: this.password,
            passwordConfirm: this.passwordConfirm,
            phone: this.phone,
            permission: this.permission,
            position: this.position,
            _photo: this._photo,
            isTeamIndex: this.isTeamIndex,
            website: this.website
        }

        if (this.permission === 'manager') {
            param._admin = this.admin._id
        }

        try {
            const admin = await Admin.save(param)
            if (admin && admin.success) {
                alert("관리자가 추가되었습니다.")
                if (this.permission === "super" || this.permission === "admin") this.props.history.push("/admin")
                else if (this.permission === "manager") this.props.history.push("/admin/manager")
                else if (this.permission === "arranger") this.props.history.push("/admin/arranger")
            } else {
                alert(admin.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    @action
    _reset = () => {
        this.name = ''
        this.name2nd = ''
        this.email = ''
        this.password = ''
        this.passwordConfirm = ''
        this.phone = ''
        this.permission = ''
        this.position = ''
        this._photo = ''
        this.isTeamIndex = false
        this.website = ''
    }

    @action
    _cancel = (e) => {
        e.preventDefault()
        this.props.history.goBack()
    }

    _render = () => {
        return (
            <div className="container-fluid">
                <div className="card mb-4">
                    <div className="card-header">
                        <FontAwesomeIcon icon={faTable} className="fas mr-1" />관리자 등록
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label small">이름</label>
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="이름"
                                        value={this.name}
                                        onChange={(e) => this.name = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label small">이름<br />(웹상단노출용)</label>
                                <div className="col-sm-10">
                                    <textarea
                                        rows="2"
                                        className="form-control"
                                        placeholder="이름(웹상단노출용)"
                                        value={this.name2nd}
                                        onChange={(e) => this.name2nd = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label small">Email</label>
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="email@example.com"
                                        value={this.email}
                                        onChange={(e) => this.email = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label small">비밀번호</label>
                                <div className="col-sm-10">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        value={this.password}
                                        onChange={(e) => this.password = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label small">비밀번호 확인</label>
                                <div className="col-sm-10">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        value={this.passwordConfirm}
                                        onChange={(e) => this.passwordConfirm = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label small">휴대전화</label>
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="01012344321"
                                        value={this.phone}
                                        onChange={(e) => this.phone = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label small">권한</label>
                                <div className="col-sm-10">
                                    <select name="status" className="form-control" value={this.permission} onChange={(e) => this.permission = e.target.value}>
                                        <option value="">선택</option>
                                        {
                                            this.myPermission === "super" &&
                                            <option value="super">최고관리자</option>
                                        }
                                        {
                                            this.myPermission === "super" &&
                                            <option value="admin">일반관리자</option>
                                        }
                                        {
                                            (this.myPermission === "super" || this.myPermission === "admin") &&
                                            <option value="manager">매니저</option>
                                        }
                                        {
                                            this.myPermission === "super" &&
                                            <option value="arranger">주선자</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label small">직급</label>
                                <div className="col-sm-10">
                                    <select name="status" className="form-control" value={this.position} onChange={(e) => this.position = e.target.value}>
                                        <option value="">선택</option>
                                        <option value="인턴1호">인턴1호</option>
                                        <option value="인턴2호">인턴2호</option>
                                        <option value="인턴3호">인턴3호</option>
                                        <option value="사원1호">사원1호</option>
                                        <option value="사원2호">사원2호</option>
                                        <option value="사원3호">사원3호</option>
                                        <option value="사원4호">사원4호</option>
                                        <option value="대리1호">대리1호</option>
                                        <option value="대리2호">대리2호</option>
                                        <option value="대리3호">대리3호</option>
                                        <option value="대리4호">대리4호</option>
                                        <option value="과장1호">과장1호</option>
                                        <option value="과장2호">과장2호</option>
                                        <option value="과장3호">과장3호</option>
                                        <option value="과장4호">과장4호</option>
                                        <option value="팀장1호">팀장1호</option>
                                        <option value="팀장2호">팀장2호</option>
                                        <option value="팀장3호">팀장3호</option>
                                        <option value="팀장4호">팀장4호</option>
                                        <option value="부장">부장</option>
                                        <option value="관리자">관리자</option>
                                        <option value="주선자">주선자</option>
                                        <option value="대표">대표</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label small">프로필 사진</label>
                                <div className="col-sm-10">
                                    <div className="mb-1">
                                        <label className="btn btn-sm btn-primary" htmlFor={"_photo"}>+ 추가</label>
                                        <input id={"_photo"} type="file" accept="image/jpeg, image/png" className="btn btn-sm btn-primary" style={{ display: "none" }} onChange={(e) => this.upload(e, "_photo")} onClick={e => e.target.value = null} />
                                    </div>
                                    <div className="row">
                                        {
                                            this._photo &&
                                            <div className="col-sm-4">
                                                <img className="img-thumbnail w-100 previmg" src={`${Config.photoUrl}${this._photo}`} alt="" />
                                                <div className="text-center mt-1">
                                                    <label className="btn btn-sm btn-secondary" htmlFor={"_main"} style={{
                                                        marginTop: 5,
                                                        marginRight: 5
                                                    }}>수정</label>
                                                    <label className="btn btn-sm btn-light" style={{ marginTop: 5 }} onClick={e => this.removePhotos("_photo")}>삭제</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                this.myPermission === 'super' && (this.permission === 'super' || this.permission === 'admin') &&
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label small">홈페이지 등록</label>
                                    <div className="col-sm-10">
                                        <input type="checkbox" checked={this.isTeamIndex} onChange={e => this.isTeamIndex = e.target.checked} /> 등록
                                    </div>
                                </div>
                            }
                            {
                                this.myPermission === 'super' && (this.permission === 'super' || this.permission === 'admin') && this.isTeamIndex &&
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label small">홈페이지 주소</label>
                                        <div className="col-sm-10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="https://"
                                            value={this.website}
                                            onChange={(e) => this.website = e.target.value}
                                        />
                                    </div>
                                </div>
                            }

                            <div>
                                <button className="btn btn-primary" style={{ marginRight: 5 }} onClick={this._save}>완료</button>
                                <button className="btn btn-secondary" onClick={this._cancel}>취소</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminCreateScreen
