import React from 'react'
import ReactDOM from 'react-dom'
import moment from "moment"
import GetValue from "./GetValue"

const ShownStatus = {
    getJSXElementByUser: user => {
        const showExpireAt = GetValue(user, "user._match[0].showExpireAt", "0")
        return ShownStatus.toJSXElement(showExpireAt)
    },

    getJSXElementByMatch: match => {
        const showExpireAt = GetValue(match, "match.showExpireAt", "0")
        return ShownStatus.toJSXElement(showExpireAt)
    },

    toJSXElement: showExpireAt => {
        if (showExpireAt && moment() <= moment(showExpireAt))
            return (<span className="badge badge-pill badge-warning">소개함</span>)

        return (<span className="badge badge-pill badge-secondary">소개함</span>)
    },
}

export default ShownStatus