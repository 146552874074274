import _Component from "../../common/_Component"
import React from "react"
import AdminShowLayout from "../../layouts/AdminShowLayout"
import { observable } from "mobx"
import { inject } from "mobx-react"
import Admin from "../../api/Admin"
import GetValue from "../../utils/GetValue"
import { getMoment } from "../../utils/Utils"
import Config from "../../config/config"
import Photo from "../../api/Photo"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class AdminShowScreen extends _Component {
    @observable admin = null
    @observable permission = ""
    @observable admins = []
    @observable myPermission = ""

    async componentDidMount() {
        this.admin = await this._getAdmin()
        this.myPermission = this.props.loginStore.permission

        if (this.admin && this.admin.permission) this.permission = this.admin.permission
        if (GetValue(this, "this.admin.permission") === "super" && GetValue(this, "this.props.loginStore.permission") !== "super") {
            alert("접근 권한이 없습니다")
            return true
        }
        const response = await Admin.get({ type: "admin", all: "all", sort: "asc" })
        if (response && response.success) {
            this.admins = response.admins
        }
        if (this.admin) {
            super.componentDidMount()
        }
    }

    _update = async (e) => {
        e.preventDefault()
        try {
            const response = await Admin.update(this.admin._id, {
                name: this.admin.name,
                name2nd: this.admin.name2nd,
                email: this.admin.email,
                phone: this.admin.phone,
                permission: this.admin.permission,
                position: this.admin.position,
                resign: this.admin.resign,
                _admin: GetValue(this, "this.admin._admin", null),
                _photo: this.admin._photo,
                isTeamIndex: this.admin.isTeamIndex,
                website: this.admin.website
            })
            if (response && response.success) {
                alert("데이터 수정이 완료되었습니다.")
                // if (this.admin.permission === "super" || this.admin.permission === "admin") this.props.history.push("/admin")
                // else if (this.admin.permission === "manager") this.props.history.push("/admin/manager")
                // else if (this.admin.permission === "arranger") this.props.history.push("/admin/arranger")
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _delete = async (e) => {
        e.preventDefault()
        const confirm = window.confirm("삭제하시겠습니까?")
        if (confirm) {
            try {
                const response = await Admin.delete(this.admin._id)
                if (response && response.success) {
                    alert("데이터 삭제가 완료되었습니다.")
                    this.props.history.push("/admin")
                } else {
                    alert(response.message)
                }
            } catch (e) {
                alert(e.toString())
            }
        }
    }

    removePhotos = (type = "_photo", index = null) => {
        if (type === "_photo") {
            this.admin._photo = ""
        } else {
            this[type].splice(index, 1)
        }
    }

    upload = async (e, type, index = null) => {
        e.preventDefault()
        try {
            const files = Array.from(e.target.files)
            const file = files && 0 < files.length ? files.find(file => file) : null
            if (!file)
                return

            const response = await Photo.upload([file])
            if (response && response.success && 0 < response.uploads.length) {
                this.setPhotos(type, response.uploads[0].filename, index)
            } else {
                alert(response.message)
            }
        } catch (e) {
            e.toString()
        }
    }

    setPhotos = (type = "_photo", data, index = null) => {
        if (type === "_mainPhoto") {
            this._mainPhoto = data
        } else if (type === "_photo") {
            this.admin._photo = data
        } else {
            if (!this[type]) this[type] = []
            if (index === null) {
                this[type] = [...this[type], data]
            } else {
                this[type][index] = data
            }
        }
    }

    _render = () => (
        <AdminShowLayout title={"계정정보"} admin={this.admin} permission={this.permission} _id={this.admin._id} path={this.path}>
            <form>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">id</label>
                    <div className="col-sm-10">
                        <input type="text" readOnly className="form-control-plaintext" value={this.admin._id} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">이름</label>
                    <div className="col-sm-10">
                        {
                            (this.myPermission === 'admin' && this.admin.permission === 'admin') ?
                            <input type="text" readOnly className="form-control-plaintext" value={this.admin.name} /> :
                            <input type="text" className="form-control" value={this.admin.name} onChange={e => this.admin.name = e.target.value} />
                        }
                    </div>
                </div>
                {
                    (this.myPermission === 'super' || (this.myPermission === 'admin' && this.admin.permission === 'manager')) &&
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">이름<br />(웹상단노출용)</label>
                        <div className="col-sm-10">
                            <textarea rows="2" className="form-control" value={this.admin.name2nd} onChange={e => this.admin.name2nd = e.target.value} />
                        </div>
                    </div>
                }
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">Email</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" value={this.admin.email} onChange={e => this.admin.email = e.target.value} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">휴대전화</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" value={this.admin.phone} onChange={e => this.admin.phone = e.target.value} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">권한</label>
                    <div className="col-sm-10">
                        <select name="status" className="form-control" value={this.admin.permission} onChange={e => this.admin.permission = e.target.value}>
                            <option value="">선택</option>
                            {this.props.loginStore.permission === "super" && <option value="super">최고관리자</option>}
                            {this.props.loginStore.permission === "super" && <option value="admin">일반관리자</option>}
                            <option value="manager">매니저</option>
                            {this.props.loginStore.permission === "super" && <option value="arranger">주선자</option>}
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">직급</label>
                    <div className="col-sm-10">
                        <select name="status" className="form-control" value={this.admin.position} onChange={e => this.admin.position = e.target.value}>
                            <option value="">선택</option>
                            <option value="인턴1호">인턴1호</option>
                            <option value="인턴2호">인턴2호</option>
                            <option value="인턴3호">인턴3호</option>
                            <option value="사원1호">사원1호</option>
                            <option value="사원2호">사원2호</option>
                            <option value="사원3호">사원3호</option>
                            <option value="사원4호">사원4호</option>
                            <option value="대리1호">대리1호</option>
                            <option value="대리2호">대리2호</option>
                            <option value="대리3호">대리3호</option>
                            <option value="대리4호">대리4호</option>
                            <option value="과장1호">과장1호</option>
                            <option value="과장2호">과장2호</option>
                            <option value="과장3호">과장3호</option>
                            <option value="과장4호">과장4호</option>
                            <option value="팀장1호">팀장1호</option>
                            <option value="팀장2호">팀장2호</option>
                            <option value="팀장3호">팀장3호</option>
                            <option value="팀장4호">팀장4호</option>
                            <option value="부장">부장</option>
                            <option value="관리자">관리자</option>
                            <option value="주선자">주선자</option>
                            <option value="대표">대표</option>
                        </select>
                    </div>
                </div>
                {
                    this.admin && (this.admin.permission === "arranger" || this.admin.permission === "manager") &&
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">관리자</label>
                        <div className="col-sm-10">
                            <select name="status" className="form-control" value={GetValue(this, "this.admin._admin")} onChange={e => this.admin._admin = e.target.value}>
                                <option value="">선택</option>
                                {
                                    this.admins && this.admins.length > 0 && this.admins.map((value, index) =>
                                        <option key={index} value={value._id}>{value.name}</option>)
                                }
                            </select>
                        </div>
                    </div>
                }
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">퇴사</label>
                    <div className="col-sm-10">
                        <input type="checkbox" checked={this.admin.resign} onChange={e => this.admin.resign = e.target.checked} /> 퇴사
                    </div>
                </div>
                {
                    this.admin.resignAt &&
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">퇴사일</label>
                        <div className="col-sm-10">
                            <input readOnly className="form-control-plaintext" value={getMoment(this.admin.resignAt)} />
                        </div>
                    </div>
                }

                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">프로필 사진</label>
                    <div className="col-sm-10">
                        <div className="mb-1">
                            <label className="btn btn-sm btn-primary" htmlFor={"_photo"}>+ 업로드</label>
                            <input id={"_photo"} type="file" accept="image/jpeg, image/png" className="btn btn-sm btn-primary" style={{ display: "none" }} onChange={(e) => this.upload(e, "_photo")} onClick={e => e.target.value = null} />
                        </div>
                        <div className="row">
                            {
                                this.admin._photo &&
                                <div className="col-sm-4">
                                    <img className="img-thumbnail w-100 previmg" src={`${Config.photoUrl}${this.admin._photo}`} alt="" />
                                    <div className="text-center mt-1">
                                        <label className="btn btn-sm btn-light" style={{ marginTop: 5 }} onClick={e => this.removePhotos("_photo")}>삭제</label>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.myPermission === 'super' && (this.admin.permission === 'super' || this.admin.permission === 'admin') &&
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">홈페이지 등록</label>
                        <div className="col-sm-10">
                            <input type="checkbox" checked={this.admin.isTeamIndex} onChange={e => this.admin.isTeamIndex = e.target.checked} /> 등록
                        </div>
                    </div>
                }
                {
                    this.myPermission === 'super' && (this.admin.permission === 'super' || this.admin.permission === 'admin') && this.admin.isTeamIndex &&
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label small">홈페이지 주소</label>
                        <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="https://"
                                    value={this.admin.website}
                                    onChange={e => this.admin.website = e.target.value}
                                />
                        </div>
                    </div>
                }
                {
                    this.myPermission === 'super' || this.myPermission === 'admin' ?
                        <button type="submit" className="btn btn-secondary" onClick={this._update} style={{ marginRight: 5 }}>수정하기</button>
                        : null
                }
            </form>
        </AdminShowLayout>
    )
}

export default AdminShowScreen