import _Component from "../../common/_Component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTable } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { observable } from "mobx"
import { addComma, diffDays } from "../../utils/Utils"
import Arranger from "../../api/Arranger"
import GetValue from "../../utils/GetValue"

class ArrangerSalesScreen extends _Component {
    @observable search = ''
    @observable arrangers = []
    @observable pagination = {}
    @observable name = ''
    @observable phone = ''
    @observable arrange = { name: "", phone: "" }

    async componentDidMount() {
        try {
            const response = await Arranger.my()
            if (response && response.success) {
                this.sales = response.sales
                super.componentDidMount()
            }
        } catch (e) {
            console.log(e)
        }
    }

    _render = () => (
        <div className="container-fluid">
            <div className="card mb-4">
                <div className="card-header"><FontAwesomeIcon icon={faTable} className="fas mr-1" />마이포인트</div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table text-nowrap">
                            <tbody>
                                <tr>
                                    <th>가입회원수</th>
                                    <td>{addComma(GetValue(this, "this.sales.userCount", 0))}</td>
                                </tr>
                                <tr>
                                    <th>당월포인트</th>
                                    <td>{addComma(GetValue(this, "this.sales.pointForThisMonth", 0))}</td>
                                </tr>
                                <tr>
                                    <th>전월포인트</th>
                                    <td>{addComma(GetValue(this, "this.sales.pointForLastMonth", 0))}</td>
                                </tr>
                                <tr>
                                    <th>추가 안한기간</th>
                                    {
                                        GetValue(this, "this.sales.createdAt") == null
                                            ?
                                            <td>추가데이터 없음</td>
                                            :
                                            <td>{diffDays(GetValue(this, "this.sales.createdAt"))}</td>
                                    }

                                </tr>
                                <tr>
                                    <th>전월 일반 가입</th>
                                    <td>{addComma(GetValue(this, "this.sales.normalRegisterForLastMonth", 0))}</td>
                                </tr>
                                <tr>
                                    <th>전월 프리미엄 가입</th>
                                    <td>{addComma(GetValue(this, "this.sales.premiumRegisterForLastMonth", 0))}</td>
                                </tr>
                                <tr>
                                    <th>전월실패수</th>
                                    <td>{addComma(GetValue(this, "this.sales.failForLastMonth", 0))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ArrangerSalesScreen