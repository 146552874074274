import _Component from "../../common/_Component"
import React from "react"
import AdminShowLayout from "../../layouts/AdminShowLayout"
import { observable } from "mobx"
import { inject } from "mobx-react"
import Admin from "../../api/Admin"
import GetValue from "../../utils/GetValue"

@inject((stores) => ({
    loginStore: stores.LoginStore
}))

class AdminShowPasswordScreen extends _Component {
    @observable admin = null
    @observable permission = ""

    async componentDidMount() {
        this.admin = await this._getAdmin()
        if (this.admin && this.admin.permission) this.permission = this.admin.permission
        if (GetValue(this, "this.admin.permission") === "super" && GetValue(this, "this.props.loginStore.permission") !== "super") {
            alert("접근 권한이 없습니다")
            return true
        }
        if (this.admin) {
            super.componentDidMount()
        }
    }
    _update = async (e) => {
        e.preventDefault()
        try {
            const response = await Admin.update(this.admin._id, {
                password : this.admin.password,
                passwordConfirm : this.admin.passwordConfirm,
            })
            if (response && response.success) {
                alert("비밀번호 수정이 완료되었습니다.")
                // if (this.admin.permission === "super" || this.admin.permission === "admin") this.props.history.push("/admin")
                // else if (this.admin.permission === "manager") this.props.history.push("/admin/manager")
                // else if (this.admin.permission === "arranger") this.props.history.push("/admin/arranger")
            } else {
                alert(response.message)
            }
        } catch (e) {
            alert(e.toString())
        }
    }

    _render = () => (
        <AdminShowLayout title={"비밀번호 수정"} name={"tester"} admin={this.admin} _id={this.admin._id} path={this.path} permission={this.permission}>
            <form>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">비밀번호</label>
                    <div className="col-sm-10">
                        <input type="password" className="form-control" onChange={e => this.admin.password = e.target.value} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label small">비밀번호확인</label>
                    <div className="col-sm-10">
                        <input type="password" className="form-control" onChange={e => this.admin.passwordConfirm = e.target.value} />
                    </div>
                </div>
                <button type="submit" className="btn btn-secondary" onClick={this._update} style={{ marginRight: 5 }}>수정하기</button>
            </form>
            </AdminShowLayout>
    )
}

export default AdminShowPasswordScreen