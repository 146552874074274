import React from 'react'
import { Route } from 'react-router-dom'
import AdminLayout from "./MenuLayout"

const AdminRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            <AdminLayout title={rest.title} path={rest.path}>
                <Component {...props} />
            </AdminLayout>
        )}/>
    )
}
export default AdminRoute